import React from "react";
import {valueFalse, valueTrue} from "../styles/AppScheme";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

import {
    createStyles,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Theme,
    Typography
} from "@material-ui/core";
import {observer} from "mobx-react-lite";
import {HasBoolean, HasTitle} from "../utils/props";
import {asArray} from "../utils";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        inlineContainer: {
            display: 'flex',
        },
        inlineItem: {

        },
        inlineText: {
            height: 24,
            marginRight: '3em',
        },
        item: {
            display: 'inline',
            marginRight: theme.spacing(2),
            // marginBottom: theme.spacing(1),
            // display: 'block',
        },
    }),
)

const BooleanIcon = observer((props: { value: HasBoolean }) => {
    return props.value.aBoolean ? (
        <CheckCircleOutlineIcon style={{color: valueTrue}}/>
    ) : (
        <HighlightOffIcon style={{color: valueFalse}}/>
    )
})

const DefaultList = observer((props: { items: BooleanValueItem[] | BooleanValueItem | undefined }) => {
    return (
        <List dense={false}>
            {asArray(props.items).map(item => (
                <ListItem disableGutters={true}>
                    <ListItemText primary={item.title} secondary={item.subTitle}/>
                    <ListItemSecondaryAction>
                        {item.aBoolean !== undefined && (<BooleanIcon value={item}/>)}
                    </ListItemSecondaryAction>
                </ListItem>
            ))}
        </List>
    )
})

const InlineList = observer((props: { items: BooleanValueItem[] | BooleanValueItem | undefined }) => {
    const classes = useStyles()
    return (
        <div className={classes.inlineContainer}>
            {asArray(props.items).map(item => (
                <div className={classes.inlineItem}>
                    <Typography display={"initial"} variant={"body1"} gutterBottom
                                className={classes.inlineText}>{item.title}</Typography>
                    {item.aBoolean !== undefined && (<BooleanIcon value={item}/>)}
                </div>
            ))}
        </div>
    )
})

export type BooleanValueItem = HasTitle & HasBoolean;

interface BooleanListProps {
    items: BooleanValueItem[] | BooleanValueItem | undefined
    inline?: boolean
}

export const BooleanList = observer((props: BooleanListProps) => {
    const inline = props.inline !== undefined && props.inline
    return inline ? (
        <InlineList items={props.items}/>
    ) : (
        <DefaultList items={props.items}/>
    )
})
