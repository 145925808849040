import {NestedEntityEditContext, RemoteEntityStore} from "./RemoteStore";
import {action, computed, observable} from "mobx";
import {Api, Entity, Org, Region, UserAgreement, UserConfig} from "idpet-api";
import {RootStore} from "./RootStore";
import {Observable,} from 'rxjs';
import {take, tap} from 'rxjs/operators';
import {acceptAgreement, initiateMobileReg, validateMobileReg} from "../utils/actions";
import {newError} from "../utils/builder";
import {getCustomer} from "../utils";

export interface MobileVerification extends Entity {
    mobile?: string
    code?: string
}

class MobileVerificationEntityContext extends NestedEntityEditContext<MobileVerification> {

    protected _setValue(name: keyof MobileVerification, value: any) {
        // Code must not be > 6 characters
        if (name === "code" && value.length > 6)
            return

        super._setValue(name, value);
    }
}

export type InvalidPersonType = 'multiple_match' | 'no_match'

export class ConfigStore extends RemoteEntityStore<Api, RootStore, UserConfig> {
    @observable config: UserConfig = {
        _objectType: '',
        visibleMenus: [],
        visibleFields: [],
    }
    @observable agreement?: UserAgreement
    @observable agreementContent?: any
    @observable agreementAccept: boolean = false
    @observable mobileVerification: MobileVerification = {
        _objectType: '',
    }
    @observable message?: string
    @observable delayResend: boolean = false

    isFieldVisible = (field: string): boolean => {
        return this.config.visibleFields?.find(name => name === field) !== undefined || false;
    }

    isMenuVisible = (menu: string): boolean => {
        return this.config.visibleMenus?.find(name => name === menu) !== undefined || false;
    }

    getFeature = (name: string, defaultValue: string) => {
        const result = this.config.features?.[name];
        return !result ? defaultValue : result
    }

    getLink = (name: string, defaultValue?: string) => {
        return this.config.extraInfo?.[`link.${name}`] || defaultValue || '#'
    }

    getBooleanFeature = (name: string) => {
        return this.getFeature(name, 'false') === 'true'
    }

    getInvalidPerson = (): InvalidPersonType | undefined => {
        // @ts-ignore
        return this.item.extraInfo?.['invalid_person']
    }

    @computed get admin(): boolean {
        const val = this.item.extraInfo?.['admin']
        return val === 'true'
    }

    @computed get dev(): boolean {
        return this.getBooleanFeature('dev')
    }

    @computed get partner(): boolean {
        const val = this.item.extraInfo?.['partner']
        return val === 'true'
    }

    @computed get vet(): boolean {
        return this.item.extraInfo?.['partner.vet'] === 'true'
    }

    @computed get welfare(): boolean {
        return this.item.extraInfo?.['partner.welfare'] === 'true'
    }

    @computed get customer(): { id: string, name: string } | undefined {
        const id = this.item.extraInfo?.['customer.id']
        const name = this.item.extraInfo?.['customer.name']
        return id && name ? {id, name} : undefined
    }

    @computed get defaultCondolenceText(): string | undefined {
        return this.item.extraInfo?.['cremation.defaultMessage']
    }

    protected _initialize(): Observable<any> {
        return this.invokeApi((api, params) => api.profile.getConfig(params))
            .pipe(tap(config => this.setConfig(config)))
    }

    get editing(): boolean {
        return false
    }

    @computed get item() {
        return this.config
    }

    reset(): void {
        throw newError('NOT YET IMPLEMENTED')
    }

    @action private setConfig = (config: UserConfig) => {
        this.config = config
        const agreements = this.config.agreements
        if (agreements && agreements.length > 0) {
            this.selectAgreement(agreements[0])
        } else {
            this.agreement = undefined
        }
    }

    @computed get needsDialog(): boolean {
        return this.needsAgreements || this.needsMobile
    }

    @computed get codeReady(): boolean {
        return (this.mobileVerification.code?.length || 0) === 6
    }

    @computed get mobileReady(): boolean {
        return (this.mobileVerification.mobile?.length || 0) >= 8 && !this.delayResend
    }

    @computed get showDeceased(): boolean {
        return false
    }

    @computed
    private get needsAgreements(): boolean {
        return (this.config.agreements?.length || 0) > 0
    }

    @computed
    private get needsMobile(): boolean {
        return !this.config.mobile?.validatedAt
    }

    @action updateAgreementAccept = (val: boolean) => {
        this.agreementAccept = val
    }

    @action saveAccept = () => {
        if (!this.agreement)
            return

        const action = acceptAgreement(this.agreement.id)
        this.invokeOne((api, params) => api.profile.initiateProfileAction(action, undefined, params),
            () => this.reload())
    }

    @action sendCode = () => {
        this.delayResend = true
        const resendDelayTimer = setTimeout(() => {
            this.clearResendDelay()
            clearTimeout(resendDelayTimer)
        }, 5000)

        const messageClearTimer = setTimeout(() => {
            this.setMessage('')
            clearTimeout(messageClearTimer)
        }, 30000)

        if (!this.mobileVerification.mobile)
            throw newError('No mobile number given')

        const action = initiateMobileReg(this.mobileVerification.mobile)
        this.invokeOne((api, params) => {
            return api.profile.initiateProfileAction(action, undefined, params)
        }, () => this.setMessage('Code sent'))
    }

    @action private setMessage = (message: string) => {
        this.message = message
    }

    @action private clearResendDelay = () => {
        this.delayResend = false
    }

    @action verifyCode = (preReload?: () => void) => {
        if (!this.mobileVerification.code)
            throw newError('No verification code given')

        const action = validateMobileReg(this.mobileVerification.code)
        this.invokeOne((api, params) => {
            return api.profile.initiateProfileAction(action, undefined, params)
        }, () => {
            preReload && preReload()
            this.reload()
        })
    }

    @action private reload = () => {
        this._initialize().pipe(take(1)).subscribe()
    }

    @action selectAgreement = (agreement: UserAgreement) => {
        async function http(request: RequestInfo): Promise<any> {
            const response = await fetch(request);
            return await response.text();
        }

        this.agreement = agreement
        this.agreementAccept = false
        this.agreementContent = undefined

        // Load the terms content
        http(agreement.location).then(content => this.agreementContent = content)
    }

    ctxMobile = () => {
        return this.nestedContext(new MobileVerificationEntityContext('mobile', {
            editing: () => true,
            item: () => this.mobileVerification
        }))
    }

    getGeoRegions(): Region[] {
        return this.config.regionRoots?.filter(root => root.type === 'GEOGRAPHICAL')
            .filter(root => root.children !== undefined)
            .flatMap(root => root.children) as Region[]
    }

    isEmployee(anOrg: Org | undefined): boolean {
        const customer = getCustomer(anOrg);
        return customer !== undefined && customer.id === this.customer?.id
    }
}
