import {
    Api,
    Note,
    PagedPeople,
    Person,
    Service,
    SubscriptionForm,
    SubscriptionInitiate,
    SubscriptionQuote,
    SubscriptionQuoteForm
} from "idpet-api";
import {AddressType, clipArrays, ContactType, isPersonValid} from "../utils";
import {
    DEFAULT_DISMISS_TIMEOUT,
    FieldValidationError,
    Patch,
    RemoteCreateStore,
    RemoteSearchStore,
    RemoteStore,
    RequestParams
} from "./RemoteStore";
import {newContact, newError, newPerson} from "../utils/builder";
import {PartyAddressEditContext, PartyContactEditContext, PersonEditContext} from "../utils/editContexts";
import {RootStore} from "./RootStore";
import {action, computed, observable} from "mobx";
import {NoteStore} from "./index";
import {
    linkPerson,
    newMergePeople,
    newResendVerificationTask,
    newSendPrnSubscription,
    petRescuer
} from "../utils/actions";
import {EntityRowData, RichTableStore, TableRowColumns} from "./table";
import {Filter, newStringFilter, simpleStringMatch} from "../utils/filter";
import {RowData} from "@material-ui/data-grid";
import {PersonRowData} from "../components/table/Person";
import {PartyStore} from "./PartyStores";

export class PersonStore extends PartyStore<Person> implements NoteStore {
    @observable emailRequired: boolean = true

    protected _find(api: Api, id: any, params: RequestParams): Promise<Person> {
        this.animalStore.load(id)
        return api.people.getPersonById(id, {
            expandos: this.expandos([
                'addresses',
                'contacts',
                'devices',
                'events.profile',
                'extraInfo',
                'images',
                'linkedRoles.party',
                'notes',
                // 'pets.profileImage',
                'profileImage',
                'profiles.profileImage',
                'profiles.vet',
                'rescuers',
                'roles.subscriptions',
            ])
        }, params).then(val => {
            this.emailRequired = true
            return val
        })
    }

    @action toggleEmailRequired = () => {
        this.emailRequired = !this.emailRequired
    }

    protected _save(api: Api, id: string, patch: Patch, params: RequestParams): Promise<Person> {
        return api.people.updatePersonById(id, this.clip(patch), undefined, params)
    }

    protected _delete(api: Api, id: string, params: RequestParams): Promise<void> {
        return api.people.deletePersonById(id, undefined, params)
    }

    private clip = (patch: Patch): Person => {
        // Clip off unnecessary stuff from the person
        const person: Person = patch.data
        return clipArrays(person, ["contacts", "addresses"])
    }

    ctxAddress(type: AddressType): PartyAddressEditContext {
        return this.nestedContext(new PartyAddressEditContext(this, type))
    }

    ctxContact(type: ContactType, subtype?: string): PartyContactEditContext {
        return this.nestedContext(new PartyContactEditContext(this, type, subtype))
    }

    addNote = (note: Note) => {
        const id = this.idOrThrow
        this.invokeOneAndReload((api, params) => api.people.addPersonNote(id, note, {expandos: ''}, params))
    }

    @computed get notes() {
        return this.item?.notes
    }

    protected _validateEntity(): FieldValidationError | FieldValidationError[] | undefined {
        return this.simpleValidation(isPersonValid(this.item, this.emailRequired))
    }

    sendPRNSignup = (callback?: () => void, personId?: string) => {
        const thePersonId = !personId ? 'self' : personId
        const action = newSendPrnSubscription()
        this.invokeOne(((api, params) =>
                api.people.initiatePersonAction(thePersonId, action, {}, params)),
            val => {
                this.showMessage('Sent PRN subscription message.')
                callback && callback()
            })
    }
}

export class PersonCreateStore extends RemoteCreateStore<Api, RootStore, Person> {
    @observable emailRequired: boolean = true

    protected _create(): Person {
        const defaultTitle = this.rootStore.types.defaultValue("Title");
        this.emailRequired = true
        return newPerson(defaultTitle, '', '')
    }

    protected _save(api: Api, item: Person, params: RequestParams): Promise<Person> {
        return super._save(api, item, params);
    }

    @action toggleEmailRequired = () => {
        this.emailRequired = !this.emailRequired
    }

    ctxAddress(type: AddressType): PartyAddressEditContext {
        return this.nestedContext(new PartyAddressEditContext(this, type))
    }

    ctxContact(type: ContactType, subtype?: string): PartyContactEditContext {
        return this.nestedContext(new PartyContactEditContext(this, type, subtype))
    }

    protected _validateEntity(): FieldValidationError | FieldValidationError[] | undefined {
        return this.simpleValidation(isPersonValid(this.item, this.emailRequired))
    }
}

export class PersonSearchStore extends RemoteSearchStore<Api, RootStore, PagedPeople, Person> {
    protected _search(api: Api, data: any, params: RequestParams): Promise<PagedPeople> {
        return api.people.listPeople({...data, expandos: this.expandos(['contacts', 'extraInfo'])}, params)
    }

    protected _values(): Person[] | undefined {
        return this.paged?.values;
    }
}

export class PRNSubscribeStore extends RemoteStore<Api, RootStore> {
    @observable private _open: boolean = false
    @observable private _paymentType: string = ''
    @observable private _voucherCode: string = ''
    private _callback?: () => void

    @observable quote?: SubscriptionQuote
    @observable initiate?: SubscriptionInitiate

    @computed get open(): boolean {
        return this._open
    }

    @computed get isVoucher(): boolean {
        return this._paymentType === 'voucher'
    }

    @computed get paymentType(): string {
        return this._paymentType
    }

    @computed get isPaymentTypeValid(): boolean {
        return this._paymentType !== ''
    }

    @computed get voucherCode(): string {
        return this._voucherCode
    }

    @computed get redeemEnabled(): boolean {
        return this._voucherCode.length >= 7
            && this.isVoucher
            && this.status === "READY"
    }

    @computed get gatewayEnabled(): boolean {
        return this.isPaymentTypeValid
            && !this.isVoucher
            && this.status === "READY"
    }

    @action startProcessing = () => {
        const form: SubscriptionForm = {
            paymentType: this._paymentType,
            vouchers: this.voucherCode !== '' ? [this._voucherCode] : undefined,
            service: this.getAnnualPrnService()
        }

        this.invokeOne((api, params) =>
            api.subscriptions.beginSubscription(form, {}, params), init => this.setInitiate(init))
    }

    @action setPaymentType = (type: string) => {
        this._paymentType = type
        this.initiate = undefined
    }

    @action setVoucherCode = (code: string) => {
        this._voucherCode = code
    }

    @action show = (callback?: () => void): void => {
        this._open = true
        this._callback = callback
        this._voucherCode = ''
        this._paymentType = ''
        this.initiate = undefined

        // Start the quote generate process
        this.newQuote()
    }

    @action hide = (): void => {
        this._open = false
        this._callback = undefined
        this.quote = undefined
        this.initiate = undefined
    }

    @action private newQuote = (): void => {
        const form: SubscriptionQuoteForm = {
            service: this.getAnnualPrnService()
        }

        this.invokeOne((api, params) =>
            api.subscriptions.requestSubscriptionQuote(form, {}, params), quote => this.setQuote(quote))
    }

    @action private setQuote = (quote: SubscriptionQuote) => {
        this.quote = quote
    }

    @action private setInitiate = (init: SubscriptionInitiate) => {
        this.initiate = init
        if (this.isVoucher && init.successful) {
            this._callback && this._callback()
            this.showMessage('Subscription activated successfully')
            this.hide()
        }
    }

    private getAnnualPrnService = (): Service => {
        const service = this.rootStore.product.search.services.find(value => true)
        if (!service)
            throw newError('Could not find service')

        return service
    }
}

export class PetRescuerStore extends RemoteStore<Api, RootStore> {
    @observable private _open: boolean = false
    @observable private _agreed: boolean = false
    private _callback?: () => void

    @computed get open(): boolean {
        return this._open
    }

    @computed get agreed(): boolean {
        return this._agreed
    }

    @action show(callback?: () => void): void {
        this._open = true
        this._agreed = false
        this._callback = callback
    }

    @action hide(): void {
        this._open = false
        this._callback = undefined
    }

    @action setAgreed(val: boolean): void {
        this._agreed = val
    }

    enablePetRescuer = (callback?: () => void, personId?: string) => {
        const thePersonId = !personId ? 'self' : personId
        const action = petRescuer(true)
        this.invokeOne((api, params) =>
                api.people.initiatePersonAction(thePersonId, action, undefined, params),
            val => {
                this.showMessage('Thank you for joining the pet rescuer network.')
                if (this._callback)
                    this._callback()

                if (callback)
                    callback()

                this.hide()
            }
        )
    }

    disablePetRescuer = (callback?: () => void, personId?: string) => {
        const thePersonId = !personId ? 'self' : personId
        const action = petRescuer(false)
        this.invokeOne(((api, params) =>
                api.people.initiatePersonAction(thePersonId, action, {}, params)),
            val => {
                this.showWarning('Left pet rescuer network.', DEFAULT_DISMISS_TIMEOUT)
                if (callback)
                    callback()
            })
    }
}

export class ProfilePersonCreateStore extends RemoteCreateStore<Api, RootStore, Person> {
    @observable active: boolean = false
    @observable emailRequired: boolean = true

    protected _create(): Person {
        const defaultTitle = this.rootStore.types.defaultValue("Title")
        const person = newPerson(defaultTitle, '', '')
        const verifiedMobile = this.rootStore.config.item.extraInfo?.['verified_mobile']
        if (verifiedMobile) {
            const contact = newContact("MOBILE")
            contact.value = verifiedMobile
            contact.description = 'primary'
            person.contacts = [contact]
        }
        this.emailRequired = true

        return person
    }

    protected _save(api: Api, item: Person, params: RequestParams): Promise<Person> {
        return api.profile.initiateProfileAction(linkPerson(item), undefined, params)
            .then(() => api.people.getPersonById('self', undefined, params))
    }

    ctxPerson(): PersonEditContext {
        return this.nestedContext(new PersonEditContext('person', {
            item: () => this.item,
            editing: () => this.editing
        }))
    }

    @action cancel = () => {
        this.active = false
    }

    @action begin = () => {
        this.reset()
        this.active = true
    }

    @action toggleEmailRequired = () => {
        this.emailRequired = !this.emailRequired
    }

    @action resendVerificationEmail = () => {
        this.invokeOne((api, params) =>
            api.profile.initiateProfileAction(newResendVerificationTask(), {}, params), val => {
            this.showMessage("Verification email has been resent")
        })
    }

    protected _validateEntity(): FieldValidationError | FieldValidationError[] | undefined {
        return this.simpleValidation(isPersonValid(this.item, this.emailRequired))
    }
}

export class PersonMergeStore extends RemoteStore<Api, RootStore> implements RichTableStore<Person> {
    readonly search: PersonSearchStore = new PersonSearchStore(
        this.rootStore, false, false, 'addresses,pets,profiles,devices,roles')
    readonly rowColumns: TableRowColumns = new TableRowColumns()
    readonly filter: Filter<string> = newStringFilter()
    @observable _selected: PersonRowData[] = []
    @observable primary?: Person

    @computed get selected() {
        return this._selected
    }

    @action setSelected = (val: RowData[]) => {
        // @ts-ignore
        this._selected = val
    }

    @action reset = () => {
        this.search.clear()
        this._selected = []
        this.primary = undefined
    }

    @computed get busy(): boolean {
        return this.status === 'BUSY' || this.search.status === 'BUSY'
    }

    refresh(): void {
        this.search.trigger()
    }

    @computed get rows(): EntityRowData<Person>[] {
        const values: Person[] = this.search.paged?.values || []
        return values
            .filter(val => simpleStringMatch(this.filter.debounced, val.name))
            .map(item => new EntityRowData<Person>(item))
    }

    @action merge = (onMerged?: (person: Person) => void) => {
        const person = this.primary
        if (!person) {
            throw newError('Target person is not specified')
        }

        const data = newMergePeople(person, this.selected.map(value => value.entity))
        this.invokeOne((api, params) => api.batch.initiateBatchAction(data, {}, params),
            val => onMerged && [onMerged(person), this.reset()])
    }

    @action setPrimary = (val: Person) => {
        this.primary = val
    }
}
