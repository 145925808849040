import {ColDef, RowData, RowId} from "@material-ui/data-grid";
import {RowColumns} from "../../components/table/ColumnSelector";
import {action, computed, observable} from "mobx";
import {Entity} from "idpet-api";
import {Filter} from "../../utils/filter";

export class EntityRowData<T extends Entity> implements RowData {
    @observable entity: T
    id: RowId

    constructor(entity: T) {
        this.entity = entity
        this.id = entity.id || ''
    }
}

export class TableRowColumns implements RowColumns {
    @observable _columns: ColDef[]

    constructor(cols?: ColDef[]) {
        this._columns = cols || []
    }

    @action toggleColumn = (column: ColDef) => {
        const found = this.columns?.find(col => col.field === column.field)
        if (found) {
            found.hide = !found.hide
        }
    }

    @action setColumns = (columns: ColDef[]) => {
        this._columns = columns
    }

    @computed get columns() {
        return this._columns
    }

    @computed get visibleColumns() {
        return this.columns.filter(col => col.hide === undefined || !col.hide)
    }

    @computed get empty(): boolean {
        return this.columns.length === 0
    }
}

export interface RichTableStore<T extends Entity> {
    rowColumns: TableRowColumns
    rows: EntityRowData<T>[]
    selected: EntityRowData<T>[]
    setSelected: (vals: RowData[]) => void
    refresh: () => void
    filter: Filter<string>
    busy: boolean
}

/*

class WrappingRichTableStore<T extends Entity, PA extends PagedArray> implements RichTableStore<T> {
    readonly parent: RemoteSearchStore<any, any, PA, T>
    readonly matcher?: (partial: string, entity: T) => boolean

    readonly rowColumns: TableRowColumns = new TableRowColumns()
    readonly filter: Filter<string> = newStringFilter()
    @observable _selected: EntityRowData<T>[] = []

    constructor(parent: RemoteSearchStore<any, any, PA, T>,
                matcher?: (partial: string, entity: T) => boolean) {
        this.parent = parent
        this.matcher = matcher
    }

    @computed get busy(): boolean {
        return this.parent.status === "BUSY"
    }

    @action refresh = (): void => {
        this.parent.trigger()
    }

    @computed get rows(): EntityRowData<T>[] {
        console.log('ROWS (1)', this.parent.paged)
        // @ts-ignore
        const values: T[] = this.parent.paged?.values
        console.log('ROWS (1a)', values)
        if (!values)
            return []

        console.log('ROWS (2)')
        return values
            .filter(val => !this.matcher || this.matcher(this.filter.debounced, val))
            .map(item => new EntityRowData<T>(item))
    }

    @computed get selected(): EntityRowData<T>[] {
        return this._selected
    }

    @action setSelected = (vals: RowData[]): void => {
        // @ts-ignore
        this._selected = vals
    }
}

export const withRichTable1 = <T extends Entity, PA extends PagedArray, RSS extends RemoteSearchStore<Api, RootStore, PA, T>>
(store: RSS, matcher?: (partial: string, entity: T) => boolean): RSS & RichTableStore<T> => {
    return Object.assign(store, new WrappingRichTableStore(store, matcher))
}

export const withRichTable = <T extends Entity, PA extends PagedArray, RSS extends RemoteSearchStore<Api, RootStore, PA, T>>
(store: RSS, matcher?: (partial: string, entity: T) => boolean): RSS & RichTableStore<T> => {
    const rowColumns: TableRowColumns = new TableRowColumns()
    const filter: Filter<string> = newStringFilter()

    let extra = {
        get busy() {
            return store.status === 'BUSY'
        },
        filter: filter,
        refresh(): void {
            store.trigger()
        },
        rowColumns: rowColumns,
        rows: computed(() => {
            console.log('ROWS (1)', store.paged)
            // @ts-ignore
            const values: T[] = store.paged?.values
            console.log('ROWS (1a)', values)
            if (!values)
                return []

            console.log('ROWS (2)')
            return values
                .filter(val => !matcher || matcher(filter.debounced, val))
                .map(item => new EntityRowData<T>(item))
        }).get(),
        selected: [],
        setSelected(vals: RowData[]): void {
            action(() => {
                this.selected.length = 0
                // @ts-ignore
                selected.push(vals)
            })
        }
    }

    decorate(extra, {
        busy: computed,
        selected: observable,
    })
    // extra = observable.object(extra)

    console.log('!@', extra)

    return Object.assign(store, extra)
}

type Constructor = new (...args: any[]) => {}
type GConstructor<T = {}> = new (...args: any[]) => T
type Searchable = GConstructor<typeof RemoteSearchStore>

function RichTableStoreMixin<TBase extends Searchable, T extends Entity>(Base: TBase) {
    return class RichTableStoreImpl extends Base implements RichTableStore<T> {
        rowColumns: TableRowColumns = new TableRowColumns()
        filter: Filter<string> = newStringFilter()

        @computed get busy() {
            return this.status === 'BUSY'
        }


    }
}
*/
