import {Api, Cremated, Customer, PagedCrematedItems, RequestParams} from "idpet-api";
import {RemoteSearchStore} from "../RemoteStore";
import {RootStore, theRootStore} from "../RootStore";
import {action, computed, observable} from "mobx";
import {RowData} from "@material-ui/data-grid";
import {markCremated} from "../../utils/actions";
import {EntityRowData, TableRowColumns} from "../table";

interface CustomerCrematedItems {
    customer: Customer
    count: number
    items?: Cremated[]
}

export type CrematedRowData = EntityRowData<Cremated>

class CremationsStore extends RemoteSearchStore<Api, RootStore, PagedCrematedItems, Cremated> {
    @observable _selected: RowData[] = []
    @observable selectedCustomerId: string = '*'
    @observable type?: string
    rowColumns: TableRowColumns = new TableRowColumns()

    protected _search(api: Api, data: any, params: RequestParams): Promise<PagedCrematedItems> {
        return api.cremations.listCrematedItems({
            q: this.query, expandos: this.expandos(
                ['cremation.owner', 'pet.chips', 'cremation.order.recipient.party'])
        }, params)
    }

    protected _values(): Cremated[] | undefined {
        return this.paged?.values
    }

    @computed get rows(): CrematedRowData[] {
        const values = this.values()
        if (!values)
            return []

        return values
            .filter(val => this.selectedCustomerId === '*'
                || val.cremation?.order?.recipient.id === this.selectedCustomerId)
            .map(val => val && new EntityRowData<Cremated>(val))
    }

    @computed get selected() {
        return this._selected
    }

    @computed get customerSummary(): CustomerCrematedItems[] {
        const values = this.values();
        if (!values)
            return []

        const map: Map<string, CustomerCrematedItems> = new Map()
        values.forEach(item => {
            const recipient = item.cremation?.order?.recipient
            if (!recipient)
                return

            const id = recipient.id || ''
            if (!map.has(id)) {
                map.set(id, {customer: recipient, count: 0})
            }
            const cci = map.get(recipient?.id || '')
            cci && cci.count++
        })

        return Array.from(map.values()).sort((a, b) =>
            (a.customer.party?.name || 'ZZ').localeCompare(b.customer.party?.name || 'ZZ'))
    }

    @action setSelected = (val: RowData[]) => {
        this._selected = val
    }

    @action selectCustomer = (val: string) => {
        this.selectedCustomerId = val
    }

    @action pending = () => {
        this.setQuery('*(pending)')
        this.type = 'Pending'
        this.trigger()
    }

    @action recents = () => {
        this.setQuery('*(recents)')
        this.type = 'Recent'
        this.trigger()
    }

    @computed get showMarkButton(): boolean {
        return this.rootStore.config.admin && this.type === 'Pending'
    }

    applyCremated = (date: string, callback?: () => void) => {
        const values = this.values()
        if (!values)
            return

        const ids = this._selected.map(value => `${value.id}`)
        const items = values.filter(value => ids.indexOf(value.id || '') >= 0);

        this.invokeOne(((api, params) =>
                api.cremations.initiateCremationAction(markCremated(date, items), {}, params)),
            () => callback && callback())
    }
}

export const cremationsStore = new CremationsStore(theRootStore)
