import {observer} from "mobx-react-lite";
import {useStores} from "../contexts";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import React from "react";
import {Button, List} from "@material-ui/core";
import {QrCodeListItem} from "../components/QrCode";

export const PrivateQrDialog = observer(() => {
    const orgStore = useStores().org.edit
    return (
        <Dialog
            open={orgStore.showPrivateQrCode}
            maxWidth={"sm"}
            fullWidth
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">Org. Code - not for customers</DialogTitle>
            <DialogContent>
                {/*
                <Typography variant={"body1"} color={"textPrimary"} gutterBottom>
                    This code is <em>private</em>, and must not be given to customers.
                </Typography>
                 */}

                <List disablePadding>
                    <QrCodeListItem data={orgStore.privateQrCode}/>
                </List>
            </DialogContent>
            <DialogActions>
                <Button onClick={orgStore.closePrivateQrCode} variant="contained" color="primary">Dismiss</Button>
            </DialogActions>
        </Dialog>
    )
})
