import {
    HasApi,
    InvokeApiOptions,
    MessageType,
    RemoteCreateStore,
    RemoteItemStore,
    RemoteSearchStore
} from "./RemoteStore";
import {
    Api,
    Chip,
    Cremation,
    Entity,
    Order,
    Org,
    PagedArray,
    Person,
    Pet,
    PetSos,
    Product,
    Recovery,
    RequestParams,
    Shipment
} from "idpet-api";
import {CremationCreateStore, CremationStore} from "./CremationStores";
import {ConfigStore} from "./ConfigStore";
import {MenuItemStore} from "./MenuItemStore";
import {TypeStore} from "./TypeStore";
import {RecentsStore} from "./RecentsStore";
import {ChipSearchStore, ChipStore} from "./ChipStores";
import {OrderCreateStore, OrderSearchStore, OrderStore} from "./OrderStores";
import {OrgCreateStore, OrgSearchStore, OrgStore} from "./OrgStores";
import {
    PersonCreateStore,
    PersonMergeStore,
    PersonSearchStore,
    PersonStore,
    PetRescuerStore,
    PRNSubscribeStore,
    ProfilePersonCreateStore
} from "./PersonStores";
import {PetCreateStore, PetSearchStore, PetStore} from "./PetStores";
import {ShipmentCreateStore, ShipmentStore} from "./ShipmentStores";
import {isInitializer} from "../utils";
import {PetTransferStore} from "./PetTransferStore";
import {action, computed, observable} from "mobx";
import {AlertStore} from "./AlertStores";
import {from, Observable, of, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {theConstants} from "../constants";
import {PetRecoveryCreateStore, PetRecoveryStore} from "./PetRecoveryStores";
import {ProductSearchStore} from "./ProductStores";
import {LocationState, Path} from "history";
import {PetSosCreateStore, PetSosSearchStore, PetSosStore} from "./PetSosStores";
import {AmlaSearchStore} from "./AmlaStores";
import {DashboardStore} from "./DashboardStore";
import {newError} from "../utils/builder";
import {QuickSearchStore} from "./QuickSearchStore";
import {TaskStore} from "./TaskStore";
import {CustomReportStore} from "./CustomReportStores";

interface StoreGroup<T extends Entity,
    C extends RemoteCreateStore<Api, RootStore, T>,
    E extends RemoteItemStore<Api, RootStore, T>,
    S extends RemoteSearchStore<Api, RootStore, PagedArray, T>> {
    create: C
    edit: E
    search: S
}

interface ChipGroup extends StoreGroup<Chip, any, ChipStore, ChipSearchStore> {

}

interface CremationGroup extends StoreGroup<Cremation, CremationCreateStore, CremationStore, any> {

}

interface OrderGroup extends StoreGroup<Order, OrderCreateStore, OrderStore, OrderSearchStore> {

}

interface OrgGroup extends StoreGroup<Org, OrgCreateStore, OrgStore, OrgSearchStore> {

}

interface PersonGroup extends StoreGroup<Person, PersonCreateStore, PersonStore, PersonSearchStore> {
    rescuer: PetRescuerStore
    profile: ProfilePersonCreateStore
    merge: PersonMergeStore
    prn: PRNSubscribeStore
}

interface PetGroup extends StoreGroup<Pet, PetCreateStore, PetStore, PetSearchStore> {
    recovery: PetRecoveryGroup
    sos: PetSosGroup
    transfer: PetTransferStore
}

interface PetRecoveryGroup extends StoreGroup<Recovery, PetRecoveryCreateStore, PetRecoveryStore, any> {

}

interface PetSosGroup extends StoreGroup<PetSos, PetSosCreateStore, PetSosStore, PetSosSearchStore> {

}

interface ProductGroup extends StoreGroup<Product, any, any, ProductSearchStore> {

}

interface ShipmentGroup extends StoreGroup<Shipment, ShipmentCreateStore, ShipmentStore, any> {

}

export interface History {
    push(path: Path, state?: LocationState): void;
}

export type LoadingState = 'LOADING' | 'COMPLETE' | 'ERROR'

export class RootStore implements HasApi<Api> {
    readonly chip: ChipGroup
    readonly cremation: CremationGroup
    readonly order: OrderGroup
    readonly org: OrgGroup
    readonly person: PersonGroup
    readonly pet: PetGroup
    readonly product: ProductGroup
    readonly shipment: ShipmentGroup

    readonly alert: AlertStore
    readonly amla: AmlaSearchStore
    readonly config: ConfigStore
    readonly customReport: CustomReportStore
    readonly dashboard: DashboardStore
    readonly menu: MenuItemStore
    readonly quickSearch: QuickSearchStore
    readonly recents: RecentsStore
    readonly task: TaskStore
    readonly types: TypeStore

    @observable loading: LoadingState = "LOADING"
    @observable private _tokenFetcher: (() => Promise<any>) = () => Promise.reject("Undefined getToken")
    @observable private _user: any
    @observable private _visible: boolean = true
    @observable private _loadVersion?: string
    @observable private _currentVersion?: string

    private _history?: History

    private _versionRecheckMillis = 60 * 1000
    private _timer?: NodeJS.Timeout
    private _lastVersionCheck?: Date
    private _devLog: boolean = theConstants.execEnv() === "dev" || theConstants.execEnv() === "uat"

    private securityWorker: ((data: any) => RequestParams) = (data) => {
        return {
            headers: {
                "Authorization": "Bearer " + data
            }
        };
    };

    private api = new Api({
        baseUrl: theConstants.apiBaseUrl(),
        securityWorker: this.securityWorker
    })

    constructor() {
        this.chip = {
            create: {},
            edit: new ChipStore(this),
            search: new ChipSearchStore(this),
        }

        this.cremation = {
            create: new CremationCreateStore(this),
            edit: new CremationStore(this),
            search: {},
        }

        this.order = {
            create: new OrderCreateStore(this),
            edit: new OrderStore(this),
            search: new OrderSearchStore(this),
        }

        this.org = {
            create: new OrgCreateStore(this),
            edit: new OrgStore(this),
            search: new OrgSearchStore(this),
        }

        this.person = {
            create: new PersonCreateStore(this),
            edit: new PersonStore(this),
            merge: new PersonMergeStore(this),
            profile: new ProfilePersonCreateStore(this),
            prn: new PRNSubscribeStore(this),
            rescuer: new PetRescuerStore(this),
            search: new PersonSearchStore(this),
        }

        this.pet = {
            create: new PetCreateStore(this),
            edit: new PetStore(this),
            recovery: {
                create: new PetRecoveryCreateStore(this),
                edit: new PetRecoveryStore(this),
                search: {},
            },
            search: new PetSearchStore(this),
            sos: {
                create: new PetSosCreateStore(this),
                edit: new PetSosStore(this),
                search: new PetSosSearchStore(this),
            },
            transfer: new PetTransferStore(this),
        }

        this.product = {
            create: {},
            edit: {},
            search: new ProductSearchStore(this),
        }

        this.shipment = {
            create: new ShipmentCreateStore(this),
            edit: new ShipmentStore(this),
            search: {},
        }

        this.alert = new AlertStore(this)
        this.amla = new AmlaSearchStore(this)
        this.config = new ConfigStore(this)
        this.customReport = new CustomReportStore(this)
        this.dashboard = new DashboardStore(this)
        this.menu = new MenuItemStore(this)
        this.quickSearch = new QuickSearchStore(this)
        this.recents = new RecentsStore(this)
        this.task = new TaskStore(this)
        this.types = new TypeStore(this)

        this.setVisibility(true)
    }

    set tokenFetcher(value: () => Promise<any>) {
        this._tokenFetcher = value;
    }

    get user() {
        return this._user
    }

    set history(history: History) {
        this._history = history
    }

    get history(): History {
        if (!this._history)
            throw newError('History not present')

        return this._history
    }

    @action setUser = (user: any) => {
        this._user = user
    }

    @action setVisibility = (visible: boolean) => {
        this._visible = visible

        // If the version number has changed, just do nothing.
        if (this._loadVersion && this._currentVersion && this._loadVersion !== this._currentVersion) {
            this._devLog && console.debug('Version has diverged. Not altering visibility.')
            return;
        }

        if (visible && !this._timer) {
            // Get the version if its been a while since we last checked
            if (!this._lastVersionCheck
                || Math.abs(Date.now().valueOf() - this._lastVersionCheck.valueOf()) > this._versionRecheckMillis) {
                this.checkVersion()
            }

            // Set a timer for subsequent versions
            this._timer = setInterval(() => this.checkVersion(), this._versionRecheckMillis)
            this._devLog && console.debug('Created the timer')
        }

        if (!visible && this._timer) {
            // Cancel the timer as we're not visible
            clearInterval(this._timer)
            this._timer = undefined
            this._devLog && console.debug('Cleared the timer')
        }
    }

    private checkVersion = () => {
        this._devLog && console.debug('Checking version....')
        var nocacheHeaders = new Headers();
        nocacheHeaders.append('pragma', 'no-cache');
        nocacheHeaders.append('cache-control', 'no-cache');

        fetch(`/version.txt`, {method: 'GET', headers: nocacheHeaders})
            .then(res => res.text().then(value => this.setVersion(value)))
            .catch(rej => console.warn('Could not check version', rej))
    }

    @action
    private setVersion = (val: string) => {
        if (val.toLocaleLowerCase().indexOf('html') >= 0) {
            return
        }

        this._devLog && console.debug(`Got version : ${val}`,
            `Load: ${this._loadVersion}`,
            `Current: ${this._currentVersion}`,
            `Last Check: ${this._lastVersionCheck}`)

        this._lastVersionCheck = new Date()
        if (!this._loadVersion) {
            this._loadVersion = val
        } else {
            this._currentVersion = val
        }

        // If the versions have diverged, clear the interval
        if (this._loadVersion && this._currentVersion && this._loadVersion !== this._currentVersion) {
            this._timer && clearInterval(this._timer)
            this._timer = undefined
            this._devLog && console.debug('Versions have diverged. Clearing timeout.')
        }
    }

    @computed get newVersion(): boolean {
        return this._loadVersion !== undefined
            && this._currentVersion !== undefined
            && this._loadVersion !== this._currentVersion
    }

    @action setLoadingState = (state: LoadingState) => {
        this.loading = state
    }

    onMessage: ((type: MessageType, message: any, dismissAfter?: number) => void) = (type: MessageType, message: any) => {
        const asString = (message: any): string => {
            return message instanceof Error ? message.toString() : JSON.stringify(message)
        }

        switch (type) {
            case "INFO":
                console.info('(bootstrap) onMessage', type, asString(message))
                break;
            case "WARNING":
                console.warn('(bootstrap) onMessage', type, asString(message))
                break;
            case "ERROR":
                console.error('(bootstrap) onMessage', type, asString(message))
                break;
        }
    }

    initialize = () => {
        const promises: (Promise<any>)[] = []

        function tryInitialize(val: any) {
            if (val && isInitializer(val)) {
                promises.push(val.initialize())
            }
        }

        Object.values(this).forEach(field => {
            tryInitialize(field)

            const keys: (keyof StoreGroup<any, any, any, any>)[] = ["create", "edit", "search"]
            keys.forEach(key => tryInitialize(field && field[key]))

            // TODO what about groups that have other keys - eg. pet group. They should also be initialised
        })

        Promise.all(promises).then(() => {
            this.setLoadingState("COMPLETE")
        }, err => {
            this.setLoadingState("ERROR")
        })
    }

    invokeApi = <T>(method: ((api: Api, params: RequestParams) => PromiseLike<T>), options?: InvokeApiOptions): Observable<any> => {
        return new Observable<T>(observer => {
            const abortController = new AbortController();
            const params: RequestParams = {
                signal: abortController.signal
            }

            const subscription = from(this._tokenFetcher()
                .then(val => this.api.setSecurityData(val))
                .then(() => method(this.api, params))).subscribe(observer)

            return () => {
                abortController.abort()
                subscription.unsubscribe()
            }
        }).pipe(catchError(err => {
            if (options?.onError) {
                options.onError(err)
                return of()
            } else {
                this.onMessage("ERROR", err)
                return throwError(err)
            }
        }))
    }

    invokeMaybeAnonymous = <T>(method: ((api: Api, params: RequestParams) => Promise<T>)): Promise<T> => {
        return method(this.api, {})
    }
}

export const theRootStore = new RootStore()
