import {observer} from "mobx-react-lite";
import React, {useState} from "react";
import {isString} from "../../utils";
import {Entity} from "idpet-api";
import {EditableFieldProps, styles, title} from "./index";
import {DatumView} from "../DatumView";
// @ts-ignore
import MaterialUiPhoneNumber from "material-ui-phone-number"
import {parsePhoneNumberFromString} from "libphonenumber-js/max";
import {InputAdornment} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from '@material-ui/icons/Clear';

export const Phone = observer(<T extends Entity>(props: EditableFieldProps<T>) => {
    const classes = styles();
    const [error, setError] = useState<string | undefined>()
    const value = props.context.getValue(props.field)
    const checkValue = (event: any) => {
        console.log(">> ", props.context.isValid(props.field))
        const value = event.target.value;
        if (value.trim() === '+27')
            return

        const phoneNumber = parsePhoneNumberFromString(value)
        if (!phoneNumber?.isValid()) {
            setError('Invalid phone number')
        } else {
            setError(undefined)
            props.onBlur && props.onBlur();
        }
    }

    return (
        <div>
            {props.context.editing ? (
                <MaterialUiPhoneNumber
                    label={title(props)}
                    variant="filled"
                    value={value}
                    defaultCountry={"za"}
                    autoFormat={true}
                    required={props.required}
                    onChange={(val: any) => props.context.setValue(props.field, val)}
                    onBlur={checkValue}
                    className={classes.item}
                    autoFocus={props.autoFocus}
                    masks={{za: '(..) ...-....'}}
                    InputProps={{
                        endAdornment:
                            <InputAdornment position="end">
                                <IconButton onClick={() => {
                                    props.context.setValue(props.field, '')
                                    setError(undefined)
                                }}>
                                    <ClearIcon/>
                                </IconButton>
                            </InputAdornment>
                    }}
                    inputProps={{
                        name: 'phone',
                    }}
                    error={error !== undefined}
                    helperText={error || props.helperText}
                />
            ) : (
                <DatumView title={title(props)} children={value && isString(value) ? value : '-'}/>
            )}
        </div>
    )
})
