import React, {Fragment, ReactNode, Suspense} from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import List from '@material-ui/core/List';
import PetsIcon from '@material-ui/icons/Pets';
import HistoryIcon from '@material-ui/icons/History'
import SearchIcon from '@material-ui/icons/Search'
import AddIcon from '@material-ui/icons/Add'
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import BarChartIcon from '@material-ui/icons/BarChart';
import {useAuth0} from '@auth0/auth0-react';
import {Avatar, Backdrop, Box, CircularProgress, ListItem, ListItemText, Typography} from "@material-ui/core";
import {ListItemLink} from "./ListItemLink";
import {Logo} from "./Logo";
import {useStores} from "../contexts";
import {observer} from "mobx-react-lite";
import {RenderDialogs} from "../dialogs";
import {initializeBindings} from "../utils/keyBindings";
import {theConstants} from "../constants";
import {RecentsView} from "../utils/lazyRoutes";

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        appBar: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
        },
        recentsDrawer: {
            flexShrink: 0,
        },
        recentsDrawerPaper: {
            minWidth: 600,
        },
        drawerPaper: {
            width: drawerWidth,
        },
        drawerPaperNotProd: {
            width: drawerWidth,
            borderLeft: '7px solid orange',
        },
        // necessary for content to be below app bar
        toolbar: theme.mixins.toolbar,
        content: {
            minHeight: '100vh',
            flexGrow: 1,
            backgroundColor: theme.palette.background.default,
            padding: theme.spacing(3),
        },
        small: {
            width: theme.spacing(3),
            height: theme.spacing(3),
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
    }),
);

const RecentsLoading = () => {
    return (
        <Typography variant="overline" display="block" gutterBottom>
            Loading recents...
        </Typography>
    )
}


const DrawerHolder = observer(() => {
    const classes = useStyles()
    const {user} = useAuth0()
    const rootStore = useStores()
    const {menu, recents, config} = rootStore
    const paperClass = theConstants.execEnv() !== "live" ? classes.drawerPaperNotProd : classes.drawerPaper

    if (user && rootStore.loading === "COMPLETE") {
        initializeBindings(user, rootStore)
    }

    return (
        <Fragment>
            <Drawer anchor={"left"} open={recents.visible} onClose={recents.hide} className={classes.recentsDrawer}
                    classes={{paper: classes.recentsDrawerPaper}}>
                <Suspense fallback={<RecentsLoading/>}>
                    <RecentsView/>
                </Suspense>
            </Drawer>

            <Drawer className={classes.drawer} variant="permanent" classes={{paper: paperClass,}}
                    anchor="left">
                <Logo/>
                <Box display={'flex'} height={'100%'} flexDirection={'column'} justifyContent={'space-between'}>
                    <Box>
                        <List>
                            {
                                user ? (
                                    <Fragment>
                                        <ListItemLink primary={"Home"} to={"/dashboard"} icon={<PetsIcon/>}/>
                                        <ListItemLink primary={"Recents"} onClick={() => recents.show()}
                                                      icon={<HistoryIcon/>}/>
                                        <ListItemLink primary={"Search"} onClick={() => menu.show('Search ')}
                                                      icon={<SearchIcon/>}/>
                                        <ListItemLink primary={"Create"} onClick={() => menu.show('Create ')}
                                                      icon={<AddIcon/>}/>
                                        {menu.hasReports && (
                                            <ListItemLink primary={"Reports"} onClick={() => menu.show('Report ')}
                                                          icon={<BarChartIcon/>}/>
                                        )}
                                        {config.admin && (
                                            <ListItemLink primary={"Tasks"} to={"/tasks"}
                                                          icon={<PlaylistAddCheckIcon/>}/>
                                        )}
                                    </Fragment>
                                ) : (<ListItemLink primary={"Home"} to={"/"} icon={<PetsIcon/>}/>)
                            }
                        </List>
                    </Box>
                    <Box>
                        <List>
                            {user && (
                                <Fragment>
                                    <Profile/>
                                    <Logout/>
                                </Fragment>
                            )}

                            {!user && (
                                <ListItemLink primary={"Login"}
                                              to={'/dashboard'}
                                              icon={<PowerSettingsNewIcon/>}/>
                            )}
                            <ListItem>
                                <ListItemText secondary={
                                    `Version: ${theConstants.execEnv()}, ${theConstants.version()}`}/>
                            </ListItem>
                        </List>
                    </Box>
                </Box>
            </Drawer>
        </Fragment>
    )
})

export const PermanentDrawerLeft = observer((props: any) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <CssBaseline/>

            <DrawerHolder/>
            <main className={classes.content}>
                {props.children}
                <Backdrop className={classes.backdrop} open={false}>
                    <CircularProgress color="inherit"/>
                </Backdrop>
            </main>

            <RenderDialogs/>
        </div>
    )
})

const Profile = () => {
    const classes = useStyles();
    const {user} = useAuth0();

    return user ? (
        <ListItemLink primary={"Profile"} to={"/profile"} icon={
            <Avatar alt={user.name} src={user.picture} className={classes.small}/>}
        />
    ) : (<Fragment/>)
}

const Logout = () => {
    const {logout} = useAuth0();
    const logoutWithRedirect = () => {
        logout({
            returnTo: window.location.origin
        });
    }

    return (
        <ListItemLink primary={"Logout"}
                      onClick={logoutWithRedirect}
                      icon={<PowerSettingsNewIcon color={"secondary"}/>}/>
    )
}

export class FullHeightContent extends React.Component<{ children?: ReactNode }, any> {
    private _jsd: any

    componentDidMount() {
        this._jsd = document.getElementById('jsd-widget')
        if (this._jsd) {
            this._jsd.style.display = 'none'
        }
    }

    componentWillUnmount() {
        if (this._jsd) {
            this._jsd.style.display = ''
            this._jsd = undefined
        }
    }

    render() {
        return (
            <Box display={'flex'} height={'100%'} flexDirection={'column'} justifyContent={'space-between'}>
                {this.props.children}
            </Box>
        )
    }
}

export class DisableJSD extends React.Component<{ children?: ReactNode }, any> {
    private _jsd: any

    componentDidMount() {
        this._jsd = document.getElementById('jsd-widget')
        if (this._jsd) {
            this._jsd.style.display = 'none'
        }
    }

    componentWillUnmount() {
        if (this._jsd) {
            this._jsd.style.display = ''
            this._jsd = undefined
        }
    }

    render() {
        return (
            <Fragment>{this.props.children}</Fragment>
        )
    }
}
