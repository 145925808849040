import React from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../contexts";
import {Box, Button, Dialog, DialogActions, DialogContent, TextField, Typography} from "@material-ui/core";
import {columnStyles} from "../styles/EntityLayoutStyles";

const Content = observer(() => {
    const {create} = useStores().shipment
    const classes = columnStyles()

    return (
        <Box className={classes.container}>
            <Box className={classes.column}>
                <div>
                    <Typography variant={"body1"} gutterBottom>
                        Captured {create.captureCount}, found {create.uniqueCount} unique.
                    </Typography>
                </div>

                <TextField
                    id="serial-numbers"
                    label="Serial numbers - one per line"
                    variant="filled"
                    fullWidth
                    multiline
                    rows={8}
                    value={create.serialString}
                    onChange={event => create.setSerialString(event.target.value)}
                    autoFocus
                    helperText={create.captureError}
                />
            </Box>
        </Box>
    )
})

export const ShipmentCreateDialog = observer(() => {
    const {create} = useStores().shipment

    return (
        <Dialog onClose={() => create.cancelCapture()}
                open={create.inCapture}
                fullWidth={true}
                maxWidth={'sm'}
        >
            <DialogContent>
                <Content/>
            </DialogContent>
            <DialogActions>
                <Button variant={"text"}
                        onClick={() => create.cancelCapture()}>Cancel</Button>
                <Button variant={"contained"} color={"primary"}
                        disabled={!create.isCaptureValid}
                        onClick={() => create.saveCapture()}>Save</Button>
            </DialogActions>
        </Dialog>
    )
})
