import {action, computed, observable} from "mobx";
import {interval, Subject} from "rxjs";
import {debounce} from "rxjs/operators";
import {asArray} from "./index";

export interface Filter<T> {
    value: T
    debounced: T
    setValue: (val: T) => void
}

class FilterImpl<T> implements Filter<T> {
    @observable value: T
    @observable private debouncedValue: T
    readonly subject: Subject<T>

    constructor(defaultValue: T) {
        this.value = defaultValue
        this.debouncedValue = defaultValue

        this.subject = new Subject<T>()
        this.subject.pipe(
            debounce(() => interval(250))
        ).subscribe(next => this._setDebounced(next))
    }

    @action setValue = (val: T) => {
        this.subject.next(val)
        this.value = val
    }

    @computed get debounced() {
        return this.debouncedValue
    }

    @action private _setDebounced = (val: T) => {
        this.debouncedValue = val
    }

}

export const newStringFilter = (): Filter<string> => {
    return new FilterImpl<string>('')
}

export const simpleStringMatch = (filter: string, val: string[] | string | undefined): boolean => {
    if (val === undefined)
        return false

    if (filter === '')
        return true

    const lowerFilter = filter.toLowerCase()
    return asArray(val)
        .filter(item => item !== undefined && item.toLowerCase().indexOf(lowerFilter) >= 0)
        .length > 0
}
