import {lazy} from "react";

// Unauth'ed pages
export const Home = lazy(() => import('../views/Home'))

// Auth'ed pages
export const AmlaView = lazy(() => import('../views/AmlaView'))
export const ChipView = lazy(() => import('../views/ChipView'))
export const ChipSearchView = lazy(() => import('../views/ChipSearchView'))
export const CremationView = lazy(() => import('../views/CremationView'))
export const CremationCreateView = lazy(() => import('../views/CremationCreateView'))
export const DashboardView = lazy(() => import('../views/DashboardView'))
export const OrderView = lazy(() => import('../views/OrderView'))
export const OrderSearchView = lazy(() => import('../views/OrderSearchView'))
export const OrderCreateView = lazy(() => import('../views/OrderCreateView'))
export const OrgCreateView = lazy(() => import('../views/OrgCreateView'))
export const OrgView = lazy(() => import('../views/OrgView'))
export const OrgSearchView = lazy(() => import('../views/OrgSearchView'))
export const PersonView = lazy(() => import('../views/PersonView'))
export const PersonMergeView = lazy(() => import('../views/PersonMergeView'))
export const PersonSearchView = lazy(() => import('../views/PersonSearchView'))
export const PetCreateView = lazy(() => import('../views/PetCreateView'))
export const PetView = lazy(() => import('../views/PetView'))
export const PetRecoveryView = lazy(() => import('../views/PetRecoveryView'))
export const PetSearchView = lazy(() => import('../views/PetSearchView'))
export const PetSosCreateView = lazy(() => import('../views/PetSosCreateView'))
export const PetSosView = lazy(() => import('../views/PetSosView'))
export const PetSosGalleryView = lazy(() => import('../views/PetSosGalleryView'))
export const PetTransferView = lazy(() => import('../views/PetTransferView'))
export const ProfileView = lazy(() => import('../views/Profile'))
export const PrnStatsView = lazy(() => import('../views/PrnStatsView'))
export const RecentsView = lazy(() => import('../views/Recents'))
export const ShipmentCreateView = lazy(() => import('../views/ShipmentCreateView'))
export const ShipmentView = lazy(() => import('../views/ShipmentView'))
export const TaskView = lazy(() => import('../views/TaskView'))
export const TaskProcessView = lazy(() => import('../views/TaskProcessView'))

// Misc pages
export const Blank = lazy(() => import('../components/routes/Blank'))

// Error pages
export const NotFound = lazy(() => import('../components/routes/NotFound'))
export const NotImplemented = lazy(() => import('../components/routes/NotImplemented'))
