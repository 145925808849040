import React, {Fragment} from "react";
import {observer} from "mobx-react-lite";
import {columnStyles} from "../../styles/EntityLayoutStyles";
import {TitleBox} from "../MasterDetail";
import {Box, Button} from "@material-ui/core";
import {DatumView} from "../DatumView";
import {BooleanList} from "../BooleanList";
import {hasRoleOfType} from "../../utils";
import {PersonEditContext} from "../../utils/editContexts";
import {PersonStore} from "../../stores/PersonStores";
import {EditableEmail, EditablePhone, EditableText} from "../editable";
import {EditableAddress, EditablePersonName} from "./index";

interface PersonProps {
    context: PersonEditContext | PersonStore
    editOnly?: boolean
    emailRequired?: boolean
    toggleEmailRequired?: () => void
}

export const Person = observer((props: PersonProps) => {
    const classes = columnStyles();
    const ctx = props.context

    const closePro = ctx.item?.extraInfo?.['prn.close.pro']
    const closeRegular = ctx.item?.extraInfo?.['prn.close.regular']

    const copyFromPhysical = () => {
        const physical = ctx.ctxAddress("PHYSICAL")
        const postal = ctx.ctxAddress("POSTAL")
        postal.setValue('line1', physical.getValue('line1'))
        postal.setValue('line2', physical.getValue('line2'))
        postal.setValue('line3', physical.getValue('line3'))
        postal.setValue('line4', physical.getValue('line4'))
        postal.setValue('code', physical.getValue('code'))
    }

    return (
        <Fragment>
            <TitleBox children={<EditablePersonName title={"Name"} context={ctx} autoFocus={true} required/>}
                      minCols={2}/>

            <Box className={classes.container}>
                <Box className={classes.column}>
                    <EditablePhone title={'Cell (Primary)'} context={ctx.ctxContact("MOBILE", "primary")}
                                   field={"value"} required/>
                    <EditablePhone title={'Cell (Alt)'} context={ctx.ctxContact("MOBILE", "secondary")}
                                   field={"value"}/>
                    <EditablePhone title={'Home'} context={ctx.ctxContact("HOME")} field={"value"}/>
                    <EditablePhone title={'Work'} context={ctx.ctxContact("WORK")} field={"value"}/>
                    <EditableEmail title={'Email'} context={ctx.ctxContact("EMAIL")} field={"value"}
                                   required={props.emailRequired} toggleRequired={props.toggleEmailRequired}/>
                </Box>
                <Box className={classes.column}>
                    <EditableText context={ctx} field={"idNumber"} title={"ID Number"}/>
                    {!props.editOnly && (
                        <Fragment>
                            <DatumView title={'Flags'} children={
                                <BooleanList items={
                                    {title: 'Rescuer', aBoolean: hasRoleOfType(ctx.item, "PET_RESCUER")}
                                }/>
                            }/>

                            {closeRegular && (
                                <DatumView title={'Close Rescuers'} children={[
                                    `Professional - ${closePro}`,
                                    `Regular - ${closeRegular}`,
                                ]}/>
                            )}
                        </Fragment>
                    )}
                </Box>
                <Box className={classes.column}>
                    <EditableAddress context={ctx.ctxAddress("PHYSICAL")} title={"Physical"} required/>
                </Box>
                <Box className={classes.column}>
                    {ctx.editing && (
                        <Button size={"small"}
                                variant={"text"}
                                onClick={() => copyFromPhysical()}
                        >
                            Copy from Physical
                        </Button>

                    )}
                    <EditableAddress context={ctx.ctxAddress("POSTAL")} title={"Postal"}/>
                </Box>
            </Box>
        </Fragment>
    )
})
