import React, {Fragment} from "react";
import {observer} from "mobx-react-lite";
import {columnStyles} from "../../styles/EntityLayoutStyles";
import {TitleBox} from "../MasterDetail";
import {Box} from "@material-ui/core";
import {DatumView} from "../DatumView";
import {BooleanList, BooleanValueItem} from "../BooleanList";
import {hasRoleOfType} from "../../utils";
import {OrgEditContext} from "../../utils/editContexts";
import {EditableEmail, EditableName, EditablePhone, EditableText} from "../editable";
import {EditableAddress} from "./index";
import {OrgStore} from "../../stores/OrgStores";

export const Org = observer((props: { context: OrgEditContext | OrgStore, editOnly?: boolean }) => {
    const classes = columnStyles();
    const ctx = props.context
    const customerCtx = ctx.ctxCustomer()

    const booleans: BooleanValueItem[] = [
        {title: 'Customer', aBoolean: hasRoleOfType(ctx.item, "CUSTOMER")},
        {title: 'Vet', aBoolean: hasRoleOfType(ctx.item, "VET_CLINIC")},
        {title: 'Welfare', aBoolean: hasRoleOfType(ctx.item, "WELFARE")},
        {title: 'Breeder', aBoolean: hasRoleOfType(ctx.item, "BREEDER")},
    ]
    // const qrCode = customerCtx.item?.extraInfo?.['qr.code.public']

    return (
        <Fragment>
            <TitleBox children={<EditableName title={"Name"} field={"name"} context={ctx} autoFocus={true} required/>}
                      minCols={3}/>

            <Box className={classes.container}>
                <Box className={classes.column}>
                    <EditablePhone title={'Work'} context={ctx.ctxContact("WORK")} field={"value"} required/>
                    <EditablePhone title={'Fax'} context={ctx.ctxContact("FAX")} field={"value"}/>
                    <EditableEmail title={'Email'} context={ctx.ctxContact("EMAIL")} field={"value"} required/>
                    <EditableText title={'Web'} context={ctx.ctxContact("WEB")} field={"value"}/>
                </Box>
                <Box className={classes.column}>
                    <EditableText title={'Account #'} context={customerCtx} field={"accountNumber"}/>
                    <EditableText title={'Attention'} context={customerCtx} field={"attention"}/>
                    <EditableText title={'Dr(s)'} context={customerCtx} field={"doctor"}/>
                    <EditableText title={'Contact(s)'} context={customerCtx} field={"contact"}/>
                    <DatumView title={'Vet Pin'} children={customerCtx.getValue("vetPin")}/>
                </Box>
                <Box className={classes.column}>
                    <EditableAddress context={ctx.ctxAddress("PHYSICAL")} title={"Physical"} required/>
                </Box>
                <Box className={classes.column}>
                    <EditableAddress context={ctx.ctxAddress("POSTAL")} title={"Postal"}/>
                </Box>
                {ctx.item?.roles && (
                    <Fragment>
                        <Box className={classes.column}>
                            <BooleanList items={booleans}/>
                        </Box>
                        {/*
                        {qrCode && (
                            <List disablePadding>
                                <QrCodeListItem data={qrCode}/>
                            </List>
                        )}
*/}
                    </Fragment>
                )}
            </Box>
        </Fragment>
    )
})
