import {RemoteStore} from "./RemoteStore";
import {Api, RescheduleTask, ResolveTask, Task, TaskResolution} from "idpet-api";
import {RootStore} from "./RootStore";
import {action, computed, observable} from "mobx";
import {newAssignTask} from "../utils/actions";
import {newError, newNote} from "../utils/builder";

export class TaskStore extends RemoteStore<Api, RootStore> {
    @observable active?: Task
    @observable filter: string = 'resolved'
    @observable tasks: Task[] = []

    @observable rescheduleVisible: boolean = false
    @observable resolveVisible: boolean = false

    @observable assigning: boolean = false

    constructor(rootStore: RootStore) {
        super(rootStore, 'assignee,notes,data');
    }

    showError(error: any): void {
        if ('code' in error && error.code === 404) {
            this.setActive(undefined)
        } else {
            super.showError(error)
        }
    }

    @action assignTask = () => {
        this.assigning = true
        const action = newAssignTask("PrnSaleTask")
        this.invokeOne((api, params) =>
                api.profile.initiateProfileAction(action, {}, params),
            val => this.refresh())
    }

    refresh = () => {
        // Active task
        this.invokeOne((api, params) =>
                api.tasks.getTaskById('active', {expandos: this.expandos('')}, params),
            val => this.setActive(val))

        // Filtered tasks
        this.invokeOne((api, params) =>
                api.tasks.listTasks({q: this.filter, limit: 10, expandos: this.expandos('')}, params),
            val => this.setTasks(val.values))
    }

    @action updateFilter = (filter: string) => {
        this.filter = filter
        this.tasks = []

        this.invokeOne((api, params) =>
                api.tasks.listTasks({q: this.filter, expandos: this.expandos('')}, params),
            val => this.setTasks(val.values))
    }

    @computed get resolutions(): TaskResolution[] {
        return this.active?.availableResolutions || []
    }

    reschedule = (period?: string, until?: string, comments?: string, onSuccess?: () => void) => {
        const taskId = this.active?.id
        if (!taskId)
            return

        if (!period && !until) {
            throw newError('Either period or until must be specified')
        }

        const data: RescheduleTask = {
            _flagType: "RescheduleTask",
            period,
            until,
            comments,
        }

        this.invokeOne((api, params) =>
                api.tasks.flagTaskById(taskId, data, {}, params),
            val => [this.refresh(), onSuccess && onSuccess()])
    }

    resolve = (resolution: TaskResolution, comments?: string, onSuccess?: () => void) => {
        const taskId = this.active?.id
        if (!taskId)
            return

        const data: ResolveTask = {
            _flagType: "ResolveTask",
            resolution,
            comments,
        }

        this.invokeOne((api, params) =>
                api.tasks.flagTaskById(taskId, data, {}, params),
            val => [this.refresh(), onSuccess && onSuccess()])
    }

    addNote = (value: string) => {
        const taskId = this.active?.id
        if (!taskId)
            return

        this.invokeOne((api, params) =>
                api.tasks.addTaskNote(taskId, newNote(value), {}, params),
            val => this.refresh())
    }

    @action showResolve = () => {
        if (this.active)
            this.resolveVisible = true
    }

    @action hideResolve = () => {
        this.resolveVisible = false
    }

    @action showReschedule = () => {
        if (this.active)
            this.rescheduleVisible = true
    }

    @action hideReschedule = () => {
        this.rescheduleVisible = false
    }

    @action
    private setActive = (task?: Task) => {
        this.active = task
        this.assigning = false
    }

    @action
    private setTasks = (tasks?: Task[]) => {
        this.tasks = tasks || []
    }
}
