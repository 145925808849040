import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {AppState, Auth0Provider} from '@auth0/auth0-react';
import config from "./auth_config.json";
import history from "./utils/history";
import {WiredStores} from "./contexts";
import * as Sentry from '@sentry/react';
// See https://github.com/mobxjs/mobx-react-lite/#observer-batching
import 'mobx-react-lite/batchingForReactDom'
import {theConstants} from "./constants";
import ReactGA from 'react-ga'

const onRedirectCallback = (appState?: AppState) => {
    // Use the router's history module to replace the url
    history.replace(appState?.returnTo || window.location.pathname);
};

if (theConstants.sentry.enable) {
    Sentry.init({
        dsn: theConstants.sentry.dsn,
        environment: theConstants.execEnv()
    });
}

if (theConstants.gaId() !== '') {
    ReactGA.initialize(theConstants.gaId())
}

ReactDOM.render(
    <React.StrictMode>
        <Auth0Provider
            domain={config.domain}
            scope="openid profile email read:basic write:basic admin:*"
            audience="https://api.identipet.com/v3"
            clientId={config.clientId}
            redirectUri={window.location.origin}
            onRedirectCallback={onRedirectCallback}>
            <WiredStores>
                <App/>
            </WiredStores>
        </Auth0Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
