import {action, computed, observable} from "mobx";

abstract class AbstractSelectionModel<T> implements SelectionModel<T> {
    @observable protected _selected: T[] | T | undefined;

    @computed get selected() {
        return this._selected
    }

    contains = (item: T): boolean => {
        if (!this._selected)
            return false

        return Array.isArray(this._selected)
            ? this._selected.indexOf(item) >= 0
            : this._selected === item
    }

    abstract add(item: T): boolean

    @action clear = () => {
        this._selected = undefined
    }

    abstract remove(item: T): boolean

    @action toggle(item: T) {
        this.contains(item) ? this.remove(item) : this.add(item)
    }
}

class SingleSelectionModel<T> extends AbstractSelectionModel<T> {
    @action add(item: T): boolean {
        const found = this._selected === item
        if (!found)
            this._selected = item

        return !found
    }

    @action remove(item: T): boolean {
        const found = this._selected === item
        if (found)
            this._selected = undefined

        return found
    }
}

export interface SelectionModel<T> {
    selected: T[] | T | undefined

    add(item: T): boolean

    remove(item: T): boolean

    toggle(item: T): void

    clear(): void

    contains(item: T): boolean
}

export const singleSelection = <T>(): SelectionModel<T> => {
    return new SingleSelectionModel<T>()
}
