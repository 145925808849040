import {RemoteItemStore, RequestParams} from "./RemoteStore";
import {Api, DashboardConfig, DashboardItem} from "idpet-api";
import {RootStore} from "./RootStore";
import {computed} from "mobx";
import {asArray} from "../utils";

export class DashboardStore extends RemoteItemStore<Api, RootStore, DashboardConfig> {
    protected _find(api: Api, id: string, params: RequestParams): Promise<DashboardConfig> {
        return api.profile.getDashboard(params)
    }

    @computed get gadgets(): DashboardItem[] {
        return asArray(this.item?.items).filter(item => item.visible)
    }
}
