import {Typography} from "@material-ui/core";
import React, {Fragment, ReactNode} from "react";
import {isSimpleValue} from "../utils";
import {OverrideProps} from "@material-ui/core/OverridableComponent";
import {TypographyTypeMap} from "@material-ui/core/Typography/Typography";

export type TextOrReactRenderProps<D extends React.ElementType = TypographyTypeMap['defaultComponent'],
    P = { value?: ReactNode; }> = OverrideProps<TypographyTypeMap<P, D>, D>;

export const TextOrReactRender = (props: TextOrReactRenderProps) => {
    const {value, children, ...tProps} = props;
    return isSimpleValue(value) ? (
        <Typography {...tProps}>{value}</Typography>
    ) : <Fragment>{value}</Fragment>;
}

