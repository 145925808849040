import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import {Alert, Autocomplete} from "@material-ui/lab";
import {Box, DialogTitle, TextField} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {useStores} from "../contexts";
import {observer} from "mobx-react-lite";
import {Org} from "idpet-api";
import {styles,} from "../components/editable";
import {columnStyles} from "../styles/EntityLayoutStyles";

const OrgSearch = observer(() => {
    const classes = styles();
    const {edit} = useStores().pet;

    function queryChange(event: any) {
        edit.setOrgQuery(event.target.value);
    }

    return (
        <Autocomplete
            id="organization"
            options={edit.orgSearch.paged?.values || []}
            getOptionLabel={(option) => option.name}
            onInputChange={queryChange}
            className={classes.item}
            onChange={(event, value) => edit.setImplantingOrg(value as Org)}
            filterOptions={(options) => options}
            renderInput={(params) =>
                <TextField
                    {...params}
                    label={"Implanting Organization"}
                    variant={"filled"}
                    placeholder={"Implanting Organization"}
                />}
        />
    )
})

export const ImplanterDialog = observer(() => {
    const classes = columnStyles()
    const otherClasses = styles()
    const {edit} = useStores().pet
    const {config} = useStores()

    return (
        <Dialog
            onClose={edit.hideChippedByDialog}
            aria-labelledby="dialog-title"
            open={edit.chippedByDialogVisible}
            fullWidth={true}
            maxWidth={'md'}
            disableBackdropClick
        >
            <DialogTitle>Update Chip Implant Details</DialogTitle>
            <DialogContent>
                <Box className={classes.container}>
                    <Box className={classes.column}>
                        <TextField label={"Implanting Vet"} variant="filled" value={edit.implantingVet}
                                   required
                                   onChange={event => edit.setImplantingVet(event.target.value)}
                                   className={otherClasses.item}
                                   autoFocus/>
                        {config.admin || !edit.implantingOrg ? (
                            <OrgSearch/>
                        ) : (
                            <Alert severity="info">
                                If the implanting organisation has already been set, only Identipet
                                staff can change the value.
                            </Alert>
                        )}
                    </Box>
                </Box>

                <DialogActions>
                    <Button variant="text" onClick={edit.hideChippedByDialog}>Cancel</Button>
                    <Button variant="contained" color="primary"
                            disabled={!edit.readyForChippedByUpdate || edit.status === "BUSY"}
                            onClick={edit.updateChippedBy}>Update</Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    )
})

