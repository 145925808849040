import {RemoteCreateStore, RemoteItemStore, RemoteSearchStore, RequestParams} from "./RemoteStore";
import {RootStore} from "./RootStore";
import {Api, Note, PagedPetSos, Pet, PetSos, PetSosAction, PetSosResolution} from "idpet-api";
import {newAddressFromPlace, newError, newPetSos} from "../utils/builder";
import {action, computed, observable} from "mobx";
import {PetEditContext, PetSosContactEditContext} from "../utils/editContexts";
import {ContactType, dateUtils, getContact, getPetOwner, isContactValid, isPetValid, simpleClone} from "../utils";
import {newSosActivate, newSosPause, newSosResolve} from "../utils/actions";
import {NoteStore} from "./index";

const loadExpandos = [
    'contacts',
    'extraDetail',
    'images',
    'location',
    'pet.parties.party',
    'prnSummary',
]

export class PetSosStore extends RemoteItemStore<Api, RootStore, PetSos> implements NoteStore {
    @observable resolving: boolean = false
    @observable resolution: string = ''
    @observable comments: string = ''

    protected _find(api: Api, id: string, params: RequestParams): Promise<PetSos> {
        return api.sos.getSosById(id, {expandos: this.expandos(loadExpandos)}, params)
    }

    protected _delete(api: Api, id: string, params: RequestParams): Promise<void> {
        return api.sos.deleteSosById(id, undefined, params)
    }

    addNote(note: Note): void {
        throw newError('NOT IMPLEMENTED')
    }

    @computed get notes() {
        return this.item?.pet?.notes
    }

    activate = () => {
        this.invokeAction(newSosActivate())
    }

    pause = () => {
        this.invokeAction(newSosPause())
    }

    @action startResolve = () => {
        this.resolution = ''
        this.resolving = true
    }

    @action cancelResolve = () => {
        this.resolving = false
    }

    resolve = () => {
        if (!this.resolution)
            throw newError('Resolution not set')

        if (!PetSosStore.isResolution(this.resolution))
            throw newError(`Resolution type ${this.resolution} is invalid`)

        this.invokeAction(newSosResolve(this.resolution), () => this.cancelResolve())
        this.cancelResolve()
    }

    @action setResolution = (resolution: string | undefined) => {
        if (!resolution) {
            this.resolution = ''
        } else {
            this.resolution = resolution
        }
    }

    @computed get resolutionValid(): boolean {
        return PetSosStore.isResolution(this.resolution)
    }

    private static isResolution(val: string): val is PetSosResolution {
        return val === "cancelled" || val === "found" || val === "time_expired"
    }

    @action
    private invokeAction = (action: PetSosAction, postInvoke?: () => void) => {
        const id = this.idOrThrow
        this.invokeOneAndReload((api, params) => api.sos.initiateSosAction(id, action, {}, params),
            () => postInvoke && postInvoke())
    }
}

export class PetSosCreateStore extends RemoteCreateStore<Api, RootStore, PetSos> {
    @observable _place?: google.maps.places.PlaceResult
    @observable id?: string

    protected _create(): PetSos {
        this._place = undefined
        this.id = undefined

        return {
            ...newPetSos()
        }
    }

    @action load = (id: string) => {
        this.reset()
        this.id = id
        this.invokeOne((api, params) => api.pets.getPetById(id, {
            expandos: this.expandos([
                'chips',
                'images',
                'parties.party.contacts',
            ])
        }, params), val => this.setPet(val))
    }

    @action
    private setPet = (pet: Pet) => {
        this.item.pet = simpleClone(pet)
        // Remove the parties from the cloned pet
        this.item.pet.parties = undefined

        this.item.contacts = []

        // Assume missing since today
        this.item.missingSince = dateUtils.format(new Date(), "yyyy-MM-dd")

        // Default the contact details
        const petOwner = getPetOwner(pet)
        if (petOwner) {
            let contact = getContact(petOwner, "MOBILE", "primary")
            contact && this.item.contacts.push(contact)

            contact = getContact(petOwner, "MOBILE", "secondary")
            contact && this.item.contacts.push(contact)

            contact = getContact(petOwner, "EMAIL")
            contact && this.item.contacts.push(contact)
        }

        // Ensure the extra detail field is present
        this.item.extraDetail = {
            answersTo: '',
            personality: '',
            medicalConditions: '',
            reward: '',
        }
    }

    @action setPlace(place: google.maps.places.PlaceResult) {
        console.log('!PLACE', place)
        this._place = place
        this.item.lastSeenAt = newAddressFromPlace(place, "ALERT")
    }

    @computed get place() {
        return this._place
    }

    @computed get isMissingDetailValid(): boolean {
        return this._place !== undefined
            && this.item.missingSince !== undefined
            && isContactValid(getContact(this.item, "MOBILE", "primary"))
    }

    @computed get isPetValid(): boolean {
        return isPetValid(this.item.pet)
    }

    @action activate = (id: string, postActivate: (val?: PetSos) => void): void => {
        const action = newSosActivate()
        this.invokeOne((api, params) =>
                api.sos.initiateSosAction(id, action, {}, params),
            () => postActivate && postActivate(this._item))
    }

    protected _save(api: Api, item: PetSos, params: RequestParams): Promise<PetSos> {
        this.item.missingSince = dateUtils.date(this.item.missingSince).toISOString()
        return api.sos.addSos(item, {expandos: this.expandos(loadExpandos)}, params)
    }

    @action setItem = (val: PetSos) => {
        this._item = val
        return val
    }

    ctxPet() {
        return this.nestedContext(new PetEditContext(`sos-pet`, {
            item: () => this.item.pet,
            editing: () => this.editing,
            resetAction: "REMOVE",
        }))
    }

    ctxContact(type: ContactType, subtype?: string): PetSosContactEditContext {
        return this.nestedContext(new PetSosContactEditContext(this, type, subtype))
    }
}

export class PetSosSearchStore extends RemoteSearchStore<Api, RootStore, PagedPetSos, PetSos> {
    protected _search(api: Api, data: any, params: RequestParams): Promise<PagedPetSos> {
        return api.sos.listSos({
            ...data, expandos: this.expandos([
                'contacts',
                'extraDetail',
                'images',
                'location',
                'pet.profileImage',
            ])
        }, params)
    }

    protected _values(): PetSos[] | undefined {
        return this.paged?.values
    }

    searchNearSelf = () => {
        this.clearLimit()
        this.setQuery('*(near:self)')
        this.trigger()
    }

    searchAll = () => {
        this.clearLimit()
        this.setQuery('*')
        this.trigger()
    }
}
