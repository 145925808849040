import React, {Fragment} from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../contexts";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import {Button, Checkbox, Divider, FormControlLabel, Typography} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {green} from "@material-ui/core/colors";
import {EditablePhone, EditableText} from "../components/editable";
import {columnStyles} from "../styles/EntityLayoutStyles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        spinner: {
            color: green[500],
            position: "absolute",
            margin: 'auto',
            zIndex: 1,
        },
        spinContainer: {
            display: 'flex',
            width: `calc(100% - ${theme.spacing(4)}px)`,
            height: `calc(100% - ${theme.spacing(4)}px)`,
            justifyContent: 'center',
            alignItems: 'center',
            position: "absolute",
        },
        divider: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
        message: {
            marginLeft: theme.spacing(1),
        },
    }),
);

const TermsView = observer(() => {
    const {config} = useStores()
    return (
        <div dangerouslySetInnerHTML={{__html: config.agreementContent}}/>
    )
})

const MobileView = observer(() => {
    const classes = useStyles()
    const columns = columnStyles()
    const {config} = useStores()
    const mobileCtx = config.ctxMobile()
    return (
        <div className={columns.container}>
            <div className={columns.column}>
                <EditablePhone context={mobileCtx} title={"Cell Phone"} field={"mobile"} required={true}
                               autoFocus={true}/>
                <Button variant={"outlined"} color={"primary"} onClick={config.sendCode} disabled={!config.mobileReady}>
                    Send Code...
                </Button>

                {config.message && (
                    <Typography variant={"body1"}
                                color={"textSecondary"}
                                className={classes.message}
                                gutterBottom
                                display={"inline"}>
                        {config.message}
                    </Typography>
                )}

                <Divider className={classes.divider}/>

                <EditableText context={mobileCtx} title={"Code"} field={"code"} required={true}/>
            </div>

            <div className={columns.column}>
                <Typography variant={"body1"} gutterBottom>
                    In order to securely authenticate you, and if possible, link you with an existing pet
                    owner, we need to verify your mobile number.
                </Typography>

                <Typography variant={"body1"} gutterBottom>
                    Please capture your number in the field provided, then click the "Send Code" button. We
                    will then send a 6 digit code to your mobile. This must be entered into the code field
                    and finally, click the "Verify Code" button.
                </Typography>
            </div>
        </div>
    )
})

const TermsButtons = observer(() => {
    const {config} = useStores()
    return (
        <div>
            <FormControlLabel
                control={<Checkbox checked={config.agreementAccept}
                                   onChange={(event, val) => config.updateAgreementAccept(val)}
                                   name="accept"/>}
                label="Accept Terms?"
            />
            <Button variant="contained" color="primary" disabled={!config.agreementAccept} onClick={config.saveAccept}>
                Save
            </Button>
        </div>
    )
})

const MobileButtons = observer(() => {
    const {config} = useStores()
    const onClick = () => {
        config.verifyCode()
    }

    return (
        <Button variant="contained" color="primary" disabled={!config.codeReady} onClick={onClick}>
            Verify Code
        </Button>
    )
})

const TermsAndMobileDialog = observer(() => {
    const rootStore = useStores()
    const {config} = rootStore
    const classes = useStyles()

    // config.rootStore.setJsdVisible(config.needsDialog && config.agreement !== undefined)
    const jsd = document.getElementById('jsd-widget')
    if (jsd) {
        if (config.needsDialog && config.agreement) {
            jsd.style.display = 'none'
        } else {
            jsd.style.display = ''
        }
    }

    return config.needsDialog ? (
        <Dialog
            open={config.needsDialog && rootStore.loading === "COMPLETE"}
            scroll={"paper"}
            maxWidth={config.agreement ? "lg" : "md"}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {config.agreement ? `${config.agreement.name} - version ${config.agreement.version}` : 'Mobile Verification'}
            </DialogTitle>

            <DialogContent>
                {config.agreement ? <TermsView/> : <MobileView/>}
            </DialogContent>

            <DialogActions style={{position: 'relative'}}>
                {config.status === "BUSY" && <div className={classes.spinContainer}>
                    <CircularProgress size={36} className={classes.spinner}/>
                </div>}

                {config.agreement ? <TermsButtons/> : <MobileButtons/>}
            </DialogActions>
        </Dialog>
    ) : <Fragment/>
})

export default TermsAndMobileDialog
