import {EditContext, NestedContextSupport, NestedEntityEditContext} from "../stores/RemoteStore";
import {AddressType, ContactType, getAddress, getContact, getCustomer} from "./index";
import {
    Address,
    Chip,
    Contact,
    Cremated,
    Customer,
    OrderItem,
    Org,
    Party,
    Person,
    Pet,
    PetSos,
    Region
} from "idpet-api";
import {newAddress, newContact, newCustomer, newError} from "./builder";

//
// Context classes
//

export class PartyContactEditContext extends NestedEntityEditContext<Contact> {
    private readonly type: ContactType;
    private readonly subtype?: string;

    constructor(parent: EditContext<Party>, type: ContactType, subtype?: string) {
        super(`${parent.item?.id}-contact-${type}-${subtype || ''}`, {
            editing: () => parent.editing,
            buildItem: () => this.build(parent.item),
            item: () => getContact(parent.item, type, subtype)
        });
        this.type = type;
        this.subtype = subtype;
    }

    private build = (party: Party | undefined) => {
        if (!party)
            throw newError('party must be valid (1)')

        const contact = newContact(this.type)
        if (this.subtype) {
            contact.description = this.subtype
        }

        if (!party.contacts)
            party.contacts = []

        party.contacts.push(contact)
    }
}

export class PetSosContactEditContext extends NestedEntityEditContext<Contact> {
    private readonly type: ContactType;
    private readonly subtype?: string;

    constructor(parent: EditContext<PetSos>, type: ContactType, subtype?: string) {
        super(`${parent.item?.id}-contact-${type}-${subtype || ''}`, {
            editing: () => parent.editing,
            buildItem: () => this.build(parent.item),
            item: () => getContact(parent.item, type, subtype)
        });
        this.type = type;
        this.subtype = subtype;
    }

    private build = (sos: PetSos | undefined) => {
        if (!sos)
            throw newError('sos must be valid (1)')

        const contact = newContact(this.type)
        if (this.subtype) {
            contact.description = this.subtype
        }

        if (!sos.contacts)
            sos.contacts = []

        sos.contacts.push(contact)
    }
}

export class PartyAddressEditContext extends NestedEntityEditContext<Address> {
    private readonly type: AddressType

    constructor(parent: EditContext<Party>, type: AddressType) {
        super(`${parent.item?.id}-address-${type}`, {
            editing: () => parent.editing,
            buildItem: () => this.build(parent.item),
            item: () => getAddress(parent.item, type)
        })
        this.type = type
    }

    private build = (party: Party | undefined) => {
        if (!party)
            throw newError('party must be valid (2)')

        const address = newAddress(this.type)
        if (!party.addresses)
            party.addresses = []

        party.addresses.push(address)
    }
}

export type GeoRegionOrg = Org & { geoRegion: Region | undefined }

export class RegionEditContext extends NestedEntityEditContext<GeoRegionOrg> {
    private readonly type: string

    constructor(parent: EditContext<Org>, type: string) {
        super(`${parent.item?.id}-region-${type}`, {
            editing: () => parent.editing,
            item: () => RegionEditContext.getRegion(parent.item, type),
        })
        this.type = type
    }

    private static getRegion(org: Org | undefined, type: string): GeoRegionOrg | undefined {
        return org && {
            ...org,
            get geoRegion(): Region | undefined {
                return org?.regions?.filter(region => region.type === type)[0]
            },
            set geoRegion(val: Region | undefined) {
                if (!org)
                    return;

                if (!org.regions) {
                    org.regions = []
                }

                // Copy all non matching back into array
                org.regions = org.regions.filter(region => region.type !== type)

                // Insert new
                if (val) {
                    org.regions.push(val)
                }
            }
        }
    }
}

const buildCustomerEditContextSupport = (parent: EditContext<Party>): NestedContextSupport<Customer> => {
    const build = (party: Party | undefined) => {
        if (!party)
            throw newError('party must be valid (3)')

        const customer = newCustomer()
        if (!party.roles)
            party.roles = []

        party.roles.push(customer)
    }

    return {
        item: () => getCustomer(parent.item),
        editing: () => parent.editing,
        buildItem: () => build(parent.item)
    }
}

export class CustomerEditContext extends NestedEntityEditContext<Customer> {
    static create = (parent: EditContext<Party>): CustomerEditContext => {
        return new CustomerEditContext(`${parent.item?.id}-party-customer`, buildCustomerEditContextSupport(parent))
    }
}

export class PartyEditContext<T extends Party> extends NestedEntityEditContext<T> {
    ctxAddress(type: AddressType): PartyAddressEditContext {
        return this.nestedContext(new PartyAddressEditContext(this, type))
    }

    ctxContact(type: ContactType, subtype?: string): PartyContactEditContext {
        return this.nestedContext(new PartyContactEditContext(this, type, subtype))
    }
}

export class PersonEditContext extends PartyEditContext<Person> {

}

export class OrgEditContext extends PartyEditContext<Org> {
    ctxCustomer(): CustomerEditContext {
        return this.nestedContext(CustomerEditContext.create(this))
    }
}

export class CrematedEditContext extends NestedEntityEditContext<Cremated> {
    ctxPet(): PetEditContext {
        return this.nestedContext(new PetEditContext(`cremated-${this.id}-pet`, {
            editing: () => !this.item?.pet.id && this.editing,
            item: () => this.item?.pet
        }))
    }
}

export class ChipEditContext extends NestedEntityEditContext<Chip> {

}

export class PetEditContext extends NestedEntityEditContext<Pet> {
    ctxChip(): ChipEditContext {
        return this.nestedContext(new ChipEditContext(`pet-${this.id}-chip`, {
            editing: () => this.editing,
            item: () => this.item?.chips?.[0],
            resetAction: "REMOVE",
        }))
    }
}

export class OrderItemEditContext extends NestedEntityEditContext<OrderItem> {

}

export class ContactEditContext extends NestedEntityEditContext<Contact> {

}
