import React, {createContext, Fragment, ReactNode, SyntheticEvent, useContext} from 'react'
import {useAuth0} from '@auth0/auth0-react';
import {Snackbar} from "@material-ui/core";
import MuiAlert, {AlertProps, Color} from '@material-ui/lab/Alert';
import {theRootStore} from "../stores/RootStore";
import {isApiError, isString} from "../utils";
import {Error as ApiError} from "idpet-api";
import {MessageType} from "../stores/RemoteStore";
import {interval, Subject} from "rxjs";
import {throttle} from "rxjs/operators";
import {action, computed, observable} from "mobx";
import {observer} from "mobx-react-lite";
import history from "../utils/history";
import * as Sentry from '@sentry/react';

export const RootStoreContext = React.createContext(theRootStore);
export const useStores = () => React.useContext(RootStoreContext);

function Alert(props: AlertProps) {
    return (
        <MuiAlert elevation={6} variant="filled" {...props} />
    )
}

const ApiErrorContent = (props: { error: ApiError }) => {
    return props.error.fields || props.error.children ? (
        <Fragment>
            {props.error.message.content?.content}
            <ul>
                {props.error.fields?.map(field => (
                    <li key={field.field}>{field.message?.content?.content}</li>
                ))}

                {props.error.children?.map(child => (
                    <li key={child.message.key}>{child.message.content?.content}</li>
                ))}
            </ul>
        </Fragment>
    ) : (
        <Fragment>{props.error.message.content?.content}</Fragment>
    )
}

interface Message {
    type: MessageType
    message: any
    dismissAfter?: number
}

class MessageHandler {
    @observable visible: boolean = false
    @observable message?: Message
    @observable severity: Color = "info"

    private readonly subject = new Subject<Message>()

    constructor() {
        const observable = this.subject.pipe(throttle(val => interval(200)))

        const asString = (message: any): string => {

            return message instanceof Error ? message.toString() : JSON.stringify(message)
        }

        observable.subscribe(message => {
            // Api errors that are 401 or 403 should be skipped
            const skipConsoleLog = (message: any) => {
                if (!isApiError(message))
                    return false

                const code = message.code
                return code === 401 || code === 403
            }

            if (!skipConsoleLog(message.message)) {
                switch (message.type) {
                    case "INFO":
                        console.info('onMessage', message.type, asString(message.message))
                        break;
                    case "WARNING":
                        console.warn('onMessage', message.type, asString(message.message))
                        break;
                    case "ERROR":
                        console.error('onMessage', message.type, asString(message.message))
                        break;
                }
            }

            // Some messages need to be ignored
            if (message.message.error && message.message.error === 'login_required') {
                // TODO what if we're not transitioning to the login screen here? Then we should explicitly tell the
                //  user... Perhaps a timer and then warning would work?
                return
            }

            this.setMessage(message)
        })

        theRootStore.onMessage = (type: MessageType, message: any, dismissAfter?: number) => {
            this.subject.next({type, message, dismissAfter})
        }

        history.listen(() => this.setVisible(false))
    }

    @action setVisible = (value: boolean) => {
        this.visible = value
    }

    @action setMessage = (message: Message) => {
        this.message = message
        this.severity = this._severity(message)
        this.visible = true
    }

    @action clearMessage = () => {
        this.visible = false
        this.message = undefined
    }

    private _severity = (message: Message): Color => {
        if (!message)
            return "info"

        // Color of message
        const type = message.type;
        switch (type) {
            case "INFO":
                return "info"
            case "WARNING":
                return "warning"
            case "ERROR":
                return "error"
            default:
                return "info"
        }
    }

    @computed get hideAfter(): number | undefined {
        if (!this.message)
            return undefined

        const dismissAfter = this.message.dismissAfter
        return (!dismissAfter || dismissAfter <= 0) ? undefined : dismissAfter
    }
}

const theMessageHandler = new MessageHandler()

const MessageHandlerContext = createContext(theMessageHandler);
const useMessageHandler = () => useContext(MessageHandlerContext);

export const ErrorHandler = observer((props: any) => {
    const handler = useMessageHandler()

    const handleClose = (event?: SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        handler.clearMessage()
    }

    return (
        <Fragment>
            <Snackbar open={handler.visible}
                      autoHideDuration={handler.hideAfter}
                      onClose={handleClose}
                      anchorOrigin={{vertical: "top", horizontal: "center"}}>
                <MessageContent onClose={handleClose}/>
            </Snackbar>

            {props.children}
        </Fragment>
    )
})

const MessageContent = observer((props: { onClose: (event?: SyntheticEvent, reason?: string) => void }) => {
    const handler = useMessageHandler()

    const contentFn = (message: any): ReactNode => {
        if (!message)
            return undefined

        // Format the content of the message
        let strVal = ''
        if (isString(message)) {
            strVal = message
        } else if (isApiError(message)) {
            return (<ApiErrorContent error={message}/>)
        } else if (message.error_description) {
            strVal = message.error_description
        } else if (message.message) {
            if (message.message === 'Unable to process JSON') {
                strVal = 'There was a problem with the data sent to the server. ' +
                    'This could be a malformed date - please check the dates are YYYY-MM-dd.'
            } else {
                strVal = JSON.stringify(message.message)
            }
        } else {
            strVal = JSON.stringify(message)
        }

        const matcher = /^["']?(.*?)["']?$/
        return matcher.exec(strVal.trim())?.[1] || strVal
    }

    return (
        <Alert onClose={props.onClose}
               severity={handler.severity}>
            {contentFn(handler.message?.message)}
        </Alert>
    )
})

export const WiredStores = (props: any) => {
    const {getAccessTokenSilently, user} = useAuth0()
    const rootStore = useStores()

    rootStore.tokenFetcher = async () => await getAccessTokenSilently();

    if (user) {
        rootStore.setUser(user)
        rootStore.initialize()

        // Update the user detail on sentry for error reporting
        if ('sub' in user) {
            Sentry.setExtra('jwt.sub', user.sub)
        }
    }

    /*
    observe(rootStore, 'jsdVisible', change => {
        const jsd = document.getElementById('jsd-widget')
        if (jsd) {
            if (!change.newValue) {
                jsd.style.display = 'none'
            } else {
                jsd.style.display = ''
            }
        }
    }, false)
    */

    return (
        <ErrorHandler>
            {props.children}
        </ErrorHandler>
    )
}
