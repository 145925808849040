import {Badge, Box, Grid, Paper, Tab, Tabs} from "@material-ui/core";
import React, {Fragment, ReactElement} from "react";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {observer} from "mobx-react-lite";
import {Skeleton} from "@material-ui/lab";
import {useStores} from "../contexts";
import {ConfigStore} from "../stores/ConfigStore";
import {columnStyles} from "../styles/EntityLayoutStyles";
import {asArray} from "../utils";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        badgedLabel: {
            margin: "0 14px",
        },
        loadingMaster: {
            height: 250,
            marginBottom: theme.spacing(2),
        },
        loadingTabs: {
            height: 50,
            marginBottom: theme.spacing(2),
        },
        loadingCard: {
            height: 350,
        }
    })
);

export interface MDItem {
    name: string;
    component: React.ReactElement;
    count?: number | undefined;
    badge?: string | number | undefined;
    if?: ((config: ConfigStore, count: number) => boolean);
}

export interface HasTabElement {
    tabElement?: (element: React.ReactElement) => void
}

interface MasterDetailProps {
    master: MDItem & { value: any };
    children?: MDItem[]
}

const Loading = () => {
    const classes = useStyles();
    return (
        <Fragment>
            <Skeleton animation="wave" variant="rect" className={classes.loadingMaster}/>

            <Skeleton animation="wave" variant="rect" className={classes.loadingTabs}/>

            <Grid container spacing={3}>
                {[...Array(3)].map(() => (
                    <Grid item xs={3}>
                        <Skeleton animation="wave" variant="rect" className={classes.loadingCard}/>
                    </Grid>
                ))}
            </Grid>
        </Fragment>
    )
}

// export const DetailContext = React.createContext({})
// export const useDetailContext = () => React.useContext(DetailContext)

export const MasterDetail = observer((props: MasterDetailProps) => {
    const {config} = useStores();
    const [value, setValue] = React.useState(0);
    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    if (!props.master.value) {
        return (<Loading/>)
    }

    // const tabElement = props.children?.[value].component.props.tabElement()
    const children = props.children?.filter(child => !child.if || child.if(config, child.count || 0))
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Paper>
                    <Box m={2} p={1} style={{position: "relative"}}>
                        {props.master.component}
                    </Box>
                </Paper>
            </Grid>

            <Grid item xs={12}>
                <Box display={"flex"} justifyContent={"space-between"} width={"100%"}>
                    <Tabs value={value} onChange={handleChange}>
                        {children?.map((child, idx) => (
                            <Tab label={<TabLabel item={child}/>} {...a11yProps(idx)} />
                        ))}
                    </Tabs>
                </Box>

                {children?.map((child, idx) => (
                    <TabPanel value={value} index={idx}>{child.component}</TabPanel>
                ))}
            </Grid>
        </Grid>
    );
})

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

interface TabLabelProps {
    item: MDItem
}

const TabLabel = (props: TabLabelProps) => {
    const classes = useStyles();
    const badgeValue = props.item.badge || props.item.count;
    return !badgeValue ? <Fragment>{props.item.name}</Fragment> : (
        <div className={'MuiTabItem-labelGroup'}>
            <div className={'MuiTabItem-label'}>
                <Badge badgeContent={badgeValue} color={"primary"}>
                    <span className={classes.badgedLabel}>{props.item.name}</span>
                </Badge>
            </div>
            {/*<div className={'MuiTabItem-subLabel'}>Youtube, LinkedIn</div>*/}
        </div>
    )
}

function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export const TitleBox = (props: { children: ReactElement[] | ReactElement, minCols?: number }) => {
    const classes = columnStyles();
    const children = asArray(props.children)
    const extra = Math.max(0, (props.minCols || 0) - children.length)
    return (
        <Box className={classes.container}>
            {children.map(child => (
                <Box className={classes.column}>
                    {child}
                </Box>
            ))}
            {Array.from(Array(extra).keys()).map((i) => (
                <Box className={classes.column}>&nbsp;</Box>
            ))}
        </Box>
    )
}
