import {action, computed, observable, toJS} from "mobx"
import {Entity, PagedArray} from "idpet-api";
import {SearchSupport} from "../components/Search";
import {asArray, HasInitializer, isEmptyString} from "../utils";
import {interval, Observable, of, Subject} from 'rxjs';
import {debounce, finalize, take, tap,} from 'rxjs/operators';
import {SelectionModel, singleSelection} from "../utils/selection";
import {newError} from "../utils/builder";

export type RemoteStoreStatus = "READY" | "BUSY";

export declare type RequestParams = Omit<RequestInit, "body" | "method"> & {
    secure?: boolean;
};

export interface InvokeApiOptions {
    onError?: (err: any) => void
}

export interface HasApi<API> {
    invokeApi: <T>(method: ((api: API, params: RequestParams) => PromiseLike<T>), options?: InvokeApiOptions) => Observable<T>
}

export const DEFAULT_DISMISS_TIMEOUT = 5000

export type MessageType = 'INFO' | 'WARNING' | 'ERROR'

export interface HasMessageHandler {
    onMessage: ((type: MessageType, message: any, dismissAfter?: number) => void)
}

export abstract class AbstractStore<API, RS extends HasApi<API> & HasMessageHandler> implements HasMessageHandler {
    private readonly _rootStore: RS;

    constructor(rootStore: RS) {
        this._rootStore = rootStore
    }

    get rootStore() {
        return this._rootStore
    }

    onMessage(type: MessageType, message: any, dismissAfter?: number): void {
        this._rootStore.onMessage(type, message, dismissAfter)
    }

    showError(error: any): void {
        this._rootStore.onMessage("ERROR", error, -1)
    }

    showMessage(message: any, dismissAfter?: number): void {
        this._rootStore.onMessage("INFO", message, dismissAfter || DEFAULT_DISMISS_TIMEOUT)
    }

    showWarning(message: any, dismissAfter?: number): void {
        this._rootStore.onMessage("WARNING", message, dismissAfter || -1)
    }
}

export abstract class RemoteStore<API, RS extends HasApi<API> & HasMessageHandler> extends AbstractStore<API, RS> implements HasInitializer {
    @observable status: RemoteStoreStatus = "READY";
    @observable error?: any;

    private readonly _expandos?: string

    private initPromise?: Promise<void>
    private resolver?: () => void
    private rejector?: (reason: any) => void

    constructor(rootStore: RS, expandos?: string) {
        super(rootStore)
        this._expandos = expandos
    }

    protected _clearOnTrigger(): boolean {
        return true;
    }

    protected _initialize(): Observable<any> {
        // Create a single entry for the observable, otherwise we don't startup...
        return of('')
    }

    protected expandos(input: string[] | string | undefined): string {
        let result = asArray(input).join(',')
        if (this._expandos)
            result += ',' + this._expandos

        return result
    }

    invokeApi = <T>(method: ((api: API, params: RequestParams) => PromiseLike<T>), options?: InvokeApiOptions): Observable<T> => {
        this.onStart();
        return this.rootStore.invokeApi((api, params) => method(api, params), options)
            .pipe(finalize(this.onReady))
    }

    // Although onSuccess should not throw an exception, the method should be prepared for that to happen, and
    // handle the exception by logging it appropriately.
    invokeOne = <T>(method: ((api: API, params: RequestParams) => PromiseLike<T>), onSuccess?: (val: T) => void) => {
        return this.invokeApi(method).pipe(
            take(1),
            tap(value => onSuccess && onSuccess(value))
        ).subscribe({
            error: err => this.showError(err)
        })
    }

    initialize = () => {
        if (this.initPromise) {
            return this.initPromise
        } else {
            // Allow for deferred initialization
            this.initPromise = new Promise<void>((resolve, reject) => {
                this.resolver = resolve
                this.rejector = reject
            })
        }

        this._initialize()
            .pipe(take(1))
            .subscribe(() => this.resolver?.(),
                err => this.rejector?.('Could not start the application'))

        return this.initPromise
    }

    @action onError = (error: any) => {
        this.error = error;
    }

    @action onStart = () => {
        this.status = "BUSY";
        this.error = undefined;
    }

    @action onReady = () => {
        this.status = "READY";
    }
}

export interface EditItem<T> {
    editing: boolean
    item: T | undefined
}

export interface EditContext<T> extends EditItem<T> {
    setValue: ((name: keyof T, value: any | undefined) => void)
    getValue: ((name: keyof T) => any)
    isValid: ((name: keyof T) => string | undefined)
}

export type ResetAction = 'BUILD' | 'REMOVE'

export interface NestedContextSupport<T> {
    buildItem?: (() => void)
    item: (() => T | undefined)
    editing: (() => boolean)
    resetAction?: ResetAction
}

export interface FieldValidationError {
    field: string
    errors: string[] | string
}

export abstract class NestedEntityEditContext<T extends Entity> implements EditContext<T> {
    private wasBuilt: boolean = false
    private readonly _support: NestedContextSupport<T>
    private readonly _id: string;

    _parent?: NestedEntityEditContext<any>
    _nc?: (<U extends Entity, CTX extends NestedEntityEditContext<U>>(context: CTX) => CTX)

    constructor(id: string, support: NestedContextSupport<T>) {
        this._id = id;
        this._support = support
    }

    @computed get editing(): boolean {
        return this._support.editing()
    }

    get id() {
        return this._id
    }

    get support() {
        return this._support
    }

    @computed get item(): T | undefined {
        return this._support.item()
    }

    getValue = (name: keyof T): any => {
        return this._getValue(name)
    }

    isValid = (name: keyof T): string | undefined => {
        const validator = this._getValidator(name);
        return validator && validator(this.getValue(name))
    }

    @action setValue = (name: keyof T, value: any): void => {
        this._setValue(name, value)
    }

    @action nestedContext = <U extends Entity, CTX extends NestedEntityEditContext<U>>(context: CTX): CTX => {
        if (this._nc)
            return this._nc(context)

        if (this._parent)
            return this._parent.nestedContext(context)

        throw newError(`Cannot find nestedContext method in chain for ${this.id}`)
    }

    @action initialize = (): void => {
        this._parent?.initialize()
        if (!this.item && !this.wasBuilt) {
            if (!this._support.buildItem) {
                throw newError(`No method defined to build the item for ${this.id}`)
            }

            this._support.buildItem()
        }

        this.wasBuilt = true
    }

    @action resetAndInitialize = () => {
        this.wasBuilt = false
        return this.initialize()
    }

    protected _getValue(name: keyof T): any {
        return this.item?.[name] || ''
    }

    protected _getValidator(name: keyof T): ((val: any) => string | undefined) {
        return () => undefined
    }

    protected _setValue(name: keyof T, value: any) {
        const item = this.item
        if (item) {
            item[name] = value
        } else {
            console.warn('Trying to set value on undefined object', this.id, name, value)
        }
    }
}

export abstract class RemoteEntityStore<API, RS extends HasApi<API> & HasMessageHandler, T extends Entity> extends RemoteStore<API, RS>
    implements EditContext<T> {

    private _dirty: boolean = false
    private _nested: NestedEntityEditContext<Entity>[] = []

    _setValue<U extends Entity>(obj: U | undefined, name: keyof U, value: any | undefined) {
        if (obj) {
            obj[name] = value
        } else {
            console.warn('Trying to set value on undefined object', name, value)
        }
    }

    _getValue<U extends Entity>(obj: U | undefined, name: keyof U) {
        return (obj && obj[name]) || ''
    }

    _isValid<U extends Entity>(item: U | undefined, name: keyof U, value: any): string | undefined {
        return undefined
    }

    protected _immediate(): boolean {
        return false
    }

    protected _validateEntity(): undefined | FieldValidationError | FieldValidationError[] {
        return undefined
    }

    abstract get editing(): boolean;

    abstract get item(): T | undefined;

    abstract reset(): void;

    @action setValue = (name: keyof T, value: any | undefined) => {
        if (!this.editing)
            throw newError('Not in edit state for setValue')

        this._dirty = true
        this._setValue(this.item, name, value)
    }

    getValue = (name: keyof T) => {
        return this._getValue(this.item, name)
    }

    isValid = (name: keyof T) => {
        return this._isValid(this.item, name, this.getValue(name))
    }

    @action nestedContext = <T extends Entity, CTX extends NestedEntityEditContext<T>>(context: CTX): CTX => {
        const bound: any = this._nested.find(ctx => ctx.id === context.id)
        if (bound)
            return bound

        // Add the context to those registered with the store...
        context._nc = this.nestedContext
        this._nested.push(context)

        // Do we need to initialize?
        if (this._immediate()) {
            context.initialize()
        }

        return context
    }

    @action protected initializeNested = () => {
        this._nested.forEach(value => value.initialize())
    }

    @action protected clearNested = () => {
        this._nested = []
    }

    @action protected resetNested = () => {
        // Remove those marked for removal
        this._nested = this._nested
            .filter(val => val.support.resetAction === undefined || val.support.resetAction === "BUILD")
            .map(val => {
                val.resetAndInitialize()
                return val
            })
    }

    validateEntity = (): boolean => {
        const errors = this._validateEntity();
        console.log('validate', errors)
        if (errors) {
            // TODO show as error
            // TODO show all field validations
            this.showWarning('One or more required fields are not captured.', DEFAULT_DISMISS_TIMEOUT)
        }

        return !errors
    }

    @computed get isEntityValid() {
        return !this._validateEntity()
    }

    //
    // TODO this dirty code isn't working at the moment. It should prompt the user before allowing a router change.
    //

    @action setDirty = () => {
        if (!this._dirty)
            console.log('set dirty')
        this._dirty = true
    }

    @action clearDirty = () => {
        console.log('clear dirty')
        this._dirty = false
    }

    @computed get dirty() {
        return this._dirty
    }

    protected createFieldValidationError = (field: string, errors: string | string[]): FieldValidationError => {
        return {
            field, errors
        }
    }

    protected simpleValidation = (isValid: boolean): FieldValidationError | undefined => {
        return isValid
            ? undefined
            : this.createFieldValidationError('FORM', 'One or more required fields are not captured.')
    }
}

export type PatchType = "full"

export interface Patch {
    type: PatchType
    data: any
}

export abstract class RemoteItemStore<API, RS extends HasApi<API> & HasMessageHandler, T extends Entity> extends RemoteEntityStore<API, RS, T> {
    @observable private _id?: string;
    @observable private _item?: T;
    @observable private _editable?: T;

    protected abstract _find(api: API, id: string, params: RequestParams): Promise<T>

    protected _save(api: API, id: string, patch: Patch, params: RequestParams): Promise<T> {
        console.log("_save", id, patch);
        return Promise.reject({message: 'NOT IMPLEMENTED', store: this, patch});
    }

    protected _delete(api: API, id: string, params: RequestParams): Promise<void> {
        console.log("_delete", id);
        return Promise.reject({message: 'NOT IMPLEMENTED', store: this});
    }

    // Although onSuccess should not throw an exception, the method should be prepared for that to happen, and
    // handle the exception by logging it appropriately.
    invokeOneAndReload = <T>(method: ((api: API, params: RequestParams) => PromiseLike<T>), onSuccess?: (val: T) => void) => {
        this.invokeApi(method)
            .pipe(
                take(1),
                tap(value => onSuccess && onSuccess(value))
            )
            .subscribe({
                error: err => this.showError(err),
                complete: () => this.reload()
            })
    }

    @action reload = () => {
        if (!this.id)
            return

        return this.load(this.id)
    }

    @computed get editing() {
        return this._editable !== undefined
    }

    @computed get item() {
        return this.editing ? this._editable : this._item
    }

    @computed get id() {
        return this._id
    }

    @computed get idOrThrow(): string {
        if (!this._id)
            throw newError('Expected id to be present')

        return this._id
    }

    @action edit = () => {
        if (this._item && !this._editable) {
            this._editable = toJS(this._item);
            this.initializeNested();
        }
    }

    @action save = (): void => {
        if (!this._editable)
            throw newError('_editable is null during save. Should not happen.')

        if (!this._id)
            throw newError('_id is null during save. Should not happen')

        if (!this.validateEntity())
            return

        const patch: Patch = {
            type: "full",
            data: toJS(this._editable)
        }

        const id = this._id
        return this.invokeOneAndReload((api, params) => this._save(api, id, patch, params),
            () => {
                this._editable = undefined
                this.clearNested()
            })
    }

    @action delete = (): void => {
        if (!this._id)
            throw newError('_id is null during delete. Should not happen')

        const id = this._id
        return this.invokeOneAndReload((api, params) => this._delete(api, id, params),
            () => {
                this._editable = undefined
                this.clearNested()
            })
    }

    @action cancel = () => {
        this._editable = undefined
        this.clearNested()
    }

    @action reset = () => {
        if (this._item) {
            this._editable = toJS(this._item);
            this.clearNested()
            this.clearDirty()
        }
    }

    @action load = (id: string) => {
        // Ensure we don't have an editing state in play
        this._editable = undefined
        this.clearNested()

        // Do we delete the values on trigger, or only when ready?
        if (this._clearOnTrigger()) {
            this._id = undefined;
            this._item = undefined;
        }

        return this.invokeOne((api, params) => this._find(api, id, params),
            (val) => {
                this._id = id
                this._item = val
            })
    }
}

export abstract class RemoteCreateStore<API, RS extends HasApi<API> & HasMessageHandler, T extends Entity> extends RemoteEntityStore<API, RS, T> {
    @observable _item?: T
    onSave?: (value: T) => void

    protected abstract _create(): T;

    protected _immediate() {
        return true
    }

    @computed get editing(): boolean {
        return true
    }

    @computed get item() {
        if (!this._item) {
            // Cannot call this.createItem() in a computed block - mobx then throws an exception. So, raise an
            // error and make a suggestion.
            throw newError(`this._item was not initialized for the create store (${this}). Perhaps initialize similar to PetCreateStore.`)
        }

        return this._item
    }

    protected _save(api: API, item: T, params: RequestParams): Promise<T> {
        console.log("_save", toJS(this.item));
        return Promise.reject({message: 'NOT IMPLEMENTED', store: this, item: this.item});
    }

    @action save = (postSave?: ((result: T) => void)) => {
        if (!this._item)
            throw newError('_item is null during save. Should not happen.')

        if (!this.validateEntity())
            return

        // Allow the onSave to be called if there isn't a postSave defined.
        if (!postSave && this.onSave)
            postSave = this.onSave

        const item = this._item
        return this.invokeOne((api, params) => this._save(api, item, params),
            (val) => postSave ? postSave(val) : this.reset())
    }

    @action reset = () => {
        this._item = this._create()
        this.resetNested()
        this.onSave = undefined
        this.clearDirty()
    }
}

export abstract class RemoteSearchStore<API, RS extends HasApi<API> & HasMessageHandler, T extends PagedArray, U> extends RemoteStore<API, RS> {
    @observable paged?: T
    @observable query: string = ''
    @observable offset: number = 0
    @observable limit?: number = 20
    @observable fuzzy: boolean = false
    @observable selection: SelectionModel<U> = singleSelection<U>()

    @observable _inSync: boolean = true

    private readonly clearOnTrigger: boolean = false

    private onQueryUpdateSubject?: Subject<any>

    constructor(rootStore: RS, triggerOnQueryUpdate?: boolean, clearOnTrigger?: boolean, expandos?: string) {
        super(rootStore, expandos)

        clearOnTrigger && (this.clearOnTrigger = clearOnTrigger)
        if (triggerOnQueryUpdate) {
            this.onQueryUpdateSubject = new Subject<any>()
            const queryUpdate = this.onQueryUpdateSubject.pipe(debounce(() => interval(200)));
            queryUpdate.subscribe(() => {
                // TODO this is funky
                this.trigger()
            })
        }
    }

    protected abstract _search(api: API, data: any, params: RequestParams): Promise<T>

    protected abstract _values(): U[] | undefined;

    protected _clearOnTrigger(): boolean {
        return this.clearOnTrigger;
    }

    @action setQuery = (query: string) => {
        this.query = query;
        this._inSync = false
        if (!isEmptyString(query) && this.onQueryUpdateSubject) {
            this.onQueryUpdateSubject.next(query)
        }
    }

    @action setFuzzy = (fuzzy: boolean) => {
        this.fuzzy = fuzzy;
    }

    @action trigger = (): void => {
        if (this._clearOnTrigger()) {
            this.paged = undefined;
        }

        if (isEmptyString(this.query))
            return

        this.invokeOne((api, params) => this._search(api, {
            q: (this.fuzzy ? '~' : '') + this.query,
            limit: this.limit,
            offset: this.offset.toString()
        }, params), result => {
            this.setResults(result)
            this.setInSync(true)
        })
    }

    @action clear = () => {
        this.paged = undefined;
        this.query = '';
        this.offset = 0;
    }

    @action setLimit = (limit: number) => {
        this.limit = limit
    }

    @action clearLimit = () => {
        this.limit = undefined
    }

    @action setSelectionModel = (model: SelectionModel<U>) => {
        this.selection = model
    }

    @computed get isInSync() {
        return this._inSync
    }

    @action protected setResults = (val: T) => {
        this.paged = val
    }

    @action private setInSync = (inSync: boolean) => {
        this._inSync = inSync
    }

    values = () => {
        const val = this._values()
        return val && [...val]
    }

    searchSupport = (): SearchSupport => ({
        data: {query: this.query, fuzzy: this.fuzzy, busy: this.status === "BUSY"},
        setQuery: this.setQuery,
        search: () => this.trigger(),
        toggleFuzzy: () => this.setFuzzy(!this.fuzzy),
        clear: this.clear
    })
}
