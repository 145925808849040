import { newError } from "./utils/builder";

export type ExecEnv = "dev" | "uat" | "staging" | "live";

export interface Constants {
  apiBaseUrl(): string;
  execEnv(): ExecEnv;
  version(): string;
  gaId(): string;
  sentry: {
    enable: boolean;
    dsn: string;
  };
  maps: {
    apiKey: string;
  };
  dev: boolean;
}

const dev: Constants = {
  apiBaseUrl: () => {
    // return 'https://api.live.identipet.org'
    return 'https://api.dev.identipet.org'
    // return "https://70c0-102-221-101-226.eu.ngrok.io";
  },
  execEnv(): ExecEnv {
    return "dev";
  },
  version(): string {
    return version();
  },
  gaId(): string {
    return "";
  },
  dev: true,
  maps: {
    apiKey: "AIzaSyCpMley8Kj7fpAcoON1Wx1MCLFCvthK-A4",
  },
  sentry: {
    enable: false,
    dsn: "https://9263c6a4223d4daa9266ab4fe720f696@o4504876098256896.ingest.sentry.io/4504876352077824",
  },
};

const prod: Constants = {
  apiBaseUrl: () => {
    const loc = document.location;
    const partial = loc.hostname.match(/^app\.(.*)$/)?.[1];
    if (!partial) {
      if (loc.hostname === "localhost") {
        return dev.apiBaseUrl();
      }

      throw newError("Mismatched hostname, found " + loc.hostname);
    }

    const port = loc.port ? `:${loc.port}` : "";
    return `${loc.protocol}//api.${partial}${port}`;
  },
  execEnv(): ExecEnv {
    const loc = document.location;
    const partial = loc.hostname.match(/^app\.(\w+).*$/)?.[1] as
      | "dev"
      | "uat"
      | "staging"
      | "live";
    return partial || "dev";
  },
  version(): string {
    return version();
  },
  gaId(): string {
    return "UA-43843240-3";
  },
  dev: false,
  maps: {
    apiKey: "AIzaSyCpMley8Kj7fpAcoON1Wx1MCLFCvthK-A4",
  },
  sentry: {
    enable: true,
    dsn: "https://9263c6a4223d4daa9266ab4fe720f696@o4504876098256896.ingest.sentry.io/4504876352077824",
  },
};

const version = () => {
  const buildNumber = process.env.REACT_APP_BUILD_NUMBER || "yy";
  const commit = process.env.REACT_APP_COMMIT || "xxxxxx";

  return `${commit.substr(0, 6)}-${buildNumber}`;
};

export const theConstants = process.env.NODE_ENV === "development" ? dev : prod;
