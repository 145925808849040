import {observer} from "mobx-react-lite";
import {useStores} from "../contexts";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    List,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Switch
} from "@material-ui/core";
import React, {Fragment, useEffect} from "react";
import {PartyStore} from "../stores/PartyStores";
import {Party} from "idpet-api";
import {PartyRoleType} from "../utils";
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';

interface Toggle {
    key: PartyRoleType
    label: string
    description?: string
}

const ToggleDialog = observer(<T extends Party>(props: { store: PartyStore<T>, toggles: Toggle[] }) => {
    useEffect(() => {
        props.store.setAdjustableRoles(props.toggles.map(value => value.key))
    })

    return (
        <Dialog onClose={props.store.cancelRoleToggle}
                open={props.store.showRoleToggle}
                fullWidth={true}
                maxWidth={'sm'}
        >
            <DialogTitle>Toggle Roles</DialogTitle>

            <DialogContent>
                <List>
                    {props.toggles.map(toggle => (
                        <ListItem key={toggle.key}>
                            <ListItemIcon>
                                {props.store.isChanged(toggle.key)
                                    ? (<StarIcon color={"secondary"}/>)
                                    : (<StarBorderIcon color={"disabled"}/>)}
                            </ListItemIcon>
                            <ListItemText primary={toggle.label} secondary={toggle.description}/>
                            <ListItemSecondaryAction>
                                <Switch
                                    edge="end"
                                    onChange={(event, checked) => props.store.toggleRole(toggle.key, checked)}
                                    checked={props.store.hasRole(toggle.key)}
                                    // inputProps={{ 'aria-labelledby': 'switch-list-label-wifi' }}
                                />
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))}
                </List>
            </DialogContent>

            <DialogActions>
                <Box style={{flexGrow: 1}}>
                    <Button variant={"text"} color={"primary"} disabled={!props.store.canSave}
                            onClick={props.store.resetSelection}>Reset</Button>
                </Box>

                <Button variant={"text"} onClick={props.store.cancelRoleToggle}>Cancel</Button>
                <Button variant={"contained"} color={"primary"} disabled={!props.store.canSave}
                        onClick={props.store.saveRoleUpdates}>Save</Button>
            </DialogActions>
        </Dialog>
    )
})

export const PartyRoleToggleDialog = observer(() => {
    const person = useStores().person.edit
    const org = useStores().org.edit

    return (
        <Fragment>
            <ToggleDialog store={person} toggles={[]}/>
            <ToggleDialog store={org} toggles={[
                {key: "VET_CLINIC", label: 'Vet', description: 'Highlighted in app, emergency contacts'},
                {key: "WELFARE", label: 'Welfare', description: 'Can have pets that are available for adoption'},
                {key: "BREEDER", label: 'Breeder', description: 'Informational at present'},
            ]}/>
        </Fragment>
    )
})
