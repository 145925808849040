import {Dialog, DialogContent, DialogTitle, Typography} from "@material-ui/core";
import React from "react";

export const LoadError = () => (
    <Dialog
        open={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">
            {"There was a problem in loading the application"}
        </DialogTitle>
        <DialogContent>
            <Typography variant={"body1"} paragraph>
                Our apologies for this error - we know how important this service is, and
                we aim to keep it both up-to-date and available.
            </Typography>

            <Typography variant={"body1"} paragraph color={"secondary"}>
                Please try and refresh the page again in a few minutes.
            </Typography>
        </DialogContent>
    </Dialog>
)

