import {observer} from "mobx-react-lite";
import {useStores} from "../contexts";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import React, {Fragment} from "react";
import {
    Box,
    Button,
    FormControl,
    InputLabel,
    List,
    ListItemText,
    MenuItem,
    Select,
    TextField,
    Typography
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {columnStyles} from "../styles/EntityLayoutStyles";
import {SubscriptionQuote} from "idpet-api";
import {Alert} from "@material-ui/lab";

const QuoteContent = observer((props: { quote: SubscriptionQuote }) => {
    return (
        <div>
            <div>
                {/*
                <Typography gutterBottom variant={"overline"} display={"inline"}>
                    Amount:
                </Typography>
*/}

                <Typography gutterBottom variant={"h4"} display={"inline"}>
                    <span style={{color: 'silver'}}>R </span>
                    {props.quote.amount}
                </Typography>
            </div>

            <List>
                {props.quote?.tips?.map(tip => (
                    <ListItemText>{tip.startsWith('If you') ? (
                        <Alert severity="warning">{tip}</Alert>
                    ) : (
                        <Alert severity="info">{tip}</Alert>
                    )}
                    </ListItemText>
                ))}
            </List>
        </div>
    )
})

const useStyles = makeStyles((theme) => ({
    formControl: {
        marginBottom: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const PaymentMethods = observer(() => {
    const {prn} = useStores().person
    const {config} = useStores()
    const ctlClasses = useStyles()

    const handleChange = (event: any) => {
        prn.setPaymentType(event.target.value);
    }

    return (
        <Fragment>
            <div>
                <FormControl className={ctlClasses.formControl} variant={"filled"} fullWidth>
                    <InputLabel id="payment-type-label">Payment Type</InputLabel>
                    <Select
                        labelId="payment-type-label"
                        id="subscription-payment-type"
                        value={prn.paymentType}
                        onChange={handleChange}
                    >
                        <MenuItem value={''}>* Choose an option</MenuItem>
                        {config.getBooleanFeature('feature.prn.payment.paygate') && (
                            <MenuItem value={'paygate'}>Paygate</MenuItem>
                        )}
                        {config.getBooleanFeature('feature.prn.payment.zapper') && (
                            <MenuItem value={'zapper'}>Zapper</MenuItem>
                        )}
                        {config.getBooleanFeature('feature.prn.payment.voucher') && (
                            <MenuItem value={'voucher'}>Voucher</MenuItem>
                        )}
                    </Select>
                </FormControl>
            </div>

            {prn.isVoucher && (
                <div>
                    <FormControl className={ctlClasses.formControl} fullWidth>
                        <TextField id="voucher-code"
                                   label="Voucher Code"
                                   variant="filled"
                                   onChange={(e: any) => prn.setVoucherCode(e.target.value)}
                                   helperText={prn.initiate?.fieldErrors?.['voucherCode']}
                                   error={prn.initiate && !prn.initiate.successful}
                                   value={prn.voucherCode}/>
                    </FormControl>
                </div>
            )}
        </Fragment>
    )
})

const Content = observer(() => {
    const classes = columnStyles()
    const {prn} = useStores().person

    return prn.quote ? (
        <Box className={classes.container}>
            <Box className={classes.column}>
                <QuoteContent quote={prn.quote}/>
                <PaymentMethods/>
            </Box>
        </Box>
    ) : (
        <Alert severity="info">Building your quote...</Alert>
    )
})

const Buttons = observer(() => {
    const {prn} = useStores().person
    return (
        <Fragment>
            {/*
            <FormControlLabel
                control={<Checkbox checked={prn.agreed}
                                   onChange={(event, val) => prn.setAgreed(val)}
                                   name="accept"/>}
                label="Agree Terms?"
            />
*/}

            <Button variant={"contained"} onClick={() => prn.hide()}>
                Cancel
            </Button>

            {prn.isVoucher ? (
                <Button variant="contained" color="primary"
                        disabled={!prn.redeemEnabled}
                        onClick={() => prn.startProcessing()}>
                    Redeem voucher
                </Button>
            ) : prn.initiate ? (<Fragment/>) :
                <Button variant="contained" color="primary"
                        disabled={!prn.gatewayEnabled}
                        onClick={() => prn.startProcessing()}>
                    Choose {prn.paymentType}
                </Button>
            }

            {prn.initiate?.urlContinuation && (
                <form method={prn.initiate.urlMethod} action={prn.initiate.urlContinuation}>
                    {Object.keys(prn.initiate.urlParams || {}).map(key => (
                        <input type={"hidden"} name={key} value={prn.initiate?.urlParams?.[key]}/>
                    ))}
                    <Button variant="contained" color="secondary" type={"submit"}>
                        Pay via {prn.paymentType}
                    </Button>
                </form>
            )}
        </Fragment>
    )
})

const PRNSubscribeDialog = observer(() => {
    const {prn} = useStores().person
    return (
        <Dialog
            open={prn.open}
            onClose={prn.hide}
            maxWidth={"sm"}
            fullWidth={true}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                Pet Rescuer Network Subscription
            </DialogTitle>

            <DialogContent>
                <Content/>
            </DialogContent>

            <DialogActions>
                <Buttons/>
            </DialogActions>
        </Dialog>
    )
})

export default PRNSubscribeDialog
