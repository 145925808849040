import React from "react";
import {Link as RouterLink, LinkProps as RouterLinkProps} from "react-router-dom";
import {ListItem} from "@material-ui/core";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

interface ListItemLinkProps {
    icon?: React.ReactElement
    primary: React.ReactNode
    secondary?: React.ReactNode
    to?: string
    onClick?: (event: any) => void
    color?: string
}

export const ListItemLink = (props: ListItemLinkProps) => {

    const renderLink = React.useMemo(
        () =>
            React.forwardRef<any, Omit<RouterLinkProps, 'to'>>((itemProps, ref) => (
                props.to ? <RouterLink to={props.to} ref={ref} {...itemProps} /> :
                    // eslint-disable-next-line
                    <a ref={ref} {...itemProps}/>
            )),
        [props.to],
    );

    return (
        <li>
            <ListItem button component={renderLink} onClick={props.onClick}>
                {props.icon ? <ListItemIcon>{props.icon}</ListItemIcon> : null}
                <ListItemText primary={props.primary} secondary={props.secondary} color={props.color}/>
            </ListItem>
        </li>
    );
}
