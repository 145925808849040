import {green, grey, lightBlue} from "@material-ui/core/colors";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";

export const primary = lightBlue[600];

export const valueTrue = green[500];
export const valueFalse = grey[600];


export const typographyStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            color: grey["600"],
            marginBottom: theme.spacing(1),
        },
        item: {
            marginBottom: theme.spacing(1),
        }
    }),
);
