import {observer} from "mobx-react-lite";
import {TextField} from "@material-ui/core";
import React from "react";
import {isString} from "../../utils";
import {EditState, Entity} from "idpet-api";
import {EditableFieldProps, styles, title} from "./index";
import {DatumView} from "../DatumView";

export const Text = observer(<T extends Entity>(props: EditableFieldProps<T> & { rows?: number }) => {
    const classes = styles();
    const value = props.context.getValue(props.field)
    const editState: EditState = props.context.item?._editState || {editable: true}
    const editable: boolean = editState.editable &&
        (!editState.fields || editState.fields.indexOf(props.field.toString()) < 0)
    return (
        <div>
            {editable && props.context.editing ? (
                <TextField label={title(props)} variant="filled" value={value}
                           fullWidth
                           multiline={props.rows !== undefined && props.rows > 1}
                           rows={props.rows || 1}
                           required={props.required}
                           onChange={event => props.context.setValue(props.field, event.target.value)}
                           className={classes.item}
                           autoFocus={props.autoFocus}
                           onBlur={props.onBlur}/>
            ) : (
                <DatumView title={title(props)} children={value && isString(value) ? value : '-'}/>
            )}
        </div>
    )
})
