import React from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../contexts";
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import {columnStyles} from "../styles/EntityLayoutStyles";
import {DropdownValueSupplier, EditableText} from "../components/editable";
import {Product} from "idpet-api";
import {Dropdown} from "../components/editable/Dropdown";

const ProductSelect = observer(() => {
    const classes = columnStyles()
    const {create} = useStores().order
    const {search} = useStores().product

    const valueSupplier: DropdownValueSupplier<Product> = {
        defaultValue(): Product | undefined {
            return undefined
        }, equal(a, b): boolean {
            return (!a || !b) ? false : a.id === b.id
        }, label(value): string {
            return value.name
        }, values(): Product[] {
            return search.idpetProducts
        }
    }

    const orderItem = create.ctxOrderItem();
    return (
        <Box className={classes.container}>
            <Box className={classes.column}>
                <Dropdown context={create.ctxOrderItem()} field={"product"} valueSupplier={valueSupplier} required/>
            </Box>
            <Box className={classes.column}>
                <EditableText context={orderItem} field={"quantity"} title={"Quantity"} required/>
            </Box>
        </Box>
    )
})

export const OrderProductDialog = observer(() => {
    const {create} = useStores().order

    return (
        <Dialog onClose={create.cancel}
                open={create.isEditingOrderItem}
            // fullWidth={true}
                maxWidth={'lg'}
        >
            <DialogTitle>Add a Product</DialogTitle>
            <DialogContent>
                {create.isEditingOrderItem && (<ProductSelect/>)}
            </DialogContent>
            <DialogActions>
                <Button variant={"text"} onClick={() => create.cancel()}>Cancel</Button>
                <Button variant={"contained"} color={"primary"} onClick={() => create.saveItem()}>Save</Button>
            </DialogActions>
        </Dialog>
    )
})
