import {RemoteSearchStore} from "../RemoteStore";
import {Animal, Api, PagedAnimals, PagedPets, Party, Pet, RequestParams} from "idpet-api";
import {RootStore} from "../RootStore";
import {action, computed, observable} from "mobx";
import {newEmptyPaged, newError} from "../../utils/builder";
import {RowData} from "@material-ui/data-grid";
import {newBulkFlagAdoptable, newBulkTransferOwnership} from "../../utils/actions";
import {asArray, getRoleOfType} from "../../utils";
import {Filter, newStringFilter, simpleStringMatch} from "../../utils/filter";
import {EntityRowData, TableRowColumns} from "../table";

export type AnimalRowData = EntityRowData<Animal>

export interface HasPartyAnimalsStore {
    party: Party | undefined
    partyAnimalsStore: () => PartyAnimalsStore
}

export class PartyAnimalsStore extends RemoteSearchStore<Api, RootStore, PagedAnimals, Animal> {
    @observable _selected: AnimalRowData[] = []
    @observable partyId?: string
    @observable showDeceased: boolean = false
    readonly rowColumns: TableRowColumns = new TableRowColumns()
    readonly filter: Filter<string> = newStringFilter()

    protected _search(api: Api, data: any, params: RequestParams): Promise<PagedPets> {
        if (!this.partyId) {
            return Promise.resolve(newEmptyPaged())
        }

        return api.parties.listPartyAnimals(this.partyId, {
            q: this.query,
            expandos: this.expandos(['profileImage', 'roles', 'chips']),
            limit: 4000,
        }, params)
    }

    protected _values(): Animal[] | undefined {
        return this.paged?.values
    }

    protected _clearOnTrigger(): boolean {
        return true
    }

    @computed get rows(): AnimalRowData[] {
        const values = this.values()
        if (!values)
            return []

        const searchTerms = (value: Animal): string[] => {
            return [
                value.name || '',
                value.species.commonName || '',
                value.breed || '',
                ...asArray(value.chips).map(chip => chip.serialNumber)
            ]
        }

        return values
            .filter(value => simpleStringMatch(this.filter.debounced, searchTerms(value)))
            .filter(value => this.showDeceased || value.deceasedDate === undefined)
            .map(animal => new EntityRowData<Animal>(animal))
    }

    @computed get deceasedCount() {
        const vals =  this.values()
        if (!vals)
            return 0

        return vals.filter(val => val.deceasedDate !== undefined).length
    }

    @computed get selected() {
        return this._selected
    }

    @action setSelected = (val: RowData[]) => {
        // @ts-ignore
        this._selected = val
    }

    @action load = (partyId: string) => {
        this.partyId = partyId
        this.setQuery('*')
        this.trigger()
    }

    @action setShowDeceased = (showDeceased: boolean) => {
        this.showDeceased = showDeceased
    }

    @computed get notAdoptable(): Animal[] {
        return this.values()?.filter(item => !getRoleOfType(item, "ADOPTABLE")) || []
    }

    @action makeAdoptable() {
        if (this.selected.length === 0) {
            this.showError('No animals selected.')
            return
        }

        const action = newBulkFlagAdoptable(this.selected.map(value => value.entity))
        this.invokeOne((api, params) =>
            api.batch.initiateBatchAction(action, undefined, params), () => this.trigger())
    }

    @action transfer(target: Party) {
        if (this.selected.length === 0) {
            this.showError('No animals selected.')
            return
        }

        const asPet = (party: Party): Pet => {
            const result = getRoleOfType(party, "PET")
            if (!result)
                throw newError('Party not pet')

            return result as Pet
        }

        const action = newBulkTransferOwnership(target, this.selected.map(value => asPet(value.entity)))
        this.invokeOne((api, params) =>
            api.batch.initiateBatchAction(action, undefined, params), () => this.trigger())
    }
}
