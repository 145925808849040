import {Entity, Region, Type} from "idpet-api";
import {EditContext, RemoteCreateStore, RemoteItemStore} from "../../stores/RemoteStore";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {grey} from "@material-ui/core/colors";
import {ReactElement} from "react";
import {newRegion, TypeType} from "../../utils/builder";
import {theRootStore} from "../../stores/RootStore";

export const title = (props: EditableFieldProps<any>) => {
    if (props.title)
        return props.title;

    let result = props.field?.toString()
    return result && _title(result)
}

export const _title = (val: string) => {
    let result = val.toString()
    result = result.replace(/([A-Z])/, " $1")
    result = result.charAt(0).toUpperCase() + result.substring(1)

    return result;
}

export const styles = makeStyles((theme: Theme) =>
    createStyles({
        item: {
            marginBottom: theme.spacing(1),
            width: '100%',
        },
        title: {
            color: grey["600"],
            marginBottom: theme.spacing(1),
        },
        speedDial: {
            position: 'absolute',
            right: 0,
            top: theme.spacing(2),
        },
        speedDialDanger: {
            color: theme.palette.secondary.main,
        },
    })
)

export interface EditableProps<T extends Entity> {
    title?: string
    context: EditContext<T>
    autoFocus?: boolean
    required?: boolean
    compact?: boolean
    onBlur?: ((event?: any) => void)
    helperText?: string
    toggleRequired?: () => void
}

export interface EditableFieldProps<T extends Entity> extends EditableProps<T> {
    field: keyof T
}

export interface DropdownValueSupplier<T> {
    values(): T[]

    defaultValue(): T | undefined

    label(value: T): string

    equal(a: T, b: T): boolean
}

export const valueSupplierForType = (category: TypeType): DropdownValueSupplier<Type> => {
    return {
        defaultValue(): Type | undefined {
            return theRootStore.types.defaultValue(category)
        }, equal(a, b): boolean {
            return a.id === b.id
        }, label(value): string {
            return value.commonName
        }, values(): Type[] {
            return theRootStore.types.values(category)
        }
    }
}

export const regionSupplier = (regions: Region[]): DropdownValueSupplier<Region> => {
    return {
        defaultValue(): Region | undefined {
            return newRegion('-', regions[0].type)
        }, equal(a, b): boolean {
            return a.id === b.id
        }, label(value): string {
            return value.name
        }, values(): Region[] {
            return regions
        }
    }
}

export interface EditableDropdownProps<T extends Entity, U> extends EditableFieldProps<T> {
    valueSupplier: DropdownValueSupplier<U>
}

export interface ActionItem {
    icon: ReactElement
    name: string
    danger?: boolean
    action?: (() => void)
    visible?: (() => boolean)
    enabled?: (() => boolean)
}

interface SpeedDialProps {
    items?: ActionItem[]
    showDefault?: boolean
}

export interface EditSpeedDialProps<T extends Entity> extends SpeedDialProps {
    store: RemoteItemStore<any, any, T>
}

export interface CreateSpeedDialProps<T extends Entity> extends SpeedDialProps {
    store: RemoteCreateStore<any, any, T>
}

export {Chip as EditableChip} from "./Chip"
export {Date as EditableDate} from "./Date"
export {Dropdown as EditableDropdown} from "./Dropdown"
export {EditSpeedDial} from "./SpeedDials"
export {CreateSpeedDial} from "./SpeedDials"
export {Email as EditableEmail} from "./Email"
export {Name as EditableName} from "./Name"
export {Phone as EditablePhone} from "./Phone"
export {Text as EditableText} from "./Text"
