import React, {Fragment} from "react";
import {Avatar, ListItem, ListItemAvatar, Typography} from "@material-ui/core";
import {Qrcode as QrCodeIcon} from "mdi-material-ui";
import Accordion from '@material-ui/core/ExpansionPanel';
import AccordionSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionDetails from "@material-ui/core/ExpansionPanelActions";

export interface QrCodeProps {
    data: string | undefined
    size?: number
}

export const QrCode = (props: QrCodeProps) => {
    const width = props.size || 160
    const height = props.size || 160
    const src = `https://chart.googleapis.com/chart?cht=qr&chl=${props.data}&chs=${width}x${height}&choe=UTF-8&chld=M|2`
    return props.data ? (
        <img src={src} alt={"QR code"}/>
    ) : (
        <Fragment/>
    )
}

export const QrCodeListItem = (props: QrCodeProps & { showIcon?: boolean }) => {
    const showIcon = props.showIcon === undefined || props.showIcon
    return (
        <ListItem alignItems="center" disableGutters={!showIcon}>
            {showIcon && (
                <ListItemAvatar>
                    <Avatar>
                        <QrCodeIcon/>
                    </Avatar>
                </ListItemAvatar>
            )}
            <Accordion variant={"elevation"} elevation={0}>
                <AccordionSummary expandIcon={<ExpandMoreIcon/>} style={{padding: 0}}>
                    <Typography variant={"body1"}>QR Code</Typography>
                </AccordionSummary>
                <AccordionDetails style={{justifyContent: 'center'}}>
                    <QrCode {...props}/>
                </AccordionDetails>
            </Accordion>
        </ListItem>
    )
}
