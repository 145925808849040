import React, {Fragment} from "react";
import {PetRecoveryDialog} from "./PetRecoveryDialog";
import {CremationPetDialog} from "./CremationPetDialog";
import {ImplanterDialog} from "./ImplanterDialog";
import {OrderProductDialog} from "./OrderProductDialog";
import {MenuDialog} from "./MenuDialog";
import {AlertDialog} from "./AlertDialogs";
import {ShipmentCreateDialog} from "./ShipmentDialogs";
import {useAuth0} from '@auth0/auth0-react';
import TermsAndMobileDialog from "./TermsAndMobileDialog";
import PetRescuerDialog from "./PetRescuerDialog";
import ProfilePersonCreateDialog from "./ProfilePersonCreateDialog";
import {NewVersionDialog} from "./NewVersionDialog";
import {PartySearchDialog} from "./PartySearchDialog";
import {PartyRoleToggleDialog} from "./PartyRoleToggleDialog";
import PRNSubscribeDialog from "./PRNSubscribeDialog";
import {RescheduleTaskDialog, ResolveTaskDialog} from "./TaskDialogs";
import {PrivateQrDialog} from "./PrivateQrDialog";

// TODO get smarter about code-splitting these...
export const RenderDialogs = () => {
    const {user} = useAuth0()
    return user ? (
        <Fragment>
            <AlertDialog/>
            <CremationPetDialog/>
            <ImplanterDialog/>
            <MenuDialog/>
            <NewVersionDialog/>
            <OrderProductDialog/>
            <PRNSubscribeDialog/>
            <PartyRoleToggleDialog/>
            <PartySearchDialog/>
            <PetRecoveryDialog/>
            <PetRescuerDialog/>
            <PrivateQrDialog/>
            <ProfilePersonCreateDialog/>
            <RescheduleTaskDialog/>
            <ResolveTaskDialog/>
            <ShipmentCreateDialog/>
            <TermsAndMobileDialog/>
        </Fragment>
    ) : <Fragment/>
}
