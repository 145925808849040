import React from 'react';
import {
    createStyles,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    TextField,
    Theme,
    Typography,
    withStyles
} from '@material-ui/core';

import {NavLink} from "react-router-dom";

import {grey, lightBlue} from '@material-ui/core/colors';
import {makeStyles} from "@material-ui/core/styles";
import {MenuItem} from "../stores/MenuItemStore";
import {useStores} from "../contexts";
import {observer} from "mobx-react-lite";

const getActionDialogStyles = makeStyles((theme: Theme) => ({
    listItemText: {
        color: grey[600],
    },
    navlink: {
        textDecoration: 'none'
    }
}));

const LightBlueTextField = withStyles({
    root: {
        '& label.Mui-focused': {
            color: lightBlue[600],
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: lightBlue[600],
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: lightBlue[600],
                color: lightBlue[600]
            },
            '&:hover fieldset': {
                borderColor: lightBlue[600],
            },
            '&.Mui-focused fieldset': {
                borderColor: lightBlue[600],
            },
        },
    },
})(TextField);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            // maxWidth: 360,
            backgroundColor: theme.palette.background.paper,
            position: 'relative',
            overflow: 'auto',
            height: 360,
        },
        listSection: {
            backgroundColor: 'inherit',
        },
        ul: {
            backgroundColor: 'inherit',
            padding: 0,
        },
    }),
);


interface ListSubHeaderProps {
    subheader: string
}

const ListSubHeaderComponent = (props: ListSubHeaderProps) => {
    return (
        <ListSubheader component="div" id="nested-list-subheader">
            {props.subheader}
        </ListSubheader>
    )
}

const ListItemComponent = observer((props: { item: MenuItem }) => {
    const classes = getActionDialogStyles();
    const rootStore = useStores();

    const key = props.item.id || props.item.name;
    const clickHandler = (event: any) => {
        rootStore.menu.hide()

        // If there is an action, assume that we don't follow a link... So just call the action method
        // and cancel the default handling. Doing the preventDefault() method outside the if statement
        // will prevent *any* link from being followed.
        if (props.item.action) {
            props.item.action(rootStore)
            event.preventDefault()
        }
    }

    return (
        <NavLink to={props.item.path} className={classes.navlink} onClick={clickHandler}>
            <ListItem button key={key}>
                <ListItemIcon>{props.item.style.colourIcon}</ListItemIcon>
                <ListItemText primary={props.item.name} className={classes.listItemText}/>
            </ListItem>
        </NavLink>
    )
})

const NoMatch = () => {
    return (
        <ListSubHeaderComponent subheader={'No Matches'}/>
    )
}

const RenderItems = observer(() => {
    const classes = useStyles();
    const {menu} = useStores();
    const entries = Array.from(menu.menuItems())
    return (
        <List className={classes.root} subheader={<li/>}>
            {entries.length > 0 ? entries.map(([key, items]) => (
                <li key={`section-${key}`} className={classes.listSection}>
                    <ul className={classes.ul}>
                        <ListSubheader>{key}</ListSubheader>
                        {items.map(item => (
                            <ListItemComponent item={item}/>
                        ))}
                    </ul>
                </li>
            )) : (<NoMatch/>)}
        </List>
    )
})

export const MenuDialog = observer(() => {
    const {menu} = useStores();

    const search = (event: any) => {
        menu.filterMenu(event.target.value || '')
    }

    return (
        <Dialog open={menu.open} onClose={menu.hide} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">
                <LightBlueTextField
                    id="keyword"
                    size="small"
                    style={{width: '100%'}}
                    defaultValue={menu.query}
                    variant="outlined"
                    onChange={search}
                    autoFocus
                />
            </DialogTitle>
            <DialogContent>
                <FormControl style={
                    {
                        width: 310,
                        height: '100%',
                        overflow: 'hidden',
                        marginTop: -18,
                    }}
                >

                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <List component="nav">
                                <RenderItems/>
                                <Divider/>
                                <ListItem>
                                    <Typography variant="caption" display="block" gutterBottom
                                                style={{color: grey[500]}}>
                                        Pressing period <code>(.)</code> opens this dialog box
                                    </Typography>
                                </ListItem>
                            </List>
                        </Grid>
                    </Grid>
                </FormControl>
            </DialogContent>
        </Dialog>
    )
})
