import {observer} from "mobx-react-lite";
import {useStores} from "../contexts";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    ListItemText,
    MenuItem,
    Select,
    TextField
} from "@material-ui/core";
import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => ({
    formControl: {
        marginBottom: theme.spacing(1),
        minWidth: 120,
        width: '100%',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}))

export const ResolveTaskDialog = observer(() => {
    const classes = useStyles()
    const {task} = useStores()
    const [resolution, setResolution] = useState('')
    const [comments, setComments] = useState('')

    const resolve = () => {
        const val = task.resolutions.find(value => value.name === resolution)
        if (!val)
            return

        task.resolve(val, comments, task.hideResolve)
    }

    return (
        <Dialog onClose={() => task.hideResolve()}
                open={task.resolveVisible}
                fullWidth={true}
                maxWidth={'sm'}
        >
            <DialogTitle id="resolve-dialog-title">Resolve Task</DialogTitle>
            <DialogContent>
                <FormControl className={classes.formControl}>
                    <InputLabel id="resolution-label">Resolution</InputLabel>
                    <Select
                        labelId="resolution-label"
                        id="resolution-select"
                        value={resolution}
                        onChange={(event: any) => setResolution(event.target.value)}
                    >
                        <MenuItem value={''}>Choose One</MenuItem>
                        {task.resolutions.map(resolution => (
                            <MenuItem value={resolution.name}>
                                <ListItemText primary={resolution.name} secondary={resolution.description}/>
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <TextField
                    id="comments"
                    label="Comments"
                    variant="filled"
                    fullWidth
                    multiline
                    rows={8}
                    value={comments}
                    onChange={event => setComments(event.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button variant={"text"}
                        onClick={task.hideResolve}>Cancel</Button>

                <Button variant={"contained"} color={"primary"}
                        disabled={resolution === ''}
                        onClick={resolve}
                >Resolve</Button>
            </DialogActions>
        </Dialog>
    )
})

export const RescheduleTaskDialog = observer(() => {
    const classes = useStyles()
    const {task} = useStores()
    const [period, setPeriod] = useState<string>('')
    const [comments, setComments] = useState('')

    return (
        <Dialog onClose={task.hideReschedule}
                open={task.rescheduleVisible}
                fullWidth={true}
                maxWidth={'sm'}
        >
            <DialogTitle id="reschedule-dialog-title">Reschedule Task</DialogTitle>
            <DialogContent>
                <FormControl className={classes.formControl}>
                    <InputLabel id="reschedule-label">Reschedule For</InputLabel>
                    <Select
                        labelId="reschedule-label"
                        id="reschedule-select"
                        value={period}
                        onChange={(event: any) => setPeriod(event.target.value)}
                    >
                        {/*<MenuItem value="P1H">1 Hour</MenuItem>*/}
                        {/*<MenuItem value="P4H">4 Hours</MenuItem>*/}
                        <MenuItem value="">Select One</MenuItem>
                        <MenuItem value="P1D">1 Day</MenuItem>
                        <MenuItem value="P1W">1 Week</MenuItem>
                        <MenuItem value="P2W">2 Weeks</MenuItem>
                        <MenuItem value="P1M">1 Month</MenuItem>
                    </Select>
                </FormControl>

                <TextField
                    id="comments"
                    label="Comments"
                    variant="filled"
                    fullWidth
                    multiline
                    rows={8}
                    value={comments}
                    onChange={event => setComments(event.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button variant={"text"}
                        onClick={task.hideReschedule}>Cancel</Button>

                <Button variant={"contained"} color={"primary"}
                        disabled={period === ''}
                        onClick={() => period && task.reschedule(period, undefined, comments, task.hideReschedule)}
                >Reschedule</Button>
            </DialogActions>
        </Dialog>
    )
})
