import React from "react";
import loading from "../assets/loading.svg";
import './Loading.css';

const Loading = () => (
    <div className="Loading">
        <header className="Loading-header">
            <img src={loading} className="Loading-logo" alt="logo"/>
        </header>
    </div>
)

export default Loading;
