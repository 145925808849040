import React, {Fragment} from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import {Alert, Autocomplete} from "@material-ui/lab";
import {Box, DialogTitle, TextField} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {useStores} from "../contexts";
import {observer} from "mobx-react-lite";
import {Chip, Org} from "idpet-api";
import {EditableName, styles,} from "../components/editable";
import {columnStyles} from "../styles/EntityLayoutStyles";
import {DatumView} from "../components/DatumView";
import {NameView} from "../components/NameView";
import {useHistory} from "react-router";
import {SearchField} from "../components/Search";
import {newError} from "../utils/builder";

const OrgSearch = observer(() => {
    const classes = styles();
    const {create} = useStores().pet.recovery;

    function queryChange(event: any) {
        create.setOrgQuery(event.target.value);
    }

    return (
        <Autocomplete
            id="organization"
            options={create.orgSearch.paged?.values || []}
            getOptionLabel={(option) => option.name}
            onInputChange={queryChange}
            className={classes.item}
            onChange={(event, value) => create.setOrg(value as Org)}
            filterOptions={(options, {inputValue}) => options}
            renderInput={(params) =>
                <TextField
                    {...params}
                    variant={"filled"}
                    placeholder={"Organization"}
                />}
        />
    )
})

const SummaryView = observer((props: { chip: Chip }) => {
    const pet = props.chip.pet
    return pet ? (
        <Fragment>
            <DatumView title={"Chip"} children={props.chip.serialNumber}/>
            <DatumView title={"Name"} children={pet.name}/>
            <DatumView title={"Breed"} children={pet.breed}/>
            <DatumView title={"Description"} children={pet.description}/>
            <DatumView title={"Species"} children={pet.species.commonName}/>
            <DatumView title={"Sex"} children={pet.sex.commonName}/>
        </Fragment>
    ) : (
        <Alert variant="outlined" severity="warning">
            No pet details have been registered, however, there may be information linked to the chip. To view,
            click the "Recover" button.
        </Alert>
    )
})

export const PetRecoveryDialog = observer(() => {
    const classes = columnStyles()
    const {create} = useStores().pet.recovery;
    const {config} = useStores()
    const {push} = useHistory();

    const recover = () => {
        // Store the chip, as the save clears out the store
        const chip = create.chip
        if (!chip)
            throw newError('Should not get here')

        create.save(value => push(`/recoveries/${value.id}`))
    }

    return (
        <Dialog
            onClose={create.hide}
            aria-labelledby="dialog-title"
            open={create.visible}
            fullWidth={true}
            maxWidth={'md'}
            disableBackdropClick
        >
            <DialogTitle>Pet Recovery</DialogTitle>
            <DialogContent>
                <Box className={classes.container}>
                    <Box className={classes.column}>
                        <SearchField support={create.chipSearch.searchSupport()} elevated={false}
                                     placeholder={"Search for chip"} autoFocus/>

                        <EditableName title={"Recoverer"} context={create} field={"caller"}/>
                        {config.isFieldVisible("recovery_org") && (<OrgSearch/>)}
                    </Box>

                    <Box className={classes.column}>
                        <NameView name={"Summary Detail"}/>
                        {create.chip && (<SummaryView chip={create.chip}/>)}
                    </Box>
                </Box>

                <DialogActions>
                    <Button variant="text" onClick={create.hide}>Cancel</Button>
                    <Button variant="contained" color="primary" disabled={create.chip === undefined || create.status === "BUSY"}
                            onClick={recover}>Recover</Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    )
})

