import React from 'react';

import PetsIcon from '@material-ui/icons/Pets';
import FaceIcon from '@material-ui/icons/Face';
import StoreIcon from '@material-ui/icons/Store';
import MemoryIcon from '@material-ui/icons/Memory';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import FireplaceIcon from '@material-ui/icons/Fireplace';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import DirectionsBoatIcon from '@material-ui/icons/DirectionsBoat';
import {HeartBroken} from "mdi-material-ui";
import AnnouncementIcon from '@material-ui/icons/Announcement';

import {amber, deepOrange, indigo, lightBlue, lime, teal} from '@material-ui/core/colors';
import {CommonProps} from "@material-ui/core/OverridableComponent";
import {SvgIconTypeMap} from "@material-ui/core/SvgIcon/SvgIcon";

const petColour = lightBlue[600]
const personColour = lime[400]
const organisationColour = deepOrange[400]
const orderColour = amber[500]
const chipColour = teal[400]
const recoveryColour = amber[400]
const cremationColour = amber[400]

// TODO someone with an eye should fix these later ones... :)
const dispatchColour = indigo[400]
const shipmentColour = indigo[400]
const sosColour = amber[400]

export interface EntityStyle {
    color: string;
    whiteIcon: React.ReactElement & CommonProps<SvgIconTypeMap>;
    colourIcon: React.ReactElement & CommonProps<SvgIconTypeMap>;
}

// I'm sure theres a better way, but for the moment...
function createStyle(colour: string, iconFactory: ((colour: string) => React.ReactElement)): EntityStyle {
    return {
        color: colour,
        whiteIcon: iconFactory('white'),
        colourIcon: iconFactory(colour),
    }
}

const styleMap = {
    'Pet': createStyle(petColour, c => <PetsIcon style={{color: c}}/>),
    'Person': createStyle(personColour, c => <FaceIcon style={{color: c}}/>),
    'Organisation': createStyle(organisationColour, c => <StoreIcon style={{color: c}}/>),
    'Order': createStyle(orderColour, c => <ShoppingCartIcon style={{color: c}}/>),
    'Chip': createStyle(chipColour, c => <MemoryIcon style={{color: c}}/>),
    'Recovery': createStyle(recoveryColour, c => <HeartBroken style={{color: c}}/>),
    'Dispatch': createStyle(dispatchColour, c => <LocalShippingIcon style={{color: c}}/>),
    'Cremation': createStyle(cremationColour, c => <FireplaceIcon style={{color: c}}/>),
    'Shipment': createStyle(shipmentColour, c => <DirectionsBoatIcon style={{color: c}}/>),
    'Sos': createStyle(sosColour, c => <AnnouncementIcon style={{color: c}}/>),
}

export default styleMap;
