import {RemoteSearchStore, RequestParams} from "./RemoteStore";
import {AmlaRegistryResults, Api, PagedAmlaRegistryResults} from "idpet-api"
import {RootStore} from "./RootStore";

export class AmlaSearchStore extends RemoteSearchStore<Api, RootStore, PagedAmlaRegistryResults, AmlaRegistryResults> {
    protected _search(api: Api, data: any, params: RequestParams): Promise<PagedAmlaRegistryResults> {
        return api.amla.queryAmla({...data, expandos: this.expandos('')}, params);
    }

    protected _values(): AmlaRegistryResults[] | undefined {
        return this.paged?.values;
    }
}
