import {observer} from "mobx-react-lite";
import {Autocomplete} from "@material-ui/lab";
import {TextField} from "@material-ui/core";
import React from "react";
import {EditableDropdownProps, styles, title} from "./index";
import {Entity} from "idpet-api";
import {DatumView} from "../DatumView";

export const Dropdown = observer(<T extends Entity>(props: EditableDropdownProps<T, any>) => {
    const classes = styles()
    const value = props.context.getValue(props.field)
    const typeItem = value || props.valueSupplier.defaultValue() || props.valueSupplier.values()[0]
    const theTitle = title(props);

    return (
        <div>
            {props.context.editing ? (
                <Autocomplete
                    id={theTitle}
                    className={classes.item}
                    options={props.valueSupplier.values()}
                    autoHighlight
                    disableClearable={true}
                    getOptionLabel={(option) => props.valueSupplier.label(option)}
                    getOptionSelected={props.valueSupplier.equal}
                    onChange={(event, value) => {
                        props.context.setValue(props.field, value)
                    }}
                    value={typeItem}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={theTitle}
                            variant={"filled"}
                            required={props.required}
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password', // disable autocomplete and autofill
                            }}
                        />
                    )}
                />
            ) : (
                <DatumView title={title(props)} children={typeItem ? props.valueSupplier.label(typeItem) : '-'}/>
            )}
        </div>
    )
})
