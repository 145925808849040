import React, {Fragment, ReactElement} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {useStores} from "../contexts";
import {observer} from "mobx-react-lite";
import {Alert, AlertStore, Prompt} from "../stores/AlertStores";
import {TextField, Typography} from "@material-ui/core";
import {styles} from "../components/editable";
import {asArray} from "../utils";
import {Autocomplete} from "@material-ui/lab";

export interface PromptWidgetProps {
    store: AlertStore
}

const TextPromptWidget = observer((props: PromptWidgetProps) => {
    const classes = styles()
    return (
        <TextField label={props.store.alert?.prompt?.label}
                   variant="filled"
                   value={props.store.prompt}
                   required
                   onChange={event => props.store.setPrompt(event.target.value)}
                   className={classes.item}
                   autoFocus/>
    )
})

interface SelectPromptWidgetProps<T> extends PromptWidgetProps {
    items: T[]
    id: (val: T) => string
    label: (val: T) => string
}

const SelectPromptWidget = observer(<T extends {}>(props: SelectPromptWidgetProps<T>) => {
    const classes = styles()
    const compare = (option: T, value: T): boolean => {
        return props.id(option) === props.id(value)
    }
    return (
        <Autocomplete
            className={classes.item}
            options={props.items}
            autoHighlight
            disableClearable={true}
            getOptionLabel={(option) => (option && props.label(option)) || '-'}
            getOptionSelected={compare}
            onChange={(event, value: T) => {
                props.store.setPrompt(value)
            }}
            value={props.store.prompt}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant={"filled"}
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                />
            )}
        />
    )
})

export const selectPromptWidget = <T extends {}>
(items: T[], id: (val: T) => string, label: (val: T) => string): (store: AlertStore) => ReactElement<PromptWidgetProps> => {
    return (store) => (
        <SelectPromptWidget store={store} id={id} label={label} items={items}/>
    )
}

const Content = observer(() => {
    const store = useStores().alert
    const alert = store.alert
    if (!alert)
        return (<Fragment/>)

    if (alert.prompt && !alert.prompt.widget) {
        alert.prompt.widget = (store) => (
            <TextPromptWidget store={store}/>
        )
    }

    return (
        <Fragment>
            <DialogContentText id="alert-dialog-description">
                {asArray(alert.content).map(item => (
                    <Typography variant={"body1"} gutterBottom>{item}</Typography>
                ))}
            </DialogContentText>

            {alert.prompt && alert.prompt.widget?.(store)}
        </Fragment>
    )
})

const Buttons = observer(() => {
    const {alert} = useStores()
    return (
        <Fragment>
            {alert.alert?.buttons.map(button => (
                <Button onClick={() => button.action(alert)}
                        color={button.color}
                        autoFocus={button.autoFocus}
                        variant={button.variant}>
                    {button.title}
                </Button>
            ))}
        </Fragment>
    )
})

export const AlertDialog = observer(() => {
    const {alert} = useStores()
    return (
        <Dialog
            open={alert.open}
            onClose={alert.hide}
            maxWidth={"sm"}
            fullWidth
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            {alert.alert?.title && (
                <DialogTitle id="alert-dialog-title">{alert.alert?.title}</DialogTitle>
            )}
            <DialogContent>
                <Content/>
            </DialogContent>
            <DialogActions>
                <Buttons/>
            </DialogActions>
        </Dialog>
    )
})

export const messageAlert = (content: string | string[], title?: string): Alert => {
    return {
        title,
        content,
        buttons: [
            {
                title: "Ok",
                color: "primary",
                variant: "contained",
                autoFocus: true,
                action: store => store.hide(),
            },
        ]
    }
}

export const reloadAlert = (content: string | string[], title?: string): Alert => {
    return {
        title,
        content,
        buttons: [
            {
                title: "Ok",
                color: "primary",
                variant: "contained",
                autoFocus: true,
                action: store => window.location.reload(),
            },
        ]
    }
}

export type AlertYesNo = "yes" | "no"
export const yesNoAlert = (content: string | string[], callback: ((result: AlertYesNo) => void), title?: string): Alert => {
    return {
        title,
        content,
        buttons: [
            {
                title: "No",
                variant: "text",
                action: store => {
                    store.hide()
                    callback("no")
                }
            },
            {
                title: "Yes",
                color: "primary",
                variant: "contained",
                action: store => {
                    store.hide()
                    callback("yes")
                }
            },
        ]
    }
}

export type AlertOkCancel = "ok" | "cancel"
export const promptAlert = <T extends {}>(content: string | string[], prompt: Prompt<T>, callback: ((result: AlertOkCancel, value?: T) => void), title?: string): Alert => {
    return {
        title,
        content,
        prompt,
        buttons: [
            {
                title: "Cancel",
                variant: "text",
                action: store => {
                    store.hide()
                    callback("cancel")
                }
            },
            {
                title: "Ok",
                color: "primary",
                variant: "contained",
                action: store => {
                    store.hide()
                    callback("ok", store.prompt)
                }
            },
        ]
    }
}
