import {AbstractStore} from "./RemoteStore";
import {Api, Entity} from "idpet-api";
import {RootStore} from "./RootStore";
import {action, computed, observable, toJS} from "mobx";
import {OrgSearchStore} from "./OrgStores";
import {SearchSupport} from "../components/Search";

interface QuickSearch {
    type: SearchType
    setQuery: (val: string) => void
    values: () => Entity[]
    support: () => SearchSupport
}

export type SearchType = "Org"

class OrgQuickSearch implements QuickSearch {
    type: SearchType = "Org"
    searchStore: OrgSearchStore

    constructor(rootStore: RootStore) {
        this.searchStore = new OrgSearchStore(rootStore, true)
    }

    setQuery(val: string) {
        this.searchStore.setQuery(val)
    }

    values() {
        return this.searchStore.values() || []
    }

    support(): SearchSupport {
        return this.searchStore.searchSupport()
    }
}

export class QuickSearchStore extends AbstractStore<Api, RootStore> {
    @observable private _search?: QuickSearch

    private callback?: (selected: Entity) => void
    private searchers = {
        "Org": new OrgQuickSearch(this.rootStore)
    }

    @computed get searchType() {
        return this._search?.type
    }

    @action setSearchType(type: SearchType, callback: (selected: Entity) => void) {
        this.callback = callback
        this._search = this.searchers[type]
    }

    @action select = (selected: Entity) => {
        this.callback && this.callback(toJS(selected))
        this._search = undefined
    }

    @action cancel = () => {
        this._search = undefined
    }

    @computed get values() {
        return this._search?.values() || []
    }

    searchSupport = () => {
        return this._search?.support()
    }
}
