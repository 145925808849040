import React, {Fragment} from "react";
import {observer} from "mobx-react-lite";
import {columnStyles} from "../../styles/EntityLayoutStyles";
import {TitleBox} from "../MasterDetail";
import {EditableDate, EditableDropdown, EditableName, EditableText, valueSupplierForType} from "../editable";
import {Box} from "@material-ui/core";
import {DatumView} from "../DatumView";
import {formatAsDate, getLifetime} from "../../utils";
import {PetEditContext} from "../../utils/editContexts";
import {ButtonLink} from "../ButtonLink";
import {PetCreateStore, PetStore} from "../../stores/PetStores";

export const Pet = observer((props: { context: PetEditContext | PetStore | PetCreateStore, editOnly?: boolean }) => {
    const classes = columnStyles()
    const pet = props.context.item
    const ctxChip = props.context.ctxChip()
    const chipNo = pet?.chips?.[0].serialNumber
    const chipid = pet?.chips?.[0].id
    const implantedBy = pet?.chips?.[0].implantedBy

    return (
        <Fragment>
            <TitleBox
                children={<EditableName title={"Name"} context={props.context} field={"name"} autoFocus={true}
                                        required/>}
                minCols={3}/>

            <Box className={classes.container}>
                <Box className={classes.column}>
                    <EditableText context={props.context} field={"kennelName"}/>
                    <EditableText context={props.context} field={"description"} required/>
                    <DatumView title={'Chip Id'} children={chipNo}
                               link={<ButtonLink to={`/chips/${chipid}`} color={'primary'} variant={"text"}
                                                 className={classes.linkAdjust}>View</ButtonLink>}/>
                    {implantedBy && (
                        <DatumView title={"Implanted By"} children={implantedBy}/>
                    )}
                </Box>
                <Box className={classes.column}>
                    <EditableText context={props.context} field={"breed"}/>
                    <EditableText context={props.context} field={"breedSociety"}/>
                    {!props.editOnly && (
                        <DatumView title={'Lifetime?'} children={getLifetime(pet)?.targetYear || '-'}/>
                    )}
                </Box>
                <Box className={classes.column}>
                    <EditableDate context={props.context} field={"birthDate"} title={"Born"}/>
                    <EditableDate context={ctxChip} field={"implantDate"} title={'Implanted'}/>
                    {!props.editOnly && (
                        <Fragment>
                            <DatumView title={'Captured'} children={formatAsDate(pet?.createdAt)}/>
                            <DatumView title={'Died'} children={formatAsDate(pet?.deceasedDate)}/>
                        </Fragment>
                    )}
                </Box>
                <Box className={classes.column}>
                    <EditableDropdown context={props.context} field={"species"}
                                      valueSupplier={valueSupplierForType("Species")} required/>
                    <EditableDropdown context={props.context} field={"sex"}
                                      valueSupplier={valueSupplierForType("Sex")} required/>
                    <EditableDropdown context={props.context} field={"sterilized"}
                                      valueSupplier={valueSupplierForType("Sterilized")} required/>
                </Box>
            </Box>
        </Fragment>
    )
})
