import {Entity} from "idpet-api";
import {ActionItem, CreateSpeedDialProps, EditSpeedDialProps, styles,} from "./index";
import {observer} from "mobx-react-lite";
import React, {Fragment} from "react";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import EditIcon from "@material-ui/icons/Edit";
import {RemoteCreateStore, RemoteItemStore} from "../../stores/RemoteStore";
import SaveIcon from "@material-ui/icons/Save";
import ClearIcon from "@material-ui/icons/Clear";
import {SpeedDial} from "@material-ui/lab";
import {asArray} from "../../utils";

const viewActions = <T extends Entity>(store: RemoteItemStore<any, any, T>): ActionItem[] => {
    return store.item?._editState?.editable ? [
        {icon: <EditIcon/>, name: 'Edit', action: () => store.edit()}
    ] : []
}

const editActions = <T extends Entity>(store: RemoteItemStore<any, any, T>): ActionItem[] => {
    return [
        {icon: <SaveIcon/>, name: 'Save', action: () => store.save(), enabled: () => store.isEntityValid},
        {icon: <ClearIcon/>, name: 'Cancel', action: () => store.cancel(), danger: true},
    ]
}

const createActions = <T extends Entity>(store: RemoteCreateStore<any, any, T>): ActionItem[] => {
    return [
        {icon: <SaveIcon/>, name: 'Save', action: () => store.save(), enabled: () => store.isEntityValid},
        {icon: <ClearIcon/>, name: 'Reset', action: () => store.reset(), danger: true},
    ]
}

const SpeedDialBase = observer((props: { items: ActionItem[] }) => {
    const classes = styles();
    const [open, setOpen] = React.useState(false);

    const actionItems = asArray(props.items)
        .filter(item => item.visible === undefined || item.visible());

    if (actionItems.length === 0)
        return (<Fragment/>)

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleActionClick = (action: ActionItem) => {
        action.action && action.action()
        setOpen(false)
    }

    return (
        <SpeedDial
            ariaLabel="SpeedDial"
            className={classes.speedDial}
            icon={<SpeedDialIcon/>}
            onClose={handleClose}
            onOpen={handleOpen}
            open={open}
            direction={"down"}
        >
            {actionItems.map((item) => (
                <SpeedDialAction
                    title={item.name}
                    key={item.name}
                    icon={item.icon}
                    onClick={() => handleActionClick(item)}
                    className={(item.danger && classes.speedDialDanger) || ''}
                />
            ))}
        </SpeedDial>
    )
})

export const EditSpeedDial = observer(<T extends Entity>(props: EditSpeedDialProps<T>) => {
    const menuActions = props.store.editing ? editActions<T>(props.store) : [
        ...(props.showDefault === undefined || props.showDefault ? viewActions(props.store) : []),
        ...props.items || []
    ]

    return (
        <SpeedDialBase items={menuActions}/>
    )
})

export const CreateSpeedDial = observer(<T extends Entity>(props: CreateSpeedDialProps<T>) => {
    return (
        <SpeedDialBase items={[
            ...(props.showDefault === undefined || props.showDefault ? createActions(props.store) : []),
            ...props.items || []
        ]}/>
    )
})
