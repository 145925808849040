import {RemoteStore} from "./RemoteStore";
import {
    Api,
    CustomReportInput,
    CustomReportParameterType,
    CustomReportType,
    CustomReportTypes,
    QueuedCustomReport
} from "idpet-api";
import {RootStore} from "./RootStore";
import {Observable} from "rxjs";
import {tap} from "rxjs/operators";
import {action, computed, observable} from "mobx";

export class CustomReportStore extends RemoteStore<Api, RootStore> {
    @observable reportTypes: CustomReportType[] = [];
    @observable current: CustomReportType = {id: '', name: '-- Select One --', category: '', parameters: []};
    @observable input?: CustomReportInput;

    protected _initialize(): Observable<any> {
        return this.invokeApi((api, params) =>
            api.reports.getCustomReportTypes({}, params))
            .pipe(tap(result => this.setCustomReportTypes(result)));
    }

    @action private setCustomReportTypes = (types: CustomReportTypes) => {
        if (types && types.reports) {
            this.reportTypes = types.reports
        }
    }

    @computed get readyForGeneration() {
        const anyEmpty = (): boolean => {
            if (!this.input || !this.input.parameters) {
                return true
            }

            for (const param of this.input.parameters) {
                if (param[1].length === 0) {
                    return true
                }
            }

            return false
        }

        if (this.current.id === '')
            return false

        if (!this.current.parameters || this.current.parameters.length === 0)
            return true

        return !anyEmpty()
    }

    triggerReport = (callback: (result: QueuedCustomReport) => void) => {
        if (!this.input) {
            return
        }

        const input = this.input
         this.invokeOne((api, params) =>
             api.reports.requestCustomReport(input, {}, params),
                 val => callback(val))
    }

    @action selectReport = (value: CustomReportType) => {
        this.current = value

        const params = new Map<string, string>()
        value.parameters?.forEach(val => {
            params.set(val.key, '')
        })

        this.input = {
            id: value.id,
            name: value.name,
            parameters: params
        }
    }

    value(parameter: CustomReportParameterType): string {
        const params: Map<string, string> = this.input?.parameters
        return (params && params.get(parameter.key)) || ''
    }

    @action setValue = (parameter: CustomReportParameterType, value: string) => {
        const params: Map<string, string> = this.input?.parameters
        if (params) {
            params.set(parameter.key, value)
        }
    }
}
