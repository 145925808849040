import {RemoteItemStore, RequestParams} from "./RemoteStore";
import {action, computed, observable, reaction} from "mobx";
import {PersonSearchStore} from "./PersonStores";
import {RootStore} from "./RootStore";
import {Api, Person, Pet} from "idpet-api";
import {PersonEditContext} from "../utils/editContexts";
import {transferPet} from "../utils/actions";
import {newError, newPerson, TypeType} from "../utils/builder";
import {isPersonValid} from "../utils";

export class PetTransferStore extends RemoteItemStore<Api, RootStore, Pet> {
    @observable personSearch: PersonSearchStore
    @observable newOwner?: Person
    @observable newPerson: boolean = false
    @observable emailRequired: boolean = true

    constructor(rootStore: RootStore) {
        super(rootStore);
        this.personSearch = new PersonSearchStore(rootStore, false, false)

        reaction(
            () => this.personSearch.status,
            (value) => value === "BUSY" && this.clearOwner()
        )
    }

    protected _find(api: Api, id: any, params: RequestParams): Promise<Pet> {
        this.newOwner = undefined
        this.newPerson = false
        this.personSearch.clear()

        return api.pets.getPetById(id, {expandos: 'chips'}, params)
    }

    setNewOwner = (newOwner: Person) => {
        const ownerId = newOwner.id
        if (!ownerId)
            throw newError('Should not get here')

        // We don't have enough information from the search alone, so let's get a little more information...
        this.invokeOne(((api, params) => api.people.getPersonById(ownerId, {expandos: 'contacts,addresses'}, params)),
            person => this.setOwner(person))
    }

    @action private setOwner = (owner: Person) => {
        this.newOwner = owner
    }

    @action private clearOwner = () => {
        this.newOwner = undefined
        this.newPerson = false
    }

    @action setUseNewPerson = (val: boolean) => {
        this.newPerson = val
        if (val) {
            this.newOwner = newPerson(this.def("Title"), '', '')
        } else {
            this.newOwner = undefined
        }
    }

    @computed get newPersonValid() {
        return !this.newPerson || isPersonValid(this.newOwner, this.emailRequired)
    }

    private def = (type: TypeType) => this.rootStore.types.defaultValue(type)

    @action transfer = (onTransfer?: (pet: Pet) => void) => {
        if (!this.newOwner || !this.item)
            throw newError('Should not get here')

        const action = transferPet(this.newOwner)
        const pet = this.item
        this.invokeOne(((api, params) =>
                api.pets.initiatePetAction(this.idOrThrow, action, undefined, params)),
            () => onTransfer && onTransfer(pet))
    }

    @action toggleEmailRequired = () => {
        this.emailRequired = !this.emailRequired
    }

    ctxOwner(): PersonEditContext {
        return this.nestedContext(new PersonEditContext('person', {
            item: () => this.newOwner,
            editing: () => this.newPerson
        }))
    }
}
