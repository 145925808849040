import {observer} from "mobx-react-lite";
import {Checkbox, FormControlLabel, InputAdornment, TextField} from "@material-ui/core";
import React, {Fragment, useState} from "react";
import {isString} from "../../utils";
import {Entity} from "idpet-api";
import {EditableFieldProps, styles, title} from "./index";
import {DatumView} from "../DatumView";
import * as EmailValidator from 'email-validator';
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import {useStores} from "../../contexts";

export const Email = observer(<T extends Entity>(props: EditableFieldProps<T>) => {
    const {config} = useStores()
    const classes = styles();
    const value = props.context.getValue(props.field)

    const [error, setError] = useState<string | undefined>()
    const checkValue = (event: any) => {
        const value = event.target.value;
        if (value.trim().length === 0)
            return

        if (!EmailValidator.validate(value)) {
            setError('Invalid email address')
        } else {
            setError(undefined)
        }
    }

    const clearValue = (event: any) => {
        props.context.setValue(props.field, '')
        setError(undefined)
    }

    const Helper = () => {
        return error ? (
            <div>{error}</div>
        ) : (
            config.admin || config.partner ? (
                <FormControlLabel control={
                    <Checkbox checked={!props.required}
                              onChange={() => props.toggleRequired && props.toggleRequired()}/>
                } label={"Allow empty email?"}/>
            ) : (<Fragment/>)
        )
    };

    return (
        <div>
            {props.context.editing ? (
                <TextField label={title(props)}
                           variant="filled"
                           required={!!props.required}
                           value={value}
                           onChange={event => props.context.setValue(props.field, event.target.value)}
                           onBlur={checkValue}
                           className={classes.item}
                           autoFocus={props.autoFocus}
                           InputProps={{
                               endAdornment:
                                   <InputAdornment position="end">
                                       <IconButton onClick={clearValue}>
                                           <ClearIcon/>
                                       </IconButton>
                                   </InputAdornment>
                           }}
                           inputProps={{
                               name: 'email',
                           }}
                           error={error !== undefined}
                           helperText={<Helper/>}
                />
            ) : (
                <DatumView title={title(props)} children={value && isString(value) ? value : '-'}/>
            )}
        </div>
    )
})
