import {observer} from "mobx-react-lite";
import React, {Fragment} from "react";
import {TextField} from "@material-ui/core";
import {NameView} from "../NameView";
import {isString} from "../../utils";
import {Entity} from "idpet-api";
import {EditableFieldProps, styles, title} from "./index";

export const Name = observer(<T extends Entity>(props: EditableFieldProps<T>) => {
    const classes = styles();
    const value = props.context.getValue(props.field)
    return (
        <Fragment>
            {props.context.editing ? (
                <TextField label={title(props)} variant="filled" value={value}
                           required={props.required}
                           onChange={event => props.context.setValue(props.field, event.target.value)}
                           className={classes.item}
                           autoFocus={props.autoFocus}/>
            ) : (
                <NameView name={isString(value) ? value : '-'}/>
            )}
        </Fragment>
    )
})
