import {
    Address,
    Chip,
    Contact,
    Cremated,
    Cremation,
    Customer,
    Entity,
    Error,
    Good,
    GoodsReturn,
    GoodsReturnItem,
    InventoryItem,
    Note,
    Order,
    OrderItem,
    Org,
    PagedArray,
    Party,
    PartyRole,
    Person,
    Pet,
    PetRelatedParty,
    PetRelatedPartyType,
    PetSos,
    Product,
    Recovery,
    Region, SerializedInventoryItem,
    Sex,
    Shipment,
    ShipmentItem,
    Species,
    Sterilized,
    Title,
    Type
} from "idpet-api";
import {AddressType, asArray, ContactType, dateUtils, PartyRoleType, serialNumber} from "./index";

export type EntityType =
    "Address" |
    "Chip" |
    "Contact" |
    "Cremated" |
    "Cremation" |
    "Customer" |
    "Good" |
    "GoodsReturn" |
    "GoodsReturnItem" |
    "Image" |
    "InventoryItem" |
    "MobileDevice" |
    "MobileProfile" |
    "Note" |
    "Order" |
    "OrderItem" |
    "Org" |
    "Party" |
    "PartyRole" |
    "Person" |
    "Pet" |
    "PetInteraction" |
    "PetSos" |
    "Product" |
    "Recovery" |
    "Region" |
    "SerializedInventoryItem" |
    "Service" |
    "Shipment" |
    "ShipmentItem"
    ;

export type TypeType =
    "ImageType" |
    "Language" |
    "PartyAddressType" |
    "PartyContactType" |
    "PartyRelType" |
    "PartyRoleType" |
    "PartyType" |
    "Sex" |
    "Species" |
    "Sterilized" |
    "Title" |
    "TypeModel"
    ;

const newEntity = (type: EntityType): Entity => {
    return {
        _objectType: type,
    }
}

const newType = (id: string, name?: string, type?: TypeType): Type => {
    return {
        _objectType: type || "TypeModel",
        id: id,
        name: name || '--',
        commonName: name || '--',
        sortOrder: 99
    }
}

declare type KeyedPartyType = Record<string, Type & { entityType: EntityType }>;
const partyTypes: KeyedPartyType = {
    "Person": {...newType("1", "Person", "TypeModel"), entityType: "Person"},
    "Org": {...newType("2", "Org", "TypeModel"), entityType: "Org"},
    // "Animal": {...newType("3", "Animal", "TypeModel"), entityType: "Animal"},
}

declare type KeyedPartyRoleType = Record<string, Type & { entityType: EntityType }>;
const partyRoleTypes: KeyedPartyRoleType = {
    "Pet": {...newType("1", "PET", "TypeModel"), entityType: "Pet"},
    "Owner": {...newType("2", "OWNER", "TypeModel"), entityType: "PartyRole"},
    "PetRescuer": {...newType("3", "PET_RESCUER", "TypeModel"), entityType: "PartyRole"},
    "Breeder": {...newType("4", "BREEDER", "TypeModel"), entityType: "PartyRole"},
    "Customer": {...newType("5", "CUSTOMER", "TypeModel"), entityType: "Customer"},
    "VetClinic": {...newType("6", "VET_CLINIC", "TypeModel"), entityType: "PartyRole"},
    "Welfare": {...newType("7", "WELFARE", "TypeModel"), entityType: "PartyRole"},
}

declare type KeyedAddressType = Record<AddressType, Type & { entityType: EntityType }>;
const addressTypes: KeyedAddressType = {
    "PHYSICAL": {...newType("1", "PHYSICAL"), entityType: "Address"},
    "POSTAL": {...newType("2", "POSTAL"), entityType: "Address"},
    "ALERT": {...newType("3", "ALERT"), entityType: "Address"},
}

declare type KeyedContactType = Record<ContactType, Type & { entityType: EntityType }>;
const contactTypes: KeyedContactType = {
    "EMAIL": {...newType("1", "EMAIL"), entityType: "Contact"},
    "AMS_EMAIL": {...newType("8", "AMS_EMAIL"), entityType: "Contact"},
    "MOBILE": {...newType("2", "MOBILE"), entityType: "Contact"},
    "HOME": {...newType("3", "HOME"), entityType: "Contact"},
    "WORK": {...newType("4", "WORK"), entityType: "Contact"},
    "FAX": {...newType("5", "FAX"), entityType: "Contact"},
    "WEB": {...newType("6", "WEB"), entityType: "Contact"},
    "AFTER_HOURS": {...newType("7", "AFTER_HOURS"), entityType: "Contact"},
}

const newParty = (cType: Type & { entityType: EntityType }, name: string): Party => {
    const {entityType, ...type} = cType;
    return {
        ...newEntity(entityType),
        name,
        type,
    }
}

const newPartyRole = (cType: Type & { entityType: EntityType }): PartyRole => {
    const {entityType, ...type} = cType;
    return {
        ...newEntity(entityType),
        type,
    }
}

export const newPerson = (title: Title, firstNames: string, surname: string): Person => {
    const name = `${title.name} ${firstNames} ${surname}`
    return {
        ...newParty(partyTypes["Person"], name),
        name,
        title,
        firstNames,
        surname,
        contacts: [
            newContact("MOBILE", "primary"),
            newContact("MOBILE", "secondary"),
            newContact("HOME"),
            newContact("WORK"),
            newContact("EMAIL"),
        ],
        addresses: [
            newAddress("PHYSICAL"),
        ]
    }
}

export const newOrg = (name: string): Org => {
    return {
        ...newParty(partyTypes["Org"], name),
    }
}

export const newCustomer = (): Customer => {
    return {
        ...newPartyRole(partyRoleTypes["Customer"]),
        vetPin: '',
        accountNumber: '',
        attention: '',
        doctor: '',
        contact: '',
    }
}

export const newVetClinic = (): PartyRole => {
    return {
        ...newPartyRole(partyRoleTypes["VetClinic"])
    }
}

export const newWelfare = (): PartyRole => {
    return {
        ...newPartyRole(partyRoleTypes["Welfare"])
    }
}

export const newBreeder = (): PartyRole => {
    return {
        ...newPartyRole(partyRoleTypes["Breeder"])
    }
}

export const newRoleOfType = (type: PartyRoleType): PartyRole => {
    switch (type) {
        case "PET":
            break;
        case "OWNER":
            break;
        case "PET_RESCUER":
            break;
        case "BREEDER":
            return newBreeder()
        case "CUSTOMER":
            return newCustomer()
        case "VET_CLINIC":
            return newVetClinic()
        case "WELFARE":
            return newWelfare()
        case "USER":
            break;
        case "EMPLOYEE":
            break;
        case "EMPLOYER":
            break;
        case "VOLUNTEER":
            break;
        case "ADOPTABLE":
            break;
        case "ADOPTER":
            break;
    }

    throw newError(`Can't create role of type ${type}`)
}

export const newPet = (name: string, species: Species, sex: Sex, sterilized: Sterilized): Pet => {
    return {
        ...newEntity("Pet"),
        type: partyRoleTypes["Pet"],
        name,
        sex,
        species,
        sterilized,
    }
}

export const newCremation = (cremated: Cremated | Cremated[], condolenceText?: string): Cremation => {
    return {
        ...newEntity("Cremation"),
        cremated: asArray(cremated),
        condolenceVia: "email",
        addressee: '',
        condolenceText,
        mobile: newContact('MOBILE'),
        email: newContact('EMAIL'),
    }
}

export const newCremated = (species: Species, sex: Sex, sterilized: Sterilized): Cremated => {
    return {
        ...newEntity("Cremated"),
        deceasedDate: dateUtils.format(new Date(), "yyyy-MM-dd"),
        pet: newPet('', species, sex, sterilized),
    }
}

export const newAddress = (type: AddressType): Address => {
    return {
        ...newEntity("Address"),
        type: addressTypes[type],
        line1: '',
        line2: '',
        line3: '',
        line4: '',
        code: '',
    }
}

export const newAddressFromPlace = (place: google.maps.places.PlaceResult, type: AddressType): Address => {
    const parts = place.formatted_address?.split(',').map(val => val.trim())
    if (!parts || parts.length < 3) {
        throw newError('Could not build valid address from place')
    }

    // The country is the last part, so pop it off
    parts.pop()

    return {
        ...newEntity("Address"),
        type: addressTypes[type],
        code: parts.pop(),
        line1: parts[0],
        line2: parts.length >= 2 ? parts[1] : '',
        line3: parts.length >= 3 ? parts[2] : '',
        line4: parts.length >= 4 ? parts[3] : '',
        placeId: place.place_id,
        lat: place.geometry?.location?.lat(),
        lng: place.geometry?.location?.lng(),
    }
}

export const newContact = (type: ContactType, description?: string): Contact => {
    return {
        ...newEntity("Contact"),
        type: contactTypes[type],
        description,
        value: '',
    }
}

export const newOrder = (customer: Customer): Order => {
    return {
        ...newEntity("Order"),
        items: [],
        recipient: customer
    }
}

export const newRecovery = (): Recovery => {
    return {
        ...newEntity("Recovery"),
    }
}

export const newRegion = (name: string, type: string): Region => {
    return {
        ...newEntity("Region"),
        name,
        type,
        root: false,
    }
}

export const newNote = (value: string): Note => {
    return {
        ...newEntity("Note"),
        value: value
    }
}

export const newChip = (good: Good, serialNumber: string): Chip => {
    return {
        ...newEntity("Chip"),
        serialNumber,
        thirdParty: isThirdParty(good),
        good,
        inStock: true,
        implantDate: dateUtils.format(new Date(), 'yyyy-MM-dd'),
    }
}

export const isThirdParty = (good: Good): boolean => {
    return good.name === '3RD PARTY CHIP'
}

export const newPetRelatedParty = (party: Party, type: PetRelatedPartyType): PetRelatedParty => {
    return {
        createdAt: dateUtils.date().toISOString(),
        party,
        type,
    }
}

export const newPetOwner = (party: Party): PetRelatedParty => {
    return newPetRelatedParty(party, "OWNER")
}

export const newProduct = (name: string, category: string, chip: boolean): Product => {
    return {
        ...newEntity("Product"),
        name,
        category,
        chip,
        orderable: false
    }
}

export const newOrderItem = (product: Product, quantity: string): OrderItem => {
    return {
        ...newEntity("OrderItem"),
        product,
        quantity
    }
}

export const newShipment = (status: string): Shipment => {
    return {
        ...newEntity("Shipment"),
        status,
        items: []
    }
}

export const newSerializedInventoryItem = (good: Good, serialNumber: string): SerializedInventoryItem => {
    return {
        ...newEntity("SerializedInventoryItem"),
        good,
        serialNumber,
        inStock: true
    }
}

export const newShipmentItem = (inventoryItem: InventoryItem, quantity?: string): ShipmentItem => {
    quantity = !quantity ? "1" : quantity
    if (inventoryItem.good?.serialized && Number.parseInt(quantity) > 1) {
        throw newError('Quantity cannot be > 1 for a serialised good')
    }

    return {
        ...newEntity("ShipmentItem"),
        inventoryItem,
        quantity,
    }
}

export const newGoodsReturn = (reason: string, items: GoodsReturnItem[]): GoodsReturn => {
    return {
        ...newEntity("GoodsReturn"),
        reason,
        items,
    }
}

export const newGoodsReturnItem = (shipmentItem: ShipmentItem): GoodsReturnItem => {
    const good = shipmentItem.inventoryItem.good
    if (!good) {
        throw newError('Good must not be empty in inventory item')
    }

    return {
        ...newEntity("GoodsReturnItem"),
        good,
        quantity: "1",
        serialNumber: serialNumber(shipmentItem),
    }
}

export const newPetSos = (): PetSos => {
    return {
        ...newEntity("PetSos"),
    }
}

export const newGood = (name: string, category: string, chip: boolean, serialized: boolean): Good => {
    return {
        ...newProduct(name, category, chip),
        serialized
    }
}

export const newError = (message: string, code?: number): Error => {
    return {
        code: code || 400,
        message: {
            content: {
                content: message
            }
        }
    }
}

export const newEmptyPaged = <T extends PagedArray & { values: any }>(): T => {
    return {
        _type: '',
        size: 0,
        values: [],
    } as T
}
