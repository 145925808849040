import React, {ReactNode} from "react";
import {Typography} from "@material-ui/core";
import {primary} from "../styles/AppScheme";

interface NameViewProps {
    name?: string;
    label?: ReactNode
}

export const NameView = (props: NameViewProps) => {
    return (
        <Typography component="h2" variant="h6" gutterBottom style={{color: primary}}>
            {props.name}
            {props.label && (
                <span style={{marginLeft: 16}}>{props.label}</span>
            )}
        </Typography>
    )
}
