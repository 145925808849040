import {observer} from "mobx-react-lite";
import {TextField} from "@material-ui/core";
import React from "react";
import {formatAsDate, isString} from "../../utils";
import {EditableFieldProps, styles, title} from "./index";
import {Entity} from "idpet-api";
import {DatumView} from "../DatumView";

export const Date = observer(<T extends Entity>(props: EditableFieldProps<T>) => {
    const classes = styles();
    const value = props.context.getValue(props.field)
    return (
        <div>
            {props.context.editing ? (
                <TextField label={title(props)} variant="filled" value={value}
                           type={"date"}
                           required={props.required}
                           onChange={event => props.context.setValue(props.field, event.target.value)}
                           className={classes.item}
                           autoFocus={props.autoFocus}
                           InputLabelProps={{
                               shrink: true,
                           }}
                />
            ) : (
                <DatumView title={title(props)} children={value && isString(value) ? formatAsDate(value) : '-'}/>
            )}
        </div>
    )
})
