import {action, computed, observable} from "mobx";
import styleMap, {EntityStyle} from "../styles/ColoursAndIconsForEntities";
import {RootStore} from "./RootStore";
import {AbstractStore} from "./RemoteStore";
import {Api} from "idpet-api";
import {take, tap} from "rxjs/operators";
import {getEmployer} from "../utils";

export interface MenuItem {
    name: string;
    id?: string;
    category: string;
    style: EntityStyle;
    path: string;
    action?: ((rootStore: RootStore) => void)
}

const _menuItems: MenuItem[] = [
    {name: 'Search AMLA', category: 'Search', style: styleMap['Pet'], path: '/amla'},
    {name: 'Search Chips', category: 'Search', style: styleMap['Chip'], path: '/chips'},
    {name: 'Search Orders', category: 'Search', style: styleMap['Dispatch'], path: '/orders'},
    {name: 'Search Orgs', category: 'Search', style: styleMap['Organisation'], path: '/orgs'},
    {name: 'Search People', category: 'Search', style: styleMap['Person'], path: '/people'},
    {name: 'Search Pets', category: 'Search', style: styleMap['Pet'], path: '/pets'},

    {name: 'Create Org', category: 'Create', style: styleMap['Organisation'], path: '/orgs/create'},
    // {name: 'Create Person', category: 'Create', style: styleMap['Person'], path: '/people/create'},
    {name: 'Create Pet', category: 'Create', style: styleMap['Pet'], path: '/pets/create'},
    {
        name: 'Create Recovery',
        category: 'Create',
        style: styleMap['Recovery'],
        path: '#',
        action: rootStore => rootStore.pet.recovery.create.openRecovery()
    },
    {
        name: 'Create Order', category: 'Create', style: styleMap['Dispatch'], path: '#', action: rootStore => {
            rootStore.invokeApi((api, params) => api.people.getPersonById('self', {expandos: 'linkedRoles.party'}, params))
                .pipe(
                    take(1),
                    tap(person => {
                        const employer = getEmployer(person)
                        if (!employer) {
                            rootStore.onMessage("WARNING", 'Current user is not linked to a customer')
                        } else {
                            rootStore.history.push(`/orgs/${employer.party?.id}/orders/create`)
                        }
                    })
                ).subscribe()
        }
    },
    {
        name: 'Create Cremation', category: 'Create', style: styleMap['Cremation'], path: '#', action: rootStore => {
            rootStore.cremation.create.reset()
            rootStore.history.push('/cremations/create')
        }
    },

    {name: 'Custom Report', category: 'Reports', style: styleMap['Organisation'], path: '/reports/i/custom'},
    {name: 'Customer Chips', category: 'Reports', style: styleMap['Organisation'], path: '/reports/i/customer_chips'},
    {name: 'Owner Pets', category: 'Reports', style: styleMap['Person'], path: '/reports/i/owner_pets'},
    {name: 'Pending Cremations', category: 'Reports', style: styleMap['Cremation'], path: '/reports/l/pending_cremations'},
    {name: 'Recent Cremations', category: 'Reports', style: styleMap['Cremation'], path: '/reports/l/recent_cremations'},
    {name: 'Open Orders', category: 'Reports', style: styleMap['Order'], path: '/reports/l/open_orders'},
    {name: 'Closed Orders', category: 'Reports', style: styleMap['Order'], path: '/reports/l/closed_orders'},

    {id: 'admin_sos_gallery', name: 'SOS Gallery', category: 'Admin', style: styleMap['Sos'], path: '/sos/gallery/all'},
    {id: 'merge_people', name: 'Merge People', category: 'Admin', style: styleMap['Person'], path: '/people/merge'},
];

export class MenuItemStore extends AbstractStore<Api, RootStore> {
    @observable open: boolean = false;
    @observable query: string = ''

    private filter = () => {
        return ((item: MenuItem) => {
            const id = item.id || item.name.toLowerCase().replace(/\s+/, "_")
            return this.rootStore.config.isMenuVisible(id)
        })
    }

    menuItems = (): Map<string, MenuItem[]> => {
        const result = new Map();
        // We put a space before upper case letters, allows for partial matches without spacing
        // eg. CC => Create Chip, SP => Search Person, etc.
        const queryParts = (this.query.replace(/([A-Z])/g, ' $1')
            .trim().toLowerCase())
            .split(/\W+/);

        _menuItems.filter(this.filter())
            .filter(item => queryParts.length === 0 || this.menuFilter(queryParts, item))
            .sort(this.menuSort)
            .forEach(item => {
                const items: MenuItem[] = result.get(item.category) || [];
                items.push(item);
                result.set(item.category, items);
            });

        return result;
    }

    @action filterMenu = (query: string) => {
        this.query = query;
    }

    @action show = (query: string) => {
        this.filterMenu(query)
        this.open = true
    }

    @action hide = () => {
        this.open = false
    }

    @computed get hasReports(): boolean {
        return _menuItems.filter(this.filter())
            .filter(item => item.category === 'Reports').length > 0
    }

    private menuFilter = (queryParts: string[], item: MenuItem): boolean => {
        const nameParts = `${item.name.toLowerCase()} ${item.category.toLowerCase()}`.split(/\W+/)
        const uniqNameParts = nameParts.reduce((unique: string[], item) =>
            unique.indexOf(item) >= 0 ? unique : [...unique, item], [])
        // TODO after matching a uniqNamePart, it should be removed to prevent things like CC not matching...
        return queryParts.filter(qp => uniqNameParts.find(np => np.startsWith(qp)) === undefined).length === 0
    }

    private menuSort = (a: MenuItem, b: MenuItem) => {
        return a.category.localeCompare(b.category)
            || a.name.localeCompare(b.name)
    }
}
