import {
    FieldValidationError,
    RemoteCreateStore,
    RemoteItemStore,
    RemoteStoreStatus,
    RequestParams
} from "./RemoteStore";
import {Api, Good, GoodsReturn, Order, OrderItem, Product, Shipment, ShipmentItem} from "idpet-api";
import {RootStore} from "./RootStore";
import {action, computed, observable} from "mobx";
import {
    newError,
    newGoodsReturn,
    newGoodsReturnItem,
    newSerializedInventoryItem,
    newShipment,
    newShipmentItem
} from "../utils/builder";
import {asArray, isGood, isShipmentValid, orderItemSort, serialNumber} from "../utils";
import {RowData} from "@material-ui/data-grid";
import {EntityRowData, TableRowColumns} from "./table";
import {Filter, newStringFilter, simpleStringMatch} from "../utils/filter";

export type ShipmentItemRowData = EntityRowData<ShipmentItem>

export interface ShipmentItemStore {
    rowColumns: TableRowColumns
    selected: ShipmentItemRowData[]
    trigger: () => void
    removeSelected: (reason: string) => void
    setSelected: (val: RowData[]) => void
    status: RemoteStoreStatus
    rows: ShipmentItemRowData[]
    filter: Filter<string>
}

class ShipmentItemStoreImpl implements ShipmentItemStore {
    private readonly parent: ShipmentStore
    @observable _selected: ShipmentItemRowData[] = []
    readonly rowColumns: TableRowColumns = new TableRowColumns()
    readonly filter: Filter<string> = newStringFilter()

    constructor(parent: ShipmentStore) {
        this.parent = parent
    }

    @computed get selected() {
        return this._selected
    }

    @action trigger = (): void => {
        this.parent.reload()
    }

    @action removeSelected = (reason: string) => {
        if (this._selected.length === 0)
            return

        const data: GoodsReturn = newGoodsReturn(reason, this._selected.map(val => newGoodsReturnItem(val.entity)))
        this.parent.invokeOneAndReload(((api, params) =>
            api.shipments.addReturn(this.parent.idOrThrow, data, undefined, params)))
    }

    @computed get status() {
        return this.parent.status
    }

    @computed get rows(): ShipmentItemRowData[] {
        const values = this.parent.item?.items
        if (!values)
            return []

        return values
            .filter(val => simpleStringMatch(this.filter.debounced, serialNumber(val)))
            .map(item => new EntityRowData<ShipmentItem>(item))
    }

    @action setSelected = (val: RowData[]) => {
        // @ts-ignore
        this._selected = val
    }
}

export class ShipmentStore extends RemoteItemStore<Api, RootStore, Shipment> {
    @observable currentEdit?: Shipment
    @observable private _seen: boolean = false
    private _itemStore: ShipmentItemStore = new ShipmentItemStoreImpl(this)

    @computed get isEditingShipment() {
        return this.currentEdit !== undefined
    }

    @action loadShipment = () => {
        this.currentEdit = this.item
        this._seen = true
    }

    get itemStore(): ShipmentItemStore {
        return this._itemStore
    }

    protected _find(api: Api, id: any, params: RequestParams): Promise<Shipment> {
        return api.shipments.getShipmentById(id, {
            expandos: this.expandos([
                'items.association.chips', 'orderId', 'items.inventoryItem'
            ])
        }, params)
    }

    protected _validateEntity(): FieldValidationError | FieldValidationError[] | undefined {
        return this.simpleValidation(isShipmentValid(this.item))
    }
}

export class ShipmentCreateStore extends RemoteCreateStore<Api, RootStore, Shipment> {
    @observable order?: Order
    @observable serialString: string = ''
    @observable captureError?: string

    @observable private _selectedItem?: OrderItem
    @observable private _capturing: boolean = false
    @observable private _serialNumbers: string[] = []
    @observable private _uniqueSerialNumbers: string[] = []

    protected _create(): Shipment {
        if (!this.order)
            throw newError('Order is not set')

        return newShipment('NEW')
    }

    @action load = (orderId: string) => {
        // We don't know if the customer has all the details required.
        this.invokeOne((api, params) =>
                api.orders.getOrderById(orderId, {expandos: this.expandos('items,recipient.party')}, params),
            val => this.setOrder(val))
    }

    @action setOrder(order: Order) {
        this.order = order
        this.order.items = this.order.items.sort(orderItemSort)
        this._selectedItem = this.order.items[0]
        this.reset()
    }

    @computed get orderItems(): OrderItem[] {
        return asArray(this.order?.items)
    }

    @action select = (item: OrderItem) => {
        this._selectedItem = item
    }

    @computed get selected(): OrderItem | undefined {
        return this._selectedItem
    }

    @action selectRows = (rows: RowData[]) => {

    }

    @computed get rows(): RowData[] {
        const asRow = (val: ShipmentItem): RowData => {
            return {id: serialNumber(val) || ''}
        }

        return this.itemsForSelected.map(item => asRow(item))
    }

    @computed get isCaptureValid(): boolean {
        return this.captureCount > 0 && !this.captureError
    }

    @computed get inCapture(): boolean {
        return this._capturing
    }

    @computed get captureCount(): number {
        return this._serialNumbers.length
    }

    @computed get uniqueCount(): number {
        return this._uniqueSerialNumbers.length
    }

    @computed get duplicateCount(): number {
        return this.captureCount - this.uniqueCount
    }

    shipmentItems = (product: Product | undefined): ShipmentItem[] => {
        if (!product)
            return []

        return this.item.items.filter(item => item.inventoryItem.good?.id === product.id)
    }

    @action beginCapture = () => {
        this.serialString = ''
        this._serialNumbers = []
        this._uniqueSerialNumbers = []
        this.captureError = undefined
        this._capturing = true
    }

    @action cancelCapture = () => {
        this._capturing = false
    }

    @action saveCapture = () => {
        if (!this._selectedItem) {
            throw newError('No order item selected')
        }

        if (!isGood(this._selectedItem.product)) {
            throw newError(`The product ${this._selectedItem.product.id} is not a good`)
        }

        const good: Good = this._selectedItem.product
        this._uniqueSerialNumbers
            .map(val => newShipmentItem(newSerializedInventoryItem(good, val), "1"))
            .forEach(item => this.item.items.push(item))

        this._capturing = false
    }

    @computed get itemsForSelected(): ShipmentItem[] {
        return this.shipmentItems(this._selectedItem?.product)
    }

    @action setSerialString = (val: string) => {
        this.serialString = val
        this._serialNumbers = val.split(/\s+/)
            // Don't put in empty strings
            .filter(val => val.length > 0)
            // Don't add ones already added
            .filter(val => this.itemsForSelected.map(item => serialNumber(item)).indexOf(val) < 0)
        this._uniqueSerialNumbers = Array.from(new Set(this._serialNumbers))

        // Check status
        const alreadyAddedCount: number = this.itemsForSelected.length
        const outstanding: number = Number.parseInt(this._selectedItem?.outstanding || '0')
        if (alreadyAddedCount + this.uniqueCount > outstanding) {
            this.captureError = `Too many items. Max is ${outstanding - alreadyAddedCount}`
        } else {
            this.captureError = undefined
        }
    }

    protected _validateEntity(): FieldValidationError | FieldValidationError[] | undefined {
        return this.simpleValidation(isShipmentValid(this.item))
    }

    protected _save(api: Api, item: Shipment, params: RequestParams): Promise<Shipment> {
        if (!this.order || !this.order.id)
            throw newError('order not set')

        return api.orders.addShipment(this.order.id, item, {}, params)
    }
}

