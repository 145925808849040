import {RemoteCreateStore, RemoteItemStore, RequestParams,} from "./RemoteStore";
import {action, computed, observable} from "mobx";
import {Api, Chip, Org, Recovery} from "idpet-api";
import {newError, newRecovery} from "../utils/builder";
import {asArray, getCustomer, getPetOwner, isEmptyString} from "../utils";
import {RootStore} from "./RootStore";
import {OrgSearchStore} from "./OrgStores";
import {ChipSearchStore} from "./ChipStores";
import {OrgEditContext, PersonEditContext, PetEditContext} from "../utils/editContexts";

export class PetRecoveryStore extends RemoteItemStore<Api, RootStore, Recovery> {
    protected _find(api: Api, id: string, params: RequestParams): Promise<Recovery> {
        return api.recoveries.getRecoveryById(id, {
            expandos: this.expandos(
                [
                    'chip.implanter.addresses',
                    'chip.implanter.contacts',
                    'chip.implanter.roles',
                    'chip.pet.chips',
                    'chip.pet.parties.party.addresses',
                    'chip.pet.parties.party.contacts',
                    'chip.pet.recoveries',
                    'chip.order.recipient.party.roles.party.addresses',
                    'chip.order.recipient.party.roles.party.contacts',
                    'chip.order.recipient.party.roles.party.roles',
                    'recoverer.contacts'
                ])
        }, params)
    }

    @computed get otherRecoveries(): Recovery[] {
        // Only show recoveries that aren't this one
        return asArray(this.item?.chip?.pet?.recoveries?.values)
            .filter(recovery => recovery.id !== this.id)
    }

    @computed get recoveryCount(): number | undefined {
        const val = this.item?.chip?.pet?.recoveries?.total
        // There will always be at least 1, since we are dealing with a recovery here...
        return val && val - 1
    }

    ctxPet(): PetEditContext {
        return this.nestedContext(new PetEditContext('pet', {
            item: () => this.item?.chip?.pet,
            editing: () => false,
        }))
    }

    ctxOwner(): PersonEditContext {
        return this.nestedContext(new PersonEditContext('person', {
            item: () => getPetOwner(this.item?.chip?.pet),
            editing: () => false,
        }))
    }

    ctxCustomer(): OrgEditContext {
        return this.nestedContext(new OrgEditContext('customer', {
            item: () => getCustomer(this.item?.chip?.order?.recipient.party)?.party,
            editing: () => false,
        }))
    }

    ctxImplanter() {
        return this.nestedContext(new OrgEditContext('implanter', {
            item: () => this.item?.chip?.implanter,
            editing: () => false,
        }))
    }
}

export class PetRecoveryCreateStore extends RemoteCreateStore<Api, RootStore, Recovery> {
    @observable visible: boolean = false;
    @observable chipSearch: ChipSearchStore
    @observable orgSearch: OrgSearchStore

    constructor(rootStore: RootStore) {
        super(rootStore);
        this.chipSearch = new ChipSearchStore(rootStore, false, false)
        this.orgSearch = new OrgSearchStore(rootStore, true, false)
    }

    setChipQuery = (query: string) => {
        this.chipSearch.setQuery(query)
    }

    setOrgQuery = (query: string) => {
        this.orgSearch.setQuery(query)
    }

    @action setOrg = (org: Org) => {
        if (!this.item)
            throw newError('Should not get here')

        this.item.recoverer = org
    }

    @action setRecoverer = (recoverer: string) => {
        const item = this.item
        item?.recoverer && (item.caller = recoverer)
    }

    @action triggerChipSearch = () => {
        return this.chipSearch.trigger()
    }

    @computed get chip(): Chip | undefined {
        return this.chipSearch.values()?.[0]
    }

    @computed get chipNotFound(): boolean {
        return !isEmptyString(this.chipSearch.query) && !this.chip
    }

    protected _immediate(): boolean {
        return false
    }

    @action openRecovery = () => {
        this.reset()
        this.clear()
        this.visible = true
    }

    @action hide = () => {
        this.visible = false
    }

    @action clear = () => {
        this.chipSearch.clear()
        this.orgSearch.clear()
    }

    protected _save(api: Api, item: Recovery, params: RequestParams): Promise<Recovery> {
        if (!this.chip || !this.item)
            throw newError('Should not get here')

        this.item.chip = this.chip
        return api.recoveries.addRecovery(this.item, undefined, params)
            .then(recovery => {
                this.clear()
                this.visible = false
                return recovery
            })
    }

    protected _create(): Recovery {
        return {
            ...newRecovery(),
            caller: this.rootStore.user.nickname,
        }
    }
}
