import {RemoteStore} from "./RemoteStore";
import {action, computed, observable} from "mobx";
import {typeSort} from "../utils";
import {newError, TypeType} from "../utils/builder";
import {RootStore} from "./RootStore";
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {Api, Type, TypeCategory} from "idpet-api";

export class TypeStore extends RemoteStore<Api, RootStore> {
    @observable typeMap: Map<string, Map<string, Type>> = new Map<string, Map<string, Type>>()
    @observable defaultsMap: Map<string, Type> = new Map<string, Type>()

    protected _initialize(): Observable<any> {
        return this.invokeApi((api, params) => api.types.getTypeCategories({
            expandos: 'values'
        }, params)).pipe(tap(result => this.setTypeCategories(result)))
    }

    @action private setTypeCategories = (val: TypeCategory[]) => {
        val.forEach(cat => {
            const values = this.typeMap.get(cat.name) || new Map<string, Type>()
            cat.values?.forEach(value => {
                if (!values.get(value.id)) {
                    values.set(value.id, value)
                }
            })
            this.typeMap.set(cat.name, values)

            if (cat.defaultValue) {
                this.defaultsMap.set(cat.name, cat.defaultValue);
            }
        })
    }

    values = (category: TypeType) => {
        const values1 = this.typeMap.get(category)?.values() || [];
        return Array.from(values1).slice().sort(typeSort)
    }

    defaultValue = (category: TypeType): Type => {
        const result = this.defaultsMap.get(category)
        if (!result) {
            throw newError(`Cannot find default type for ${category}`)
        }

        return result
    }

    @computed get categories(): string[] {
        return Array.from(this.typeMap.keys())
    }
}
