import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import React, {Fragment} from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../contexts";
import {Button, Checkbox, FormControlLabel, Typography} from "@material-ui/core";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {green} from "@material-ui/core/colors";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        spinner: {
            color: green[500],
            position: "absolute",
            margin: 'auto',
            zIndex: 1,
        },
        spinContainer: {
            display: 'flex',
            width: `calc(100% - ${theme.spacing(4)}px)`,
            height: `calc(100% - ${theme.spacing(4)}px)`,
            justifyContent: 'center',
            alignItems: 'center',
            position: "absolute",
        },
        divider: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
        message: {
            marginLeft: theme.spacing(1),
        },
        para: {
            marginBottom: theme.spacing(2),
        },
    }),
);

const Buttons = observer(() => {
    const {rescuer} = useStores().person
    return (
        <Fragment>
            <FormControlLabel
                control={<Checkbox checked={rescuer.agreed}
                                   onChange={(event, val) => rescuer.setAgreed(val)}
                                   name="accept"/>}
                label="Become rescuer?"
            />

            <Button variant={"contained"} onClick={() => rescuer.hide()}>
                Cancel
            </Button>

            <Button variant="contained" color="primary"
                    disabled={!rescuer.agreed}
                    onClick={() => rescuer.enablePetRescuer()}>
                Join
            </Button>
        </Fragment>
    )
})

const Content = observer(() => {
    const classes = useStyles()
    return (
        <Fragment>
            <Typography variant={"body1"} gutterBottom className={classes.para}>
                Thank you for thinking about joining the Identipet Pet Rescuer network! We value your time, and will
                only send notifications for pets lost in your immediate vicinity.
            </Typography>

            <Typography variant={"body1"} gutterBottom className={classes.para}>
                To complete the process, please select the check box below, and then click the 'Join' button.
            </Typography>
        </Fragment>
    )
})

const PetRescuerDialog = observer(() => {
    const {rescuer} = useStores().person
    return (
        <Dialog
            open={rescuer.open}
            onClose={rescuer.hide}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                Become a pet rescuer
            </DialogTitle>

            <DialogContent>
                <Content/>
            </DialogContent>

            <DialogActions>
                <Buttons/>
            </DialogActions>
        </Dialog>
    )
})

export default PetRescuerDialog
