import {RemoteSearchStore, RequestParams} from "./RemoteStore";
import {Api, PagedRecentEntities, RecentEntity} from "idpet-api";
import {RootStore} from "./RootStore";
import {action, observable} from "mobx";

export class RecentsStore extends RemoteSearchStore<Api, RootStore, PagedRecentEntities, RecentEntity> {
    @observable visible: boolean = false

    constructor(rootStore: RootStore) {
        super(rootStore);
        // There needs to be a 'pseudo-query' in place, otherwise the trigger doesn't work
        this.query = '--'
    }

    @action show = () => {
        this.visible = true
        this.trigger()
    }

    @action hide = () => {
        this.visible = false
    }

    protected _search(api: Api, data: any, params: RequestParams): Promise<PagedRecentEntities> {
        return api.profile.listRecents({
            ...data,
            expandos: this.expandos([
                'entity.roles',
                'entity.chip.pet.parties',
                'entity.chips',
                'entity.pet',
                'entity.items.good',
                'entity.order.recipient.party',
                'entity.recipient.party',
                'entity.owner.contacts',
                'entity.owner.addresses',
                'entity.cremated.pet.chips',
            ])
        }, params)
    }

    protected _values(): RecentEntity[] | undefined {
        return this.paged?.values;
    }
}

