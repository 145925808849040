import {observer} from "mobx-react-lite";
import React from "react";
import {isString} from "../../utils";
import {Entity} from "idpet-api";
import {EditableFieldProps, EditableText, title} from "./index";
import {DatumView} from "../DatumView";

// TODO chip validation and FDX(A) / FDX(B) classification
export const Chip = observer(<T extends Entity>(props: EditableFieldProps<T>) => {
    const value = props.context.getValue(props.field)
    return (
        <div>
            {props.context.editing ? (
                <EditableText {...props}
                              title={"Chip No"}
                              required={props.required}
                              autoFocus={props.autoFocus}
                />
            ) : (
                <DatumView title={title(props)} children={value && isString(value) ? value : '-'}/>
            )}
        </div>
    )
})
