import {RemoteSearchStore} from "../RemoteStore";
import {Api, Order, PagedOrders, RequestParams} from "idpet-api";
import {RootStore, theRootStore} from "../RootStore";
import {action, computed, observable} from "mobx";
import {EntityRowData, TableRowColumns} from "../table";

export type OrderRowData = EntityRowData<Order>

class OrdersStore extends RemoteSearchStore<Api, RootStore, PagedOrders, Order> {
    @observable type?: string
    rowColumns: TableRowColumns = new TableRowColumns()
    sortOrder: string = 'ASC'

    protected _search(api: Api, data: any, params: RequestParams): Promise<PagedOrders> {
        return api.orders.listOrders({
            q: this.query, expandos: this.expandos(
                ['items', 'shipments', 'recipient.party']),
            sort: [`created:${this.sortOrder}`]
        }, params)
    }

    protected _values(): Order[] | undefined {
        return this.paged?.values
    }

    @computed get rows(): OrderRowData[] {
        const values = this.values()
        if (!values)
            return []

        return values
            .map(val => val && new EntityRowData<Order>(val))
    }

    @action open = () => {
        this.setQuery('*(open)')
        this.type = 'Open'
        this.sortOrder = 'ASC'
        this.trigger()
    }

    @action closed = () => {
        this.setQuery('*(closed)')
        this.type = 'Closed'
        this.sortOrder = 'DESC'
        this.trigger()
    }
}

export const ordersStore = new OrdersStore(theRootStore)
