import {FieldValidationError, RemoteCreateStore, RemoteItemStore, RequestParams} from "./RemoteStore";
import {Api, CondolenceVia, Cremated, Cremation, Note, Person, Pet, Type} from "idpet-api";
import {newCremated, newCremation, newError, newPerson, TypeType} from "../utils/builder";
import {action, computed, observable} from "mobx";
import {RootStore} from "./RootStore";
import {tap} from 'rxjs/operators';
import {ContactEditContext, CrematedEditContext, PersonEditContext} from "../utils/editContexts";
import {getContact, getPetOwner, ifPresent, isCremationValid, isEmptyString, isPerson} from "../utils";
import {Observable} from "rxjs";
import {NoteStore} from "./index";

export class CremationStore extends RemoteItemStore<Api, RootStore, Cremation> implements NoteStore {
    protected _find(api: Api, id: any, params: RequestParams): Promise<Cremation> {
        return api.cremations.getCremationById(id, {
            expandos: this.expandos([
                'owner.contacts',
                'owner.addresses',
                'owner.roles',
                'cremated.pet.chips',
                'order.notes'])
        }, params);
    }

    protected _delete(api: Api, id: string, params: RequestParams): Promise<void> {
        return api.cremations.deleteCremationById(id, undefined, params)
    }

    addNote = (note: Note) => {
        this.invokeOneAndReload((api, params) =>
            api.cremations.addCremationNote(this.idOrThrow, note, {expandos: ''}, params))
    }

    @computed get notes() {
        return this.item?.order?.notes
    }

    ctxOwner = (): PersonEditContext => {
        return this.nestedContext(new PersonEditContext('cremation-owner', {
            editing: () => this.editing,
            item: () => this.item?.owner
        }))
    }

    ctxMobile = (): ContactEditContext => {
        return this.nestedContext(new ContactEditContext('cremation-mobile', {
            editing: () => this.editing,
            item: () => this.item?.mobile,
        }))
    }

    ctxEmail = (): ContactEditContext => {
        return this.nestedContext(new ContactEditContext('cremation-email', {
            editing: () => this.editing,
            item: () => this.item?.email,
        }))
    }
}

export class CremationCreateStore extends RemoteCreateStore<Api, RootStore, Cremation> {
    @observable currentEdit?: Cremated
    @observable private _seen: boolean = true

    protected _create(): Cremation {
        this._seen = false
        return {
            ...newCremation([], this.rootStore.config.defaultCondolenceText)
        }
    }

    protected _initialize(): Observable<any> {
        return super._initialize().pipe(tap(() => {
            // Wait until the types are initialized
            this.rootStore.types.initialize().then(() => this.reset(), err => {})
        }))
    }

    protected _save(api: Api, item: Cremation, params: RequestParams): Promise<Cremation> {
        return api.cremations.addCremation(item, {}, params)
    }

    @action setType = (type: string) => {
        function isCondolenceVia(type: string): type is CondolenceVia {
            return type === "email" || type === "postal"
        }

        if (isCondolenceVia(type))
            this.item.condolenceVia = type
    }

    @computed get pets() {
        return this.item?.cremated || []
    }

    @action newCremated = () => {
        const newVal = newCremated(
            this.typeForId("Species", "29"),
            this.defaultType("Sex"),
            this.defaultType("Sterilized"))
        newVal.id = `${this.item?.cremated.length || 0}` || ''
        newVal.serial = ''
        this.editCremated(newVal)
        this._seen = true
    }

    @action editCremated = (item: Cremated) => {
        this.currentEdit = item
    }

    @computed get isCrematedValid() {
        if (!this.currentEdit)
            return false

        const cremated: Cremated = this.currentEdit
        const basic: boolean = !isEmptyString(cremated.pet.name)
            && !isEmptyString(cremated.deceasedDate)
        const roa: boolean = !isEmptyString(cremated.weight)

        return basic && (!cremated.roa || roa)
    }

    @action saveCremated = () => {
        // If the item being edited is not present in the array, add it.
        if (this.item && this.currentEdit && !this.findCrematedById(this.currentEdit.id)) {
            this.item?.cremated.push(this.currentEdit)
        }

        const petOwner = getPetOwner(this.currentEdit?.pet)
        if (this.owner?.id === undefined && isPerson(petOwner)) {
            this.updateWithPerson(petOwner)
        }

        this.currentEdit = undefined;
    }

    @action toggleROA = () => {
        if (!this.currentEdit)
            throw newError('No item currently selected for editing')

        this.currentEdit.roa = !this.currentEdit.roa
        if (this.currentEdit.roa && !this.currentEdit.casket) {
            this.currentEdit.casket = this.rootStore.product.search.caskets[0]
        }
    }

    private findCrematedById = (id: string | undefined): Cremated | undefined => {
        if (!id || !this.item)
            return undefined

        return this.item?.cremated.find(item => item.id === id)
    }

    @action cancel = () => {
        this.currentEdit = undefined;
    }

    @action updateWithPerson = (person: Person) => {
        this.setValue("owner", person)

        // Set defaults if possible
        if (isEmptyString(this.item.email.value)) {
            const contact = getContact(person, 'EMAIL')
            if (contact) {
                this.item.email.value = contact.value
            }
        }

        // This should only be set where the owner is set after a pet lookup.
        if (isEmptyString(this.item.mobile.value)) {
            const contact = getContact(person, 'MOBILE', 'primary')
            if (contact) {
                this.item.mobile.value = contact.value
            }
        }

        if (isEmptyString(this.item.addressee)) {
            this.item.addressee = person.name
        }
    }

    @action updateWithPet = (pet: Pet) => {
        this.ctxCremated().setValue("pet", pet)
    }

    @computed get isEditingCremated() {
        return this.currentEdit !== undefined
    }

    @computed get owner() {
        return this.item?.owner
    }

    @computed get seen(): boolean {
        return this._seen
    }

    private defaultType(category: TypeType) {
        return this.rootStore.types.defaultValue(category)
    }

    private typeForId(category: TypeType, id: string): Type {
        return this.rootStore.types.values(category).find(type => type.id === id)
            || this.rootStore.types.defaultValue(category)
    }

    ctxMobile = (): ContactEditContext => {
        return this.nestedContext(new ContactEditContext('cremation-mobile', {
            editing: () => true,
            item: () => this.item.mobile,
        }))
    }

    ctxEmail = (): ContactEditContext => {
        return this.nestedContext(new ContactEditContext('cremation-email', {
            editing: () => true,
            item: () => this.item.email,
        }))
    }

    ctxOwner = (): PersonEditContext => {
        return this.nestedContext(new PersonEditContext('cremation-owner', {
            editing: () => false, //!this.owner.id && this.editing,
            item: () => this.owner,
            buildItem: () => newPerson(this.rootStore.types.defaultValue("Title"), '', '')
        }))
    }

    ctxCremated = (): CrematedEditContext => {
        const item = this.currentEdit
        if (!item)
            throw newError('No item currently selected for editing')

        return this.nestedContext(new CrematedEditContext(`cremated-${item.id}`, {
            editing: () => this.editing,
            item: () => this.currentEdit,
            buildItem: () => this.newCremated()
        }))
    }

    triggerPetSearch = () => {
        const query = this.ctxCremated().getValue("serial")
        if (this.currentEdit?.pet.id !== undefined || !query)
            return

        this.invokeOne((api, params) =>
            api.pets.listPets({
                q: query, limit: 1, expandos: this.expandos([
                    "chips", "parties.party.contacts", "parties.party.addresses", "parties.party.roles"
                ])
            }, params), result => ifPresent(result.values, val => this.updateWithPet(val)))
    }

    triggerPersonSearch = () => {
        const query = this.item.mobile.value
        if (this.owner?.id !== undefined || !query)
            return

        return this.invokeOne((api, params) =>
                api.people.listPeople({
                    q: query, limit: 1, expandos: this.expandos(["contacts", "addresses"])
                }, params),
            (result) => ifPresent(result.values, val => this.updateWithPerson(val)))
    }

    protected _validateEntity(): FieldValidationError | FieldValidationError[] | undefined {
        return this.simpleValidation(isCremationValid(this.item))
    }
}
