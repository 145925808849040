import {FieldValidationError, Patch, RemoteCreateStore, RemoteSearchStore, RequestParams} from "./RemoteStore";
import {AddressType, clipArrays, ContactType, getCustomer, isOrgValid} from "../utils";
import {Api, Entity, Note, Org, PagedOrgs, Party} from "idpet-api";
import {newCustomer, newError, newOrg} from "../utils/builder";
import {action, computed, observable} from "mobx";
import {
    CustomerEditContext,
    PartyAddressEditContext,
    PartyContactEditContext,
    RegionEditContext
} from "../utils/editContexts";
import {RootStore} from "./RootStore";
import {tap} from 'rxjs/operators';
import {NoteStore} from "./index";
import {Observable} from "rxjs";
import {linkEmployee, unlinkEmployee} from "../utils/actions";
import {PartyStore} from "./PartyStores";

export class OrgStore extends PartyStore<Org> implements NoteStore {

    @observable showPrivateQrCode: boolean = false

    protected _find(api: Api, id: any, params: RequestParams): Promise<Org> {
        return api.orgs.getOrgById(id, {
            expandos: this.expandos([
                'addresses',
                'contacts',
                'employees.party.roles',
                'images',
                'notes',
                'roles.qr',
                'regions',
                'roles.orders',
                'roles.implanted.pet',
            ])
        }, params).then(org => {
            const config = this.rootStore.config;
            if (config.admin || config.isEmployee(org)) {
                this.animalStore.load(id)
            }

            return org
        })
    }

    protected _save(api: Api, id: string, patch: Patch, params: RequestParams): Promise<Org> {
        return api.orgs.updateOrgById(id, this.clip(patch), undefined, params)
    }

    protected _delete(api: Api, id: string, params: RequestParams): Promise<void> {
        return api.orgs.deleteOrgById(id, undefined, params)
    }

    private clip = (patch: Patch): Org => {
        // Clip off unnecessary stuff from the org
        const org: Org = patch.data
        clipArrays(org, ["contacts", "addresses", "roles", "regions"])
        org.roles?.forEach(role => {
            clipArrays(role)
            role.party = undefined
        })

        return org
    }

    ctxAddress(type: AddressType): PartyAddressEditContext {
        return this.nestedContext(new PartyAddressEditContext(this, type))
    }

    ctxContact(type: ContactType, subtype?: string): PartyContactEditContext {
        return this.nestedContext(new PartyContactEditContext(this, type, subtype))
    }

    ctxCustomer(): CustomerEditContext {
        return this.nestedContext(CustomerEditContext.create(this))
    }

    ctxGeoRegion(): RegionEditContext {
        return this.nestedContext(new RegionEditContext(this, 'GEOGRAPHICAL'))
    }

    addNote = (note: Note) => {
        const id = this.idOrThrow
        this.invokeOneAndReload((api, params) => api.orgs.addOrgNote(id, note, {expandos: ''}, params))
    }

    @computed get notes() {
        return this.item?.notes
    }

    @computed get privateQrCode(): string | undefined {
        return getCustomer(this.item)?.extraInfo?.['qr.code.internal']
    }

    @action closePrivateQrCode = () => {
        this.showPrivateQrCode = false
    }

    @action openPrivateQrCode = () => {
        this.showPrivateQrCode = true
    }

    protected _validateEntity(): FieldValidationError | FieldValidationError[] | undefined {
        return this.simpleValidation(isOrgValid(this.item))
    }

    unlinkEmployee(person: Party) {
        if (!person.id)
            throw newError('Expected id to be present')

        const personId = person.id
        this.invokeOneAndReload(((api, params) => api.people.initiatePersonAction(personId, unlinkEmployee(), {}, params)))
    }

    linkEmployee(person: Party) {
        const id = this.idOrThrow
        const action = linkEmployee(person)
        this.invokeOneAndReload((api, params) => api.orgs.initiateOrgAction(id, action, {}, params),
            () => this.showMessage('Linked employee'))
    }

    linkEmployeeByUserId(userId: string) {
        const query = `uid:${userId}`
        this.invokeOne((api, params) => api.people.listPeople({q: query}, params),
            paged => {
                if (paged.size === 0 || !paged.values) {
                    this.showError(`Could not find user for id ${userId}`)
                } else {
                    this.linkEmployee(paged.values[0])
                }
            })
    }
}

export class OrgCreateStore extends RemoteCreateStore<Api, RootStore, Org> {
    protected _create(): Org {
        return {
            ...newOrg(''),
            roles: [
                newCustomer()
            ]
        }
    }

    protected _save(api: Api, item: Org, params: RequestParams): Promise<Org> {
        return api.orgs.addOrg(item, {}, params)
    }

    protected _initialize(): Observable<any> {
        return super._initialize().pipe(tap(() => this.reset()))
    }

    _isValid<U extends Entity>(item: U | undefined, name: keyof U, value: any): string | undefined {
        return undefined
    }

    ctxAddress(type: AddressType): PartyAddressEditContext {
        return this.nestedContext(new PartyAddressEditContext(this, type))
    }

    ctxContact(type: ContactType, subtype?: string): PartyContactEditContext {
        return this.nestedContext(new PartyContactEditContext(this, type, subtype))
    }

    ctxCustomer(): CustomerEditContext {
        return this.nestedContext(CustomerEditContext.create(this))
    }

    protected _validateEntity(): FieldValidationError | FieldValidationError[] | undefined {
        return this.simpleValidation(isOrgValid(this.item))
    }
}

export class OrgSearchStore extends RemoteSearchStore<Api, RootStore, PagedOrgs, Org> {
    protected _search(api: Api, data: any, params: RequestParams): Promise<PagedOrgs> {
        return api.orgs.listOrgs({...data, expandos: this.expandos('roles')}, params);
    }

    protected _values(): Org[] | undefined {
        return this.paged?.values;
    }
}

