import {RootStore} from "../stores/RootStore";
import {promptAlert} from "../dialogs/AlertDialogs";

interface KeyBinding {
    available: ((rootStore: RootStore) => boolean)
    matches: ((event: KeyboardEvent) => boolean)
    action: ((rootStore: RootStore) => void)
}

const bindings: KeyBinding[] = [
    // Menu dialog popup
    {
        available: () => true,
        matches: (event => event.key === '.'),
        action: rootStore => rootStore.menu.show(''),
    },
    // Recovery
    {
        available: rootStore => rootStore.config.isMenuVisible('create_recovery'),
        matches: (event => event.key === 'r'),
        action: rootStore => rootStore.pet.recovery.create.openRecovery(),
    },
    // Go url
    {
        available: rootStore => rootStore.config.isFieldVisible('json'),
        matches: (event => event.key === 'g'),
        action: rootStore => rootStore.alert.show(promptAlert('Path to go to?', {
            label: 'Url',
            defaultValue: ''
        }, (result, value) => {
            if (result === "ok" && value)
                rootStore.history.push(value)
        })),
    },
]

interface BindingState {
    rootStore?: RootStore
    user?: any
}

const state: BindingState = {}

export const initializeBindings = (user: any, rootStore: RootStore) => {
    state.user = user;
    state.rootStore = rootStore;

    // activeBindings.length = 0
    // bindings.filter(binding => binding.available(rootStore))
    //     .forEach(binding => activeBindings.push(binding))
}

const keyDown = (event: KeyboardEvent) => {
    if (isTextBox(event.target))
        return

    const action = bindings.filter(binding => state.rootStore && binding.available(state.rootStore))
        .find(binding => binding.matches(event));
    if (action && state.rootStore) {
        const store = state.rootStore
        const timer = setTimeout(() => {
            action.action(store)
            clearTimeout(timer)
        }, 100);
    }
}

function isTextBox(element: any) {
    var tagName = element.tagName?.toLowerCase();
    if (tagName === 'textarea') return true;
    if (tagName !== 'input') return false;
    var type = element.getAttribute('type').toLowerCase(),
        // if any of these input types is not supported by a browser, it will behave as input type text.
        inputTypes = ['text', 'password', 'number', 'email', 'tel', 'url', 'search', 'date', 'datetime', 'datetime-local', 'time', 'month', 'week']
    return inputTypes.indexOf(type) >= 0;
}

document.addEventListener("keydown", keyDown);
