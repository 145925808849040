import {makeStyles} from "@material-ui/core";
import {createStyles, Theme} from "@material-ui/core/styles";
import {red} from "@material-ui/core/colors";

export const getEntityLayoutStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        height: 140,
        width: 200,
    },
    control: {
        padding: theme.spacing(3),
    }
}));

export const columnStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            justifyContent: 'stretch',
            // border: '2px solid red',
        },
        column: {
            marginRight: theme.spacing(1),
            // border: '2px solid green',
            flex: '1 1',
            '& :last-child': {
                marginRight: 0,
            }
        },
        col2x: {
            marginRight: theme.spacing(1),
            // border: '2px solid green',
            flex: '2 1',
            '& :last-child': {
                marginRight: 0,
            }
        },
        linkAdjust: {
            marginTop: theme.spacing(-1),
            position: "absolute",
            right: theme.spacing(1),
        },
        textAdjust: {
            marginTop: theme.spacing(1),
        },
        fg2: {
            flexGrow: 2,
        },
        fg3: {
            flexGrow: 3,
        },
    }),
);

export const cardStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            maxWidth: 345,
        },
        avatar: {
            backgroundColor: red[500],
        }
    }),
);

export const formStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: '2px 4px 6px',
        },
        control: {
            padding: theme.spacing(2),
        },
        section: {
            // height: 200
        },
        formRow: {
            paddingBottom: theme.spacing(2),
        },
        row: {
            paddingBottom: theme.spacing(1),
        },
        input: {
            height: 40,
            minWidth: 100,
            marginRight: 20
        },
        datepicker: {
            marginLeft: 20,
        },
        table: {
            marginTop: 10
        }
    }),
);
