import {observer} from "mobx-react-lite";
import React, {Fragment} from "react";
import {Box, Tooltip} from "@material-ui/core";
import {NameView} from "../NameView";
import {Person} from "idpet-api";
import {columnStyles} from "../../styles/EntityLayoutStyles";
import {EditableDropdown, EditableProps, EditableText, valueSupplierForType} from "../editable";
import {isFamily, isPrnSubscriber} from "../../utils";
import LoyaltyIcon from "@material-ui/icons/Loyalty";

export const PersonName = observer((props: EditableProps<Person>) => {
    const colClasses = columnStyles();
    const name = Array.of(
        props.context.getValue("title")?.commonName,
        props.context.getValue("firstNames"),
        props.context.getValue("surname")).filter(value => value).join(" ").trim()

    const showFirstNames = !isFamily(props.context.item?.title)
    const label = isPrnSubscriber(props.context.item) ? (
        <Tooltip title={"Active Pet Rescuer Membership"}>
            <LoyaltyIcon color={"primary"}/>
        </Tooltip>
    ) : undefined

    return (
        <Fragment>
            {props.context.editing ? (
                <Box className={colClasses.container}>
                    <Box className={colClasses.column}>
                        <EditableDropdown context={props.context}
                                          field={"title"}
                                          valueSupplier={valueSupplierForType("Title")}
                                          required={props.required}
                                          autoFocus={props.autoFocus}
                        />
                    </Box>

                    {showFirstNames && (
                        <Box className={colClasses.col2x}>
                            <EditableText title={"First Name(s)"} context={props.context} field={"firstNames"}
                                          required={props.required}/>
                        </Box>
                    )}

                    <Box className={colClasses.col2x}>
                        <EditableText title={"Surname"} context={props.context} field={"surname"}
                                      required={props.required}/>
                    </Box>
                </Box>
            ) : (
                <NameView name={name} label={label}/>
            )}
        </Fragment>
    )
})
