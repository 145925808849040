import {observer} from "mobx-react-lite";
import {useStores} from "../contexts";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import React from "react";
import {Button, Typography} from "@material-ui/core";

export const NewVersionDialog = observer(() => {
    const rootStore = useStores()
    return (
        <Dialog
            open={rootStore.newVersion}
            maxWidth={"sm"}
            fullWidth
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">New Version</DialogTitle>
            <DialogContent>
                <Typography variant={"body1"} gutterBottom>
                    A new version of the Identipet Application has been released. Click "Ok" to reload.
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => window.location.reload()} variant="contained" color="primary">Ok</Button>
            </DialogActions>
        </Dialog>
    )
})
