import {Api, Good, PagedProducts, Product, Service} from "idpet-api";
import {RemoteSearchStore, RequestParams} from "./RemoteStore";
import {RootStore} from "./RootStore";
import {Observable} from "rxjs";
import {tap} from "rxjs/operators";
import {computed} from "mobx";
import {isGood, isService} from "../utils";
import {newError} from "../utils/builder";

export class ProductSearchStore extends RemoteSearchStore<Api, RootStore, PagedProducts, Product> {
    protected _search(api: Api, data: any, params: RequestParams): Promise<PagedProducts> {
        return api.products.listProducts({...data, expandos: this.expandos('')}, params);
    }

    protected _values(): Product[] | undefined {
        return this.paged?.values;
    }

    protected _initialize(): Observable<any> {
        return this.invokeApi((api, params) => api.products.listProducts({
            expandos: this.expandos('')
        }, params)).pipe(tap(result => this.setResults(result)))
    }

    @computed get caskets(): Product[] {
        return this.values()
                ?.filter(product => product.category === 'CASKET')
                ?.filter(product => product.orderable)
                .sort((a, b) => a.name.localeCompare(b.name))
            || []
    }

    @computed get idpetProducts(): Product[] {
        const categories = ['CHIP', 'SCANNER', 'BULK_CHIPS']
        return this.values()
                ?.filter(product => categories.indexOf(product.category) >= 0)
                ?.filter(product => product.orderable)
                .sort((a, b) => a.name.localeCompare(b.name))
            || []
    }

    @computed get services(): Service[] {
        return this.values()
                ?.filter(product => isService(product))
                ?.filter(service => service.orderable)
                .sort((a, b) => a.name.localeCompare(b.name))
            || []
    }

    @computed get idpetChip(): Good {
        return this.goodWithName('CHIP')
    }

    @computed get thirdPartyChip(): Good {
        return this.goodWithName('3RD PARTY CHIP')
    }

    @computed get chipPack(): Good {
        return this.goodWithName('CHIP PACK')
    }

    private goodWithName = (name: string): Good => {
        const product = this.values()?.find(val => val.name === name);
        if (!isGood(product)) {
            throw newError('Invalid @idpetChip')
        }

        return product
    }
}
