import {ReactNode} from "react";
import {
    Address,
    Chip,
    Contact,
    Cremated,
    Cremation,
    Customer,
    Employee,
    Entity,
    Error as ApiError,
    Good,
    Image,
    InventoryItem,
    MobileDevice,
    MobileEvent,
    MobileProfile,
    Order,
    OrderItem,
    Org,
    Party,
    PartyRole,
    Person,
    Pet,
    PetInteraction,
    PetRelatedPartyType,
    PetRescuer,
    PetRescuerStatistic,
    PetSos,
    Product,
    Recovery,
    SerializedInventoryItem,
    Service,
    Shipment,
    ShipmentItem,
    Statistic,
    Subscription,
    Title,
    Type,
} from "idpet-api";
import {EntityType} from "./builder";
import {HasContacts} from "./props";
import {toJS} from "mobx";
import DateUtilsAdapter from "@date-io/date-fns";

//
// Defined constants
//

export const DateFormat = 'yyyy/MM/dd'
export const DateTimeFormat = 'yyyy/MM/dd HH:mm:ss'

export const dateUtils = new DateUtilsAdapter()

//
// Defined types
//

export type ContactType = 'AMS_EMAIL'|'EMAIL' | 'MOBILE' | 'HOME' | 'WORK' | 'FAX' | 'WEB' | 'AFTER_HOURS'
export type AddressType = 'PHYSICAL' | 'POSTAL' | 'ALERT'
export type SexType = 'FEMALE' | 'MALE' | 'UNKNOWN'
export type SterilizedType = 'YES' | 'NO' | 'UNKNOWN'

export type PartyType = 'PERSON' | 'ORG' | 'ANIMAL';
export type PartyRoleType = 'PET' // 1
    | 'OWNER' // 2
    | 'PET_RESCUER' // 3
    | 'BREEDER' // 4
    | 'CUSTOMER' // 5
    | 'VET_CLINIC' // 6
    | 'WELFARE' // 7
    | 'USER' // 8
    | 'EMPLOYEE' // 9
    | 'EMPLOYER' // 10
    | 'VOLUNTEER' // 11
    | 'ADOPTABLE' // 12
    | 'ADOPTER' // 13
    | 'BENEFICIARY' // 14

//
// Guard functions
//

export const isPerson = (entity: Entity | undefined): entity is Person => {
    return isEntityOfType(entity, "Person")
}

export const isOrg = (entity: Entity | undefined): entity is Org => {
    return isEntityOfType(entity, "Org")
}

export const isCustomer = (role: PartyRole | undefined): role is Customer => {
    return isRoleOfType(role, "CUSTOMER")
}

export const isCremated = (entity: Entity | undefined): entity is Cremated => {
    return isEntityOfType(entity, "Cremated")
}

export const isCremation = (entity: Entity | undefined): entity is Cremation => {
    return isEntityOfType(entity, "Cremation")
}

export const isProduct = (product: Entity | undefined): product is Product => {
    // A good is also a product
    return isEntityOfType(product, "Product")
        || isEntityOfType(product, "Good")
        || isEntityOfType(product, "Service")
}

export const isGood = (product: Product | Entity | undefined): product is Good => {
    return isEntityOfType(product, "Good")
}

export const isService = (product: Product | Entity | undefined): product is Service => {
    return isEntityOfType(product, "Service")
}

export const isPet = (entity: Entity | undefined): entity is Pet => {
    return isEntityOfType(entity, "Pet")
}

export const isPetSos = (entity: Entity | undefined): entity is PetSos => {
    return isEntityOfType(entity, "PetSos")
}

export const isOrder = (entity: Entity | undefined): entity is Order => {
    return isEntityOfType(entity, "Order")
}

export const isShipment = (entity: Entity | undefined): entity is Shipment => {
    return isEntityOfType(entity, "Shipment")
}

export const isShipmentItem = (entity: Entity | undefined): entity is ShipmentItem => {
    return isEntityOfType(entity, "ShipmentItem")
}

export const isInventoryItem = (entity: Entity | undefined): entity is InventoryItem => {
    return isEntityOfType(entity, "InventoryItem")
}

export const isSerializedInventoryItem = (entity: Entity | undefined): entity is SerializedInventoryItem => {
    return entity !== undefined && "serialNumber" in entity
}

export const isChip = (entity: Entity | undefined): entity is Chip => {
    return isEntityOfType(entity, "Chip")
}

export const isRecovery = (entity: Entity | undefined): entity is Recovery => {
    return isEntityOfType(entity, "Recovery")
}

export const isEntityOfType = (entity: Entity | undefined, type: EntityType) => {
    return entity !== undefined && entity._objectType === type
}

export const isRoleOfType = (role: PartyRole | undefined, type: PartyRoleType) => {
    return role !== undefined && role.type?.name === type
}

export const isFamily = (title?: Title): boolean => {
    return title?.name !== undefined && title.name.toUpperCase() === 'FAMILY'
}

export const isString = (val: any): val is string => {
    return typeof val === 'string' || val instanceof String
}

export const isBoolean = (val: any): val is boolean => {
    return typeof val === 'boolean' || val instanceof Boolean
}

export const isNumber = (val: any): val is number => {
    return typeof val === 'number' || val instanceof Number
}

export const isContactType = (val: string): val is ContactType => {
    // TODO Ugh - should check type values...
    return true;
}

export const isType = (val: any): val is Type => {
    return val && "name" in val && "commonName" in val && "sortOrder" in val
}

export const isInitializer = (val: any): val is HasInitializer => {
    return isObject(val) && "initialize" in val
}

export const isObject = (obj: any): boolean => {
    return typeof obj === 'object' && obj !== null
}

export const isApiError = (obj: any): obj is ApiError => {
    return obj
        && isObject(obj)
        && "code" in obj
        && "message" in obj
        && isObject(obj.message)
        && "content" in obj.message
}

export const isPRNStat = (val: Statistic): val is PetRescuerStatistic => {
    return val._objectType === "PetRescuerStatistic"
}

//
// Data extraction functions
//

export const getVetPin = (org: Org | undefined) => {
    return org?.roles?.find(isCustomer)?.vetPin
}

export const getContact = (container: HasContacts | Party | PetSos | undefined, type: ContactType, subtype?: string) => {
    return asArray(container?.contacts).find(contact => {
        return contact.type.name === type
            && (!subtype || contact.description === subtype);
    });
}

export const getAddress = (party: Party | undefined, type: AddressType) => {
    return party?.addresses?.find(address => address.type.name === type);
}

export const getPetRescuer = (party: Party | undefined): PetRescuer | undefined => {
    return getRoleOfType(party, "PET_RESCUER")
}

export const getSubscriptions = (party: Party | undefined): Subscription[] => {
    return getCustomer(party)?.subscriptions || []
}

export const getPrnSubscription = (party: Party | undefined): Subscription | undefined => {
    const customer = getCustomer(party)
    return customer?.subscriptions
        ?.find(subscription => subscription.service.category === 'PRN')
}

export const getOwner = (party: Party | undefined) => {
    return getRoleOfType(party, "OWNER")
}

export const getPetOwner = (pet: Pet | undefined): Party | undefined => {
    return getPartyOfType(pet, "OWNER")?.party
}

export const getCustomer = (party: Party | undefined): Customer | undefined => {
    // @ts-ignore
    return getRoleOfType(party, "CUSTOMER");
}

export const getEmployees = (party: Party | undefined): Employee[] | undefined => {
    // @ts-ignore
    return party?.linkedRoles?.filter(role => role.type?.name === "EMPLOYEE")
}

export const getEmployer = (party: Party | undefined): PartyRole | undefined => {
    // @ts-ignore
    return party?.linkedRoles?.filter(role => role.type?.name === "EMPLOYER")?.[0]
}

export const getContactTitle = (contact: Contact | undefined) => {
    return contact?.description
        ? `${contact.type.name} (${contact?.description})`
        : contact?.type.name;
}

export const getRoleOfType = (party: Party | undefined, type: PartyRoleType) => {
    return party?.roles?.find(role => role.type?.name === type)
}

export const getPartyOfType = (pet: Pet | undefined, type: PetRelatedPartyType) => {
    return pet?.parties?.find(role => role.type === type)
}

export const hasRoleOfType = (party: Party | undefined, type: PartyRoleType) => {
    return getRoleOfType(party, type) !== undefined
}

export const getLifetime = (pet: Pet | undefined) => {
    return pet?.interactions?.find(interaction => interaction.responseType?.toLowerCase() === 'lifetime')
}

export const serialNumber = (entity: Entity | undefined): string | undefined => {
    if (isSerializedInventoryItem(entity)) {
        return entity.serialNumber
    } else if (isShipmentItem(entity)) {
        return serialNumber(entity.inventoryItem)
    }

    return undefined
}

//
// Sorting functions
//

export const chipSort = (a: Chip, b: Chip) => {
    return a.serialNumber.localeCompare(b.serialNumber)
}

export const crematedSort = (a: Cremated, b: Cremated) => {
    function safeId(item: Cremated) {
        return item.id || 'ZZZ'
    }

    return safeId(a).localeCompare(safeId(b))
}

export const imageSort = (a: Image, b: Image) => {
    function safeId(item: Image) {
        return item.id || 'ZZZ'
    }

    return dateUtils.getDiff(dateUtils.date(b.createdAt), dateUtils.date(a.createdAt))
        || safeId(a).localeCompare(safeId(b))
}

export const petSort = (a: Pet, b: Pet) => {
    function safeName(pet: Pet) {
        return pet.name || 'ZZZ'
    }

    function safeChip(pet: Pet) {
        return pet.chips?.[0].serialNumber || 'ZZZ'
    }

    return safeName(a).localeCompare(safeName(b))
        || safeChip(a).localeCompare(safeChip(b))
        || -1
}

export const orderSort = (a: Order, b: Order) => {
    return dateUtils.getDiff(dateUtils.date(b.createdAt), dateUtils.date(a.createdAt))
}

export const contactSort = (a: Contact, b: Contact) => {
    function safeSortOrder(contact: Contact) {
        return contact.type?.sortOrder || 99
    }

    function safeTypeId(contact: Contact) {
        return contact.type?.id || 'ZZZ'
    }

    function safeDescription(contact: Contact) {
        return contact.description || 'ZZZ'
    }

    return safeSortOrder(a) - safeSortOrder(b)
        || safeDescription(a).localeCompare(safeDescription(b))
        || safeTypeId(a).localeCompare(safeTypeId(b))
        || -1
}

export const interactionSort = (a: PetInteraction, b: PetInteraction) => {
    return dateUtils.getDiff(dateUtils.date(b.createdAt), dateUtils.date(a.createdAt));
}

export const shipmentItemSort = (a: ShipmentItem, b: ShipmentItem) => {
    function safeId(item: ShipmentItem) {
        return item.id || 'ZZZ'
    }

    const serialA = serialNumber(a)
    const serialB = serialNumber(b)
    return serialA && serialB
        ? serialA.localeCompare(serialB)
        : safeId(a).localeCompare(safeId(b))
}

export const orderItemSort = (a: OrderItem, b: OrderItem) => {
    function safeId(item: OrderItem) {
        return item.id || 'ZZZ'
    }

    return a.product.name && b.product.name ? a.product.name.localeCompare(b.product.name)
        : safeId(a).localeCompare(safeId(b))
}

export const shipmentSort = (a: Shipment, b: Shipment) => {
    return dateUtils.getDiff(dateUtils.date(b.createdAt), dateUtils.date(a.createdAt))
}

export const deviceSort = (a: MobileDevice, b: MobileDevice) => {
    if (!a.lastCallAt && !b.lastCallAt) {
        return dateUtils.getDiff(dateUtils.date(a.createdAt), dateUtils.date(b.createdAt))
    }

    if (!a.lastCallAt) {
        return 1;
    }

    return !b.lastCallAt ? -1 : dateUtils.getDiff(dateUtils.date(b.lastCallAt), dateUtils.date(a.lastCallAt))
}

export const profileSort = (a: MobileProfile, b: MobileProfile) => {
    return dateUtils.getDiff(dateUtils.date(a.createdAt), dateUtils.date(b.createdAt))
}

export const eventSort = (a: MobileEvent, b: MobileEvent) => {
    return dateUtils.getDiff(dateUtils.date(b.createdAt), dateUtils.date(a.createdAt))
}

export const partyRoleSort = (a: PartyRole, b: PartyRole) => {
    return a.type.name.localeCompare(b.type.name)
}

export const partySort = (a: Party, b: Party) => {
    return a.name.localeCompare(b.name)
}

export const typeSort = (a: Type, b: Type) => {
    const first = a.sortOrder - b.sortOrder
    return first || a.commonName.localeCompare(b.commonName)
}

export const sosSort = (a: PetSos, b: PetSos) => {
    return dateUtils.getDiff(dateUtils.date(b.createdAt), dateUtils.date(a.createdAt))
}

//
// Validity functions
//

export const isPersonValid = (person: Person | undefined, emailRequired: boolean): boolean => {
    if (!person)
        return false

    const isFamily = person.title?.name.toUpperCase() === 'FAMILY'
    const isFirstNameValid = isFamily || !isEmptyString(person.firstNames)

    return isTypeValid(person.title)
        && isFirstNameValid
        && !isEmptyString(person.surname)
        && isContactValid(getContact(person, "MOBILE", "primary"))
        && (isContactValid(getContact(person, "EMAIL")) || !emailRequired)
        && isAddressValid(getAddress(person, "PHYSICAL"))
}

export const isPrnSubscriber = (person: Person | undefined): boolean => {
    if (!person)
        return false

    if (person.extraInfo?.['prn.subscription.id'])
        return true

    return getPrnSubscription(person) !== undefined
}

export const isOrgValid = (org: Org | undefined): boolean => {
    if (org === undefined)
        return false;

    return !isEmptyString(org.name)
        && isContactValid(getContact(org, "WORK"))
        && isContactValid(getContact(org, "EMAIL"))
        && isAddressValid(getAddress(org, "PHYSICAL"))
}

export const isPetValid = (pet: Pet | undefined): boolean => {
    if (pet === undefined)
        return false

    return !isEmptyString(pet.name)
        && !isEmptyString(pet.description)
        && isTypeValid(pet.species)
        && isTypeValid(pet.sex)
        && isTypeValid(pet.sterilized)
}

export const isCremationValid = (cremation: Cremation | undefined): boolean => {
    if (!cremation)
        return false;

    return cremation.cremated
        && cremation.cremated?.length > 0
        && !isEmptyString(cremation.addressee)
        && isContactValid(cremation.mobile)
        && isContactValid(cremation.email)
}

export const isTypeValid = (val: Type | undefined): boolean => {
    return val !== undefined && !isEmptyString(val.id);
}

export const isContactValid = (val: Contact | undefined): boolean => {
    return val !== undefined && !isEmptyString(val.value);
}

export const isAddressValid = (val: Address | undefined): boolean => {
    return val !== undefined && !isEmptyString(val.line1);
}

export const isOrderValid = (val: Order | undefined): boolean => {
    return val !== undefined && val.items.length > 0
}

export const isShipmentValid = (val: Shipment | undefined): boolean => {
    return val !== undefined
        && val.items.length > 0
        && val.items.filter(item => item.quantity !== '0').length > 0
}

//
// Uncategorised functions
//

export const asArray = <T extends any>(value: T[] | T | undefined): T[] => {
    if (value === undefined) {
        return []
    }

    return Array.isArray(value) ? value : [value];
}

export const isEmptyString = (val: ReactNode): boolean => {
    return val === undefined || (isString(val) && val.trim().length === 0)
}

export const isSimpleValue = (value: ReactNode) => {
    return isString(value) || isBoolean(value) || isNumber(value);
}

export const formatAsDate = (value: string | undefined, format?: string) => {
    return value && dateUtils.format(dateUtils.date(value), format ? format : DateFormat)
}

export const formatAsDateTime = (value: string | undefined, format?: string) => {
    return value && dateUtils.format(dateUtils.date(value), format ? format : DateTimeFormat)
}

export const formatAsAddress = (value: Address | undefined) => {
    return value && [value.line1, value.line2, value.line3, value.line4, value.code]
        .filter(val => val !== undefined && val.length > 0).join(", ")
}

export const propertiesOf =
    <TObj extends {}>(_obj: (TObj | undefined) = undefined) => <T extends keyof TObj>(name: T): T => name;

// const nameOf = <T extends {}>(name: keyof T) => name;

export const nameOf = <T extends {}>(_obj: (T | undefined) = undefined, name: keyof T) => name;

// credit: Typescript documentation, src
// https://www.typescriptlang.org/docs/handbook/advanced-types.html#index-types
export function getProperty<T, K extends keyof T>(o: T, propertyName: K): T[K] {
    return o[propertyName]; // o[propertyName] is of type T[K]
}

export interface HasInitializer {
    initialize: (() => Promise<void>)
}

export const clipArrays = <T extends {}>(obj: T, omit?: (keyof T)[]) => {
    (Object.keys(obj) as Array<keyof typeof obj>).forEach(key => {
        if (omit && omit.indexOf(key) >= 0)
            return

        if (!Array.isArray(obj[key]))
            return

        // @ts-ignore
        obj[key] = undefined
    })

    return obj
}

export const alwaysTrue = (fn: (() => any)) => {
    fn();
    return true;
}

export const limitString = (val: string | undefined, maxSize: number) => {
    if (val === undefined)
        return ''

    let result = val.substring(0, Math.min(val.length, maxSize))
    if (val.length > maxSize)
        result += '...'

    return result
}

export const ifPresent = <T extends {}>(val: T[] | T | undefined, callback: ((val: T) => void)) => {
    if (!val || (Array.isArray(val) && val.length === 0))
        return

    callback(Array.isArray(val) ? val[0] : val)
}

export const simpleClone = <T extends {}>(val: T): T => {
    return JSON.parse(JSON.stringify(toJS(val)))
}

export const uniq = <T extends {}>(arr: T[]) => {
    // Per https://medium.com/dailyjs/how-to-remove-array-duplicates-in-es6-5daa8789641c
    // and performance testing at https://blog.usejournal.com/performance-of-javascript-array-ops-2690aed47a50
    return arr.filter((item, index) => arr.indexOf(item) === index)
}
