import {
    FieldValidationError,
    RemoteCreateStore,
    RemoteItemStore,
    RemoteSearchStore,
    RequestParams
} from "./RemoteStore";
import {Api, Customer, Note, Order, OrderItem, PagedOrders} from "idpet-api";
import {newError, newOrder, newOrderItem} from "../utils/builder";
import {RootStore} from "./RootStore";
import {NoteStore} from "./index";
import {action, computed, observable} from "mobx";
import {getCustomer, isEmptyString, isOrderValid} from "../utils";
import {OrderItemEditContext} from "../utils/editContexts";
import {newOrderUpdate} from "../utils/actions";

export class OrderStore extends RemoteItemStore<Api, RootStore, Order> implements NoteStore {
    protected _find(api: Api, id: any, params: RequestParams): Promise<Order> {
        return api.orders.getOrderById(id, {
            expandos: this.expandos([
                'items', 'shipments', 'recipient.party'
            ])
        }, params)
    }

    addNote = (note: Note) => {
        const id = this.idOrThrow
        this.invokeOneAndReload((api, params) => api.orders.addOrderNote(id, note, {expandos: ''}, params))
    }

    @computed get notes() {
        return this.item?.notes
    }

    protected _validateEntity(): FieldValidationError | FieldValidationError[] | undefined {
        return this.simpleValidation(isOrderValid(this.item))
    }

    cancelOrder = () => {
        const id = this.idOrThrow
        const action = newOrderUpdate(id, 'cancel')
        this.invokeOneAndReload((api, params) =>
            api.orders.initiateOrderAction(id, action, {expandos: ''}, params))
    }
}

export class OrderCreateStore extends RemoteCreateStore<Api, RootStore, Order> {
    @observable currentEdit?: OrderItem
    @observable customerId?: string
    @observable customer?: Customer

    protected _create(): Order {
        if (!this.customer)
            throw newError('Customer is not set')

        return newOrder(this.customer)
    }

    @action load = (orgId: string) => {
        // We don't know if the customer has all the details required.
        this.invokeOne((api, params) =>
                api.orgs.getOrgById(orgId || '', {expandos: 'roles.party.addresses'}, params),
            val => {
                const customer = getCustomer(val)
                if (customer) {
                    this.setCustomer(customer)
                } else {
                    this.showError(`Customer not found for org ${orgId}`)
                }
            })
    }

    @action
    private setCustomer(customer: Customer) {
        this.customer = customer
        this.reset()
    }

    ctxOrderItem = (): OrderItemEditContext => {
        const item = this.currentEdit
        if (!item)
            throw newError('No item currently selected for editing')

        return this.nestedContext(new OrderItemEditContext(`item-${item.id}`, {
            editing: () => this.editing,
            item: () => this.currentEdit,
            resetAction: "REMOVE"
        }))
    }

    @computed get isOrderItemValid() {
        if (!this.currentEdit)
            return false

        const orderItem: OrderItem = this.currentEdit
        return !isEmptyString(orderItem.product.name)
            && !isEmptyString(orderItem.quantity)
    }

    @action cancel = () => {
        this.currentEdit = undefined
    }

    @action saveItem = () => {
        // If the item being edited is not present in the array, add it.
        if (this.item && this.currentEdit && !this.findOrderItemById(this.currentEdit.id)) {
            this.item?.items.push(this.currentEdit)
        }

        this.currentEdit = undefined
    }

    @action newOrderItem = () => {
        const aProduct = this.rootStore.product.search.idpetProducts?.[0]
        this.currentEdit = newOrderItem(aProduct, "")
    }

    @computed get isEditingOrderItem() {
        return this.currentEdit !== undefined
    }

    @computed get recipient() {
        return this.item?.recipient
    }

    protected _save(api: Api, item: Order, params: RequestParams): Promise<Order> {
        if (!this.customer?.id)
            throw newError('Customer or id not present')

        return api.customers.addOrder(this.customer.id, item, {}, params)
    }

    protected _validateEntity(): FieldValidationError | FieldValidationError[] | undefined {
        return this.simpleValidation(isOrderValid(this.item))
    }

    private findOrderItemById = (id: string | undefined): OrderItem | undefined => {
        if (!id || !this.item)
            return undefined

        return this.item?.items.find(item => item.id === id)
    }
}

export class OrderSearchStore extends RemoteSearchStore<Api, RootStore, PagedOrders, Order> {
    protected _search(api: Api, data: any, params: RequestParams): Promise<PagedOrders> {
        return api.orders.listOrders({...data, expandos: this.expandos('roles')}, params)
    }

    protected _values(): Order[] | undefined {
        return this.paged?.values
    }
}
