import {observer} from "mobx-react-lite";
import {useStores} from "../contexts";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    List,
    ListItem,
    ListItemSecondaryAction, ListItemText
} from "@material-ui/core";
import React, { Fragment } from "react";
import {SearchField} from "../components/Search";
import {isOrg} from "../utils";

export const PartySearchDialog = observer(() => {
    const {quickSearch} = useStores()

    const searchSupport = quickSearch.searchSupport()
    return (
        <Dialog onClose={quickSearch.cancel}
                open={quickSearch.searchType === 'Org'}
            // fullWidth={true}
                maxWidth={'lg'}
        >
            <DialogTitle>Org Search</DialogTitle>
            <DialogContent>
                {searchSupport && (
                    <Fragment>
                        <SearchField support={searchSupport}/>
                        <List>
                            {quickSearch.values.filter(entity => isOrg(entity)).slice(0, 10).map(org => (
                                <ListItem key={org.id}>
                                    <ListItemText
                                        // @ts-ignore
                                        primary={org.name}
                                    />
                                    <ListItemSecondaryAction onClick={() => quickSearch.select(org)}>
                                        <Button variant={"outlined"}>
                                            Select
                                        </Button>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            ))}
                        </List>
                    </Fragment>
                )}
            </DialogContent>
            <DialogActions>
                <Button variant={"text"} onClick={() => quickSearch.cancel()}>Cancel</Button>
            </DialogActions>
        </Dialog>
    )
})
