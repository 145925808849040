import {
    AcceptAgreement,
    Action,
    Animal,
    AssignTask,
    BulkFlagAdoptable,
    BulkTransferOwnership,
    CreatePersonTask,
    Cremated,
    Entity,
    InitiateMobileReg,
    LinkEmployee,
    LinkPersonAsOwner,
    MarkAlive,
    MarkCremated,
    MarkDeceased,
    MergePeople,
    OrderUpdateStatus,
    Org,
    Party,
    PartyRole,
    Person,
    PetRescuerAction,
    PetSosResolution,
    RequestCertificate,
    ResendVerification,
    TogglePartyRoles,
    TransferPet,
    UnlinkEmployee,
    UpdateChippedBy,
    ValidateMobileReg
} from "idpet-api";
import {dateUtils} from "./index";

type BatchActions = "BulkFlagAdoptable"
    | "BulkTransferOwnership"
    | "MergePeople"

type CremationActions = "MarkCremated"

type OrgActions = "LinkEmployee"

type PartyActions = "TogglePartyRoles"

type PetActions = "MarkAlive"
    | "MarkDeceased"
    | "RequestCertificate"
    | "TransferPet"
    | "UpdateChippedBy"

type ProfileActions = "AcceptAgreement"
    | "AssignTask"
    | "InitiateMobileReg"
    | "ValidateMobileReg"
    | "LinkPersonAsOwner"
    | "ResendVerification"

type PersonActions = "PetRescuerAction"
    | "UnlinkEmployee"
    | "CreatePersonTask"

type SosActions = "PetSosActivate"
    | "PetSosPause"
    | "PetSosResolve"

type OrderActions = "OrderUpdateStatus"

type Actions = BatchActions
    | CremationActions
    | OrderActions
    | OrgActions
    | PartyActions
    | PetActions
    | ProfileActions
    | PersonActions
    | SosActions

type TaskTypes = "PrnSaleTask"

const newAction = (actionType: Actions): Action => {
    return {
        _actionType: actionType,
    }
}

export const requestCertificate = (email: string): RequestCertificate => {
    return {
        ...newAction("RequestCertificate"),
        target: email,
    }
}

export const updateChippedBy = (vetName: string, org?: Org): UpdateChippedBy => {
    return {
        ...newAction("UpdateChippedBy"),
        name: vetName,
        implantingOrg: org,
    }
}

export const markDeceased = (date?: any): MarkDeceased => {
    const actual = date ? dateUtils.date(date) : dateUtils.date()
    return {
        ...newAction("MarkDeceased"),
        date: dateUtils.format(actual, 'yyyy-MM-dd'),
    }
}

export const markAlive = (reason?: string): MarkAlive => {
    return {
        ...newAction("MarkAlive"),
        reason: reason || '',
    }
}

export const transferPet = (party: Party): TransferPet => {
    return {
        ...newAction("TransferPet"),
        target: party,
    }
}

export const acceptAgreement = (agreementId: string): AcceptAgreement => {
    return {
        ...newAction("AcceptAgreement"),
        agreementId,
    }
}

export const initiateMobileReg = (mobile: string): InitiateMobileReg => {
    return {
        ...newAction("InitiateMobileReg"),
        mobile,
    }
}

export const validateMobileReg = (code: string): ValidateMobileReg => {
    return {
        ...newAction("ValidateMobileReg"),
        code,
    }
}

export const petRescuer = (active: boolean): PetRescuerAction => {
    return {
        ...newAction("PetRescuerAction"),
        active,
    }
}

export const linkPerson = (person: Person): LinkPersonAsOwner => {
    return {
        ...newAction("LinkPersonAsOwner"),
        person
    }
}

export const unlinkEmployee = (): UnlinkEmployee => {
    return {
        ...newAction("UnlinkEmployee")
    }
}

export const linkEmployee = (person: Person): LinkEmployee => {
    return {
        ...newAction("LinkEmployee"),
        person
    }
}

export const markCremated = (crematedAt: string, items: Cremated[]): MarkCremated => {
    return {
        ...newAction("MarkCremated"),
        crematedAt,
        items,
    }
}

export const newSosActivate = (comments?: string) => {
    return {
        ...newAction("PetSosActivate"),
        comments,
    }
}

export const newSosPause = (comments?: string) => {
    return {
        ...newAction("PetSosPause"),
        comments,
    }
}

export const newSosResolve = (resolution: PetSosResolution, comments?: string) => {
    return {
        ...newAction("PetSosResolve"),
        resolution,
        comments,
    }
}

export const newBulkFlagAdoptable = (animals: Animal[]): BulkFlagAdoptable => {
    return {
        ...newAction("BulkFlagAdoptable"),
        animals,
    }
}

export const newBulkTransferOwnership = (target: Party, entities: Entity[], reason?: string): BulkTransferOwnership => {
    return {
        ...newAction("BulkTransferOwnership"),
        target,
        entities,
        reason,
    }
}

export const newMergePeople = (target: Person, source: Person[]): MergePeople => {
    return {
        ...newAction("MergePeople"),
        target,
        source,
    }
}

export const newRolesToggle = (enable?: PartyRole[], disable?: PartyRole[]): TogglePartyRoles => {
    return {
        ...newAction("TogglePartyRoles"),
        enable,
        disable,
    }
}

export const newAssignTask = (taskType: TaskTypes): AssignTask => {
    return {
        ...newAction("AssignTask"),
        taskType,
    }
}

export const newResendVerificationTask = (): ResendVerification => {
    return {
        ...newAction("ResendVerification"),
        taskType: ''
    }
}

export const newSendPrnSubscription = (): CreatePersonTask => {
    return {
        ...newAction("CreatePersonTask"),
        taskType: 'PRN_SALES',
        resolution: 'SALE',
    }
}

export const newOrderUpdate = (orderId: string, nextStatus: string): OrderUpdateStatus => {
    return {
        ...newAction("OrderUpdateStatus"),
        currentStatus: '',
        orderId,
        nextStatus
    }
}
