import React from "react";
import {Link as RouterLink} from "react-router-dom";
import {Button} from "@material-ui/core";
import {OverrideProps} from "@material-ui/core/OverridableComponent";
import {ButtonTypeMap} from "@material-ui/core/Button/Button";

export type ButtonLinkProps<D extends React.ElementType = ButtonTypeMap['defaultComponent'],
    P = { to: string; }> = OverrideProps<ButtonTypeMap<P, D>, D>;


export const ButtonLink = (props: ButtonLinkProps) => {
    // const renderLink = React.forwardRef<any, Omit<RouterLinkProps, 'to'>>((itemProps, ref) => (
    //     <RouterLink to={props.to} ref={ref} {...itemProps} />
    // ));
    //
    // const {to, href, children, ...other} = props;
    return (
        <Button color={props.color} size={props.size} variant={props.variant} component={RouterLink}
                className={props.className} to={props.to}>
            {props.children}
        </Button>
        // <Button {...other} component={RouterLink} to={props.to}/>
    )
}
