import React, {Fragment, ReactElement} from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import {observer} from "mobx-react-lite";
import {CircularProgress, Divider, InputAdornment} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import BlurOnIcon from "@material-ui/icons/BlurOn";
import BlurOffIcon from "@material-ui/icons/BlurOff";
import {green} from "@material-ui/core/colors";
import TableContainer from "@material-ui/core/TableContainer";
import {RemoteSearchStore} from "../stores/RemoteStore";
import {ColumnBuilder, TableView} from "./TableView";
import {columnStyles} from "../styles/EntityLayoutStyles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: '2px 4px',
            display: 'flex',
            alignItems: 'center',
            marginBottom: theme.spacing(1),
        },
        input: {
            marginLeft: theme.spacing(1),
            flex: 1,
        },
        iconButton: {
            padding: 10,
        },
        divider: {
            height: 28,
            margin: 4,
        },
        wrapper: {
            margin: theme.spacing(1),
            position: 'relative',
        },
        buttonProgress: {
            color: green[500],
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 1,
        },
        spacer: {
            height: '4px',
        },
        empty: {
            textAlign: 'center',
        },
    }),
);

export interface SearchSupport {
    data: { query?: string, fuzzy: boolean, busy: boolean }
    setQuery: ((q: string) => void)
    search: (() => void)
    toggleFuzzy: (() => void)
    clear?: (() => void)
}

interface SearchFieldPropsBase {
    support: SearchSupport
    elevated?: boolean
    placeholder?: string
    autoFocus?: boolean
    allowFuzzy?: boolean
}

type SearchFieldProps = SearchFieldPropsBase & any;

export const SearchField = observer((props: SearchFieldProps) => {
    const classes = useStyles()
    const support: SearchSupport = props.support

    function queryChange(event: any) {
        support.setQuery(event.target.value)
    }

    function handleSubmit(event: any) {
        support.search()
        event.preventDefault()
    }

    const Clear = () => {
        return support.clear && (support.data.query || '').length > 0 ? (
            <Fragment>
                <Divider className={classes.divider} orientation="vertical"/>
                <IconButton className={classes.iconButton} aria-label="search"
                            onClick={() => support.clear && support.clear()}>
                    <CloseIcon/>
                </IconButton>
            </Fragment>
        ) : (<div/>)
    }

    const allowFuzzy = props.allowFuzzy === undefined || props.allowFuzzy
    const adornment = allowFuzzy ? (
        <InputAdornment position="end">
            <IconButton
                aria-label="fuzzy search"
                onClick={support.toggleFuzzy}
            >
                {support.data.fuzzy ? <BlurOnIcon/> : <BlurOffIcon/>}
            </IconButton>
        </InputAdornment>
    ) : undefined

    const elev = elevation(props)
    const placeholder = (props.placeholder || 'Search') + ' ...'
    return (
        <Paper component="form" className={props.className || classes.root} onSubmit={handleSubmit}
               elevation={elev} variant={elev ? "elevation" : "outlined"}>
            <InputBase
                className={classes.input}
                placeholder={placeholder}
                inputProps={{'aria-label': placeholder}}
                value={support.data.query}
                onChange={queryChange}
                endAdornment={adornment}
                autoFocus={props.autoFocus || false}
            />

            <div className={classes.wrapper}>
                <IconButton type="submit" className={classes.iconButton} aria-label="search" onClick={handleSubmit}
                            disabled={(support.data.query || '').length === 0}>
                    <SearchIcon/>
                </IconButton>
                {support.data.busy && <CircularProgress size={44} className={classes.buttonProgress}/>}
            </div>

            <Clear/>
        </Paper>
    )
})

const Spacer = () => {
    const classes = useStyles();
    return (
        <div className={classes.spacer}/>
    )
}

interface SearchProps extends SearchFieldPropsBase {
    columns: ColumnBuilder<any>
    store: RemoteSearchStore<any, any, any, any>
    keyGen: (val: any) => string | number | undefined
    onRowClick: (item: any, event?: any) => void
    secondaryControl?: ReactElement
}

const elevation = (props: { elevated?: boolean }) => {
    return props.elevated === undefined || props.elevated ? 1 : 0
}

export const Search = (props: SearchProps) => {
    const classes = columnStyles()
    const autoFocus = props.autoFocus === undefined || props.autoFocus
    return (
        <Fragment>
            <div className={classes.container}>
                <div className={classes.column}>
                    <SearchField autoFocus={autoFocus} {...props}/>
                </div>
                <div className={classes.col2x}>{props.secondaryControl || (<Fragment>&nbsp;</Fragment>)}</div>
            </div>
            <Spacer/>

            <TableContainer component={Paper} elevation={elevation(props)}>
                <TableView columns={props.columns} keyGen={props.keyGen} onRowClick={props.onRowClick}
                           values={props.store.values()} selection={props.store.selection}/>
            </TableContainer>
        </Fragment>
    )
}
