import React, {Fragment} from "react";
import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControlLabel
} from "@material-ui/core";
import {useStores} from "../contexts";
import {columnStyles} from "../styles/EntityLayoutStyles";
import {
    DropdownValueSupplier,
    EditableChip,
    EditableDate,
    EditableDropdown,
    EditableText,
    valueSupplierForType
} from "../components/editable";
import {observer} from "mobx-react-lite";
import CircularProgress from "@material-ui/core/CircularProgress";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {green} from "@material-ui/core/colors";
import {Product} from "idpet-api";
import {Dropdown} from "../components/editable/Dropdown";
import {DisableJSD} from "../components/Layout";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        buttonProgress: {
            color: green[500],
            position: "absolute",
            top: 132,
            left: 450,
            zIndex: 1,
        },
        paddedDivider: {
            marginBottom: theme.spacing(1),
        }
    }),
);

const PetDetail = observer(() => {
    const {create} = useStores().cremation
    const cremated = create.ctxCremated()
    const pet = create.ctxCremated().ctxPet()
    const classes = columnStyles()
    const otherClasses = useStyles();

    const lookupPet = (event: any) => {
        event.preventDefault();
        create.triggerPetSearch();
    }

    return (
        <Box className={classes.container}>
            {create.status === "BUSY" &&
            <CircularProgress size={60} className={otherClasses.buttonProgress}/>}
            <Box className={classes.column}>
                <EditableChip context={cremated} field={"serial"} autoFocus onBlur={lookupPet}/>
                <EditableText context={pet} field={"name"} title={"Name"} required/>
                <EditableText context={pet} field={"breed"} title={"Breed"}/>
            </Box>
            <Box className={classes.column}>
                <EditableDate title={"Deceased date"} context={cremated} field={"deceasedDate"} required/>
                <EditableDropdown context={pet} field={"species"}
                                  valueSupplier={valueSupplierForType("Species")} required/>
                <EditableText context={pet} field={"description"} title={"Colour"}/>
            </Box>
        </Box>
    )
})

const CasketSearch = observer(() => {
    const {create} = useStores().cremation
    const cremated = create.ctxCremated()
    const {search} = useStores().product

    const valueSupplier: DropdownValueSupplier<Product> = {
        defaultValue(): Product | undefined {
            return undefined
        }, equal(a, b): boolean {
            return a.id === b.id
        }, label(value): string {
            return value.name
        }, values(): Product[] {
            return search.caskets
        }
    }

    return (
        <Dropdown context={cremated} field={"casket"} valueSupplier={valueSupplier} required/>
    )
})

const CremationOptions = observer(() => {
    const classes = columnStyles()
    const {create} = useStores().cremation;
    const cremated = create.ctxCremated()

    return (
        <Fragment>
            <Box className={classes.container}>
                <Box className={classes.column}>
                    <EditableText context={cremated} field={"weight"} required/>
                </Box>

                <Box className={classes.column}>
                    <CasketSearch/>
                </Box>
            </Box>

            <Box className={classes.container}>
                <Box className={classes.col2x}>
                    <EditableText context={cremated} field={"specialRequest"} title={"Special Requests?"}/>
                </Box>
                {/*
                    <TextField
                        id="special-request"
                        label="Special Requests"
                        multiline
                        rows={4}
                        fullWidth
                        variant="filled"
                    />
*/}
            </Box>
        </Fragment>
    )
})

export const CremationPetDialog = observer(() => {
    const classes = columnStyles()
    const local = useStyles()
    const {create} = useStores().cremation;
    return (
        <Dialog onClose={create.cancel}
                open={create.isEditingCremated}
                fullWidth={true}
                maxWidth={'md'}
        ><DisableJSD>
            <DialogTitle>Pet Details</DialogTitle>
            <DialogContent>
                {create.isEditingCremated && (
                    <div>
                        <PetDetail/>

                        <Box className={classes.container}>
                            <Box className={classes.col2x}>
                                <Divider className={local.paddedDivider}/>
                                <FormControlLabel control={
                                    <Checkbox checked={create.currentEdit?.roa} inputProps={{'aria-label': 'checkbox'}}
                                              onChange={create.toggleROA} color="primary"/>}
                                                  label="With return of ashes?"/>
                            </Box>
                        </Box>

                        {create.currentEdit?.roa && (<CremationOptions/>)}
                    </div>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={create.cancel} variant={"text"}>Cancel</Button>
                <Button onClick={create.saveCremated} variant={"contained"} color={"primary"}
                        disabled={!create.isCrematedValid}>Save</Button>
            </DialogActions>
        </DisableJSD></Dialog>
    )
})
