import {RemoteItemStore, RemoteSearchStore, RequestParams} from "./RemoteStore";
import {Api, Chip, Note, PagedChips} from "idpet-api";
import {RootStore} from "./RootStore";
import {NoteStore} from "./index";
import {computed} from "mobx";

export class ChipStore extends RemoteItemStore<Api, RootStore, Chip> implements NoteStore {
    protected _find(api: Api, id: any, params: RequestParams): Promise<Chip> {
        return api.chips.getChipById(id, {expandos: 'order.recipient.party,implanter,pet.chips'}, params);
    }

    addNote = (note: Note) => {
        const id = this.idOrThrow
        this.invokeOneAndReload((api, params) => api.chips.addChipNote(id, note, {expandos: ''}, params))
    }

    @computed get notes() {
        return this.item?.pet?.notes
    }
}

export class ChipSearchStore extends RemoteSearchStore<Api, RootStore, PagedChips, Chip> {
    protected _search(api: Api, data: any, params: RequestParams): Promise<PagedChips> {
        return api.chips.listChips({
            ...data,
            expandos: this.expandos('implanter,pet,dispatch,order.recipient.party')
        }, params);
    }

    protected _values(): Chip[] | undefined {
        return this.paged?.values;
    }
}
