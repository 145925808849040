import {RemoteItemStore} from "./RemoteStore";
import {Api, Party, PartyRole} from "idpet-api";
import {RootStore} from "./RootStore";
import {HasPartyAnimalsStore, PartyAnimalsStore} from "./linked/party_animals";
import {action, computed, observable} from "mobx";
import {PartyRoleType} from "../utils";
import {newRoleOfType} from "../utils/builder";
import {newRolesToggle} from "../utils/actions";

export abstract class PartyStore<T extends Party> extends RemoteItemStore<Api, RootStore, T>
    implements HasPartyAnimalsStore {

    protected readonly animalStore: PartyAnimalsStore = new PartyAnimalsStore(this.rootStore)
    @observable showRoleToggle: boolean = false
    @observable roleSelection: PartyRole[] = []
    @observable adjustableRoleTypes: PartyRoleType[] = []

    partyAnimalsStore(): PartyAnimalsStore {
        return this.animalStore
    }

    @computed get party(): T | undefined {
        return this.item
    }

    @action cancelRoleToggle = () => {
        this.showRoleToggle = false
    }

    @action openRoleToggle = () => {
        this.showRoleToggle = true
        this.resetSelection()
    }

    @action resetSelection = () => {
        // Reset role selections
        this.roleSelection.length = 0
        if (this.party && this.party.roles) {
            // Only push roles that we manage...
            // @ts-ignore
            const roles = this.party.roles.filter(role => this.adjustableRoleTypes.indexOf(role.type.name) >= 0)
            this.roleSelection.push(...roles)
        }
    }

    hasRole = (type: PartyRoleType): boolean => {
        return this.roleSelection.filter(role => role.type.name === type).length > 0
    }

    isChanged = (type: PartyRoleType): boolean => {
        // Is it in the party roles?
        return this.isRoleOfTypeInParty(type) !== this.hasRole(type)
    }

    @computed get canSave() {
        return this.adjustableRoleTypes.filter(type => this.isChanged(type)).length > 0
    }

    @action toggleRole = (type: PartyRoleType, enabled: boolean) => {
        if (enabled && !this.hasRole(type)) {
            // Create the role
            this.roleSelection.push(newRoleOfType(type))
        } else {
            // Remove the role(s) - this _leaves in_ all roles that don't match
            this.roleSelection = this.roleSelection.filter(role => role.type.name !== type)
        }
    }

    @action saveRoleUpdates = () => {
        const enable = this.roleSelection.filter(role => !this.isRoleInParty(role))
            .filter(role => this.isSelected(role))

        // @ts-ignore
        const disable = this.party?.roles?.filter(role => this.isAdjustable(role))
            .filter(role => !this.isSelected(role))

        const data = newRolesToggle(enable, disable)
        this.invokeOne((api, params) =>
            api.parties.initiatePartyAction(this.idOrThrow, data, {}, params), () => [this.cancelRoleToggle(), this.reload()])
    }

    @action setAdjustableRoles = (roleTypes: PartyRoleType[]) => {
        this.adjustableRoleTypes = roleTypes
    }

    private isAdjustable(role: PartyRole): boolean {
        return this.adjustableRoleTypes.filter(roleType => role.type.name === roleType).length > 0
    }

    private isSelected(role: PartyRole): boolean {
        return this.roleSelection.filter(aRole => aRole.type.name === role.type.name).length > 0
    }

    private isRoleInParty(role: PartyRole): boolean {
        // @ts-ignore
        return this.isRoleOfTypeInParty(role.type.name)
    }

    private isRoleOfTypeInParty(type: PartyRoleType): boolean {
        return (this.party?.roles?.filter(role => role.type.name === type).length || 0) > 0
    }
}
