import {RootStore} from "./RootStore";
import {PropTypes} from "@material-ui/core";
import {action, observable} from "mobx";
import {AbstractStore} from "./RemoteStore";
import {Api} from "idpet-api";
import {ReactElement} from "react";
import {PromptWidgetProps} from "../dialogs/AlertDialogs";

export interface AlertButton {
    title: string
    action: ((store: AlertStore) => void)
    color?: PropTypes.Color
    autoFocus?: boolean
    variant?: 'text' | 'outlined' | 'contained';
}

export interface Prompt<T> {
    label: string
    defaultValue?: T
    widget?: (store: AlertStore) => ReactElement<PromptWidgetProps>
}

export interface Alert {
    title?: string
    prompt?: Prompt<any>
    content: string | string[]
    buttons: AlertButton[]
}

export class AlertStore extends AbstractStore<Api, RootStore> {

    @observable open: boolean = false
    @observable alert?: Alert
    @observable prompt?: any

    @action show = (alert: Alert) => {
        this.alert = alert
        if (this.alert.prompt)
            this.prompt = this.alert.prompt.defaultValue

        this.open = true
    }

    @action hide = () => {
        this.open = false
    }

    @action setPrompt = (value: any) => {
        this.prompt = value
    }
}
