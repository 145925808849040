import {Box, Divider} from "@material-ui/core";
import React, {Fragment} from "react";
import idpet from "../assets/idpet-logo.png";
import legacy from "../assets/legacy-logo.png";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";

export const useClasses = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            justifyContent: 'space-around',
            alignContent: 'center',
            width: '100%',
            marginTop: theme.spacing(-2),
            marginBottom: theme.spacing(-2),
        },
    }),
);

const Part = (props: { src: string, alt?: string, size?: string }) => {
    return (
        <Box display={'flex'} justifyContent={'center'} alignContent={'center'} width={'40%'}>
            <Box width={props.size || '100%'} margin={'auto'}>
                <img src={props.src} alt={props.alt || 'Logo'} width={'100%'} height={'auto'}/>
            </Box>
        </Box>
    )
}

export const Logo = () => {
    const classes = useClasses();
    return (
        <Fragment>
            <Box className={classes.container}>
                <Part src={idpet} alt={'Identipet Logo'}/>
                <Part src={legacy} alt={'Legacy Logo'} size={'50%'}/>
            </Box>
            <Divider/>
        </Fragment>
    )
}
