import {
    FieldValidationError,
    Patch,
    RemoteCreateStore,
    RemoteItemStore,
    RemoteSearchStore,
    RequestParams
} from "./RemoteStore";
import {Api, Chip, Note, Org, PagedPets, Person, Pet} from "idpet-api";
import {newChip, newError, newPerson, newPet, newPetOwner, TypeType} from "../utils/builder";
import {clipArrays, isPersonValid, isPetValid} from "../utils";
import {action, computed, observable, reaction} from "mobx";
import {RootStore} from "./RootStore";
import {ChipEditContext, PersonEditContext} from "../utils/editContexts";
import {tap} from 'rxjs/operators';
import {NoteStore} from "./index";
import {markAlive, markDeceased, requestCertificate, updateChippedBy} from "../utils/actions";
import {ChipSearchStore} from "./ChipStores";
import {PersonSearchStore} from "./PersonStores";
import {Observable} from "rxjs";
import {yesNoAlert} from "../dialogs/AlertDialogs";
import {OrgSearchStore} from "./OrgStores";

export class PetStore extends RemoteItemStore<Api, RootStore, Pet> implements NoteStore {
    @observable orgSearch: OrgSearchStore
    @observable chippedByDialogVisible: boolean = false

    @observable implantingOrg?: Org
    @observable implantingVet: string = ''

    constructor(rootStore: RootStore) {
        super(rootStore);
        this.orgSearch = new OrgSearchStore(rootStore, true, false)
    }

    protected _find(api: Api, id: any, params: RequestParams): Promise<Pet> {
        return api.pets.getPetById(id, {
            expandos: this.expandos([
                'chips.implanter',
                'events.profile',
                'images',
                'interactions',
                'notes',
                'parties.party.roles',
                'recoveries',
                'sos',
            ])
        }, params)
    }

    protected _save(api: Api, id: string, patch: Patch, params: RequestParams): Promise<Pet> {
        return api.pets.updatePetById(id, this.clip(patch), undefined, params)
    }

    protected _delete(api: Api, id: string, params: RequestParams): Promise<void> {
        return api.pets.deletePetById(id, undefined, params)
    }

    private clip = (patch: Patch): Pet => {
        // Clip off unnecessary stuff from the pet
        const pet: Pet = clipArrays(patch.data, ["chips"])
        pet.recoveries = undefined

        return pet
    }

    setOrgQuery = (query: string) => {
        this.orgSearch.setQuery(query)
    }

    @action setImplantingOrg = (org: Org) => {
        this.implantingOrg = org
    }

    @action setImplantingVet = (name: string) => {
        this.implantingVet = name
    }

    addNote = (note: Note) => {
        const id = this.idOrThrow
        this.invokeOneAndReload((api, params) =>
            api.pets.addPetNote(id, note, {expandos: ''}, params))
    }

    @computed get notes() {
        return this.item?.notes
    }

    requestCertificate = (email: string) => {
        const action = requestCertificate(email)
        this.invokeOne((api, params) =>
                api.pets.initiatePetAction(this.idOrThrow, action, {}, params),
            () => this.showMessage('Certificate should arrive shortly.'))
    }

    requestCertificateDownload = (onSuccess: (link: string) => void) => {
        const action = requestCertificate('direct:')
        this.invokeOne((api, params) =>
                api.pets.initiatePetAction(this.idOrThrow, action, {}, params),
            val => {
                let link = val.extraInfo?.['link']
                if (link) {
                    onSuccess(link)
                } else {
                    this.showError('Did not receive link from server. Please try later.')
                }
            }
        )
    }

    @action openChippedByDialog = () => {
        this.orgSearch.clear()
        this.chippedByDialogVisible = true

        this.implantingOrg = this.chip?.implanter
        this.implantingVet = this.chip?.implantedBy || ''
    }

    @action hideChippedByDialog = () => {
        this.chippedByDialogVisible = false
    }

    @computed get readyForChippedByUpdate(): boolean {
        return this.implantingVet.length > 0
    }

    @computed get chip(): Chip | undefined {
        return this.item?.chips?.find(() => true)
    }

    updateChippedBy = () => {
        const org = this.rootStore.config.admin ? this.implantingOrg : undefined
        const action = updateChippedBy(this.implantingVet, org)
        this.invokeOneAndReload((api, params) =>
                api.pets.initiatePetAction(this.idOrThrow, action, {}, params),
            () => {
                this.hideChippedByDialog()
                this.showMessage('Action completed.')
            })
    }

    markDeceased = () => {
        const action = markDeceased()
        const id = this.idOrThrow
        this.invokeOneAndReload((api, params) => api.pets.initiatePetAction(id, action, undefined, params),
            () => this.showMessage('Action completed.'))
    }

    markAlive = () => {
        const action = markAlive()
        const id = this.idOrThrow
        this.invokeOneAndReload((api, params) => api.pets.initiatePetAction(id, action, undefined, params),
            () => this.showMessage('Action completed.'))
    }

    ctxChip(): ChipEditContext {
        return this.nestedContext(new ChipEditContext(`pet-${this.id}-chip`, {
            editing: () => this.editing,
            item: () => this.item?.chips?.[0]
        }))
    }

    protected _validateEntity(): FieldValidationError | FieldValidationError[] | undefined {
        return this.simpleValidation(isPetValid(this.item))
    }
}

export class PetCreateStore extends RemoteCreateStore<Api, RootStore, Pet> {
    @observable chipSearch: ChipSearchStore
    @observable personSearch: PersonSearchStore
    @observable thirdParty: boolean = false
    @observable newPerson: boolean = false

    @observable fixedOwner?: boolean
    @observable owner?: Person
    @observable emailRequired: boolean = true

    constructor(rootStore: RootStore) {
        super(rootStore);
        this.chipSearch = new ChipSearchStore(rootStore, false, false)
        this.personSearch = new PersonSearchStore(rootStore, false, false, 'addresses')

        const _3rdPartyMessage: string[] = [' Identipet provides this registration page for the primary purpose of ' +
        'capturing owner and pet information for chips it has supplied to vets, animal welfare and other ' +
        'trusted parties. We do understand that the Identipet database has a great reputation, and that ' +
        'you may wish to register a non Identipet chip on our system.',
            'If you are certain that the chip number above is correct, click "Yes" to continue. Alternatively ' +
            'click "No" and correct the number, or contact the Identipet office for more assistance. '
        ]

        reaction(
            () => this.chipSearch.status,
            (value) => {
                if (value === "BUSY") {
                    this.setThirdParty(false)
                } else if (!this.chip) {
                    this.rootStore.alert.show(yesNoAlert(_3rdPartyMessage, result => {
                        this.setThirdParty(result === "yes")
                    }, 'Register Non Identipet Chip?'))
                }
            }
        )

        reaction(
            () => this.personSearch.status,
            (value) => value === "BUSY" && this.clearOwner()
        )
    }

    protected _create(): Pet {
        this.chipSearch.clear()
        this.personSearch.clear()
        this.thirdParty = false
        this.emailRequired = true
        this.fixedOwner = undefined
        this.clearOwner()

        // TODO perhaps make the builder aware of the store???
        return newPet('', this.def("Species"), this.def("Sex"), this.def("Sterilized"))
    }

    protected _save(api: Api, item: Pet, params: RequestParams): Promise<Pet> {
        if (!this.owner)
            throw newError('Should not get here')

        const products = this.rootStore.product.search
        const good = this.thirdParty ? products.thirdPartyChip : products.idpetChip

        item.parties = [newPetOwner(this.owner)]
        const implantDate = item.chips?.[0]?.implantDate
        item.chips = [this.chip || newChip(good, this.chipSearch.query)]
        item.chips[0].implantDate = implantDate
        return api.pets.addPet(item, {expandos: this.expandos(['parties.party'])}, params).then(val => {
            this.reset()
            return val
        })
    }

    protected _initialize(): Observable<any> {
        return super._initialize().pipe(tap(() => {
            // Wait until the types are initialized
            this.rootStore.types.initialize().then(() => this.reset(), err => {
            })
        }))
    }

    @action toggleEmailRequired = () => {
        this.emailRequired = !this.emailRequired
    }

    @action load = (personId: string) => {
        this.invokeOne((api, params) => api.people.getPersonById(personId, {
            expandos: this.expandos(['addresses', 'contacts'])
        }, params), val => this.setOwner(val))
    }

    @action resetOrReload = () => {
        if (this.fixedOwner && this.owner?.id) {
            this.load(this.owner?.id)
        } else {
            this.reset()
        }
    }

    @action
    private setOwner = (val: Person) => {
        this.reset()
        this.owner = val
        this.fixedOwner = true
    }

    @action selectOwner = (val: Person) => {
        this.owner = val
        this.fixedOwner = false
        this.newPerson = false
    }

    @action clearOwner = () => {
        this.owner = undefined
        this.newPerson = false
    }

    @action setThirdParty = (val: boolean) => {
        this.thirdParty = val
    }

    @action setUseNewPerson = (val: boolean) => {
        this.newPerson = val
        if (val) {
            this.buildNewPerson()
        } else {
            this.owner = undefined
        }
    }

    @computed get isSelectionValid(): boolean {
        return this.isChipValid && (this.owner?.id !== undefined || this.newPerson)
    }

    @computed get chip() {
        return this.chipSearch.values()?.[0]
    }

    @computed get isChipValid(): boolean {
        return this.isChipQueryValid
            && this.chip?.pet === undefined
            && (this.chip !== undefined || this.thirdParty)
    }

    @computed get isChipQueryValid(): boolean {
        // We need to have searched, and the query must be sync'ed with the backend result
        return this.chipSearch.query.length > 0 && this.chipSearch.isInSync
    }

    @computed get isPetValid(): boolean {
        return isPetValid(this.item)
    }

    @computed get isPersonValid(): boolean {
        return isPersonValid(this.owner, this.emailRequired)
    }

    ctxOwner(): PersonEditContext {
        return this.nestedContext(new PersonEditContext('owner', {
            editing: () => this.editing,
            item: () => this.owner,
            buildItem: this.buildNewPerson
        }))
    }

    ctxChip(): ChipEditContext {
        const products = this.rootStore.product.search
        const good = this.thirdParty ? products.thirdPartyChip : products.idpetChip

        return this.nestedContext(new ChipEditContext(`pet-chip`, {
            editing: () => this.editing,
            item: () => this.item?.chips?.[0],
            buildItem: () => this.item.chips = [newChip(good, '')]
        }))
    }

    @action private buildNewPerson = () => {
        this.owner = newPerson(this.def("Title"), '', '')
    }

    protected _validateEntity(): FieldValidationError | FieldValidationError[] | undefined {
        return this.simpleValidation(isPetValid(this.item))
    }

    private def = (type: TypeType) => this.rootStore.types.defaultValue(type)
}

export class PetSearchStore extends RemoteSearchStore<Api, RootStore, PagedPets, Pet> {
    protected _search(api: Api, data: any, params: RequestParams): Promise<PagedPets> {
        return api.pets.listPets({...data, expandos: this.expandos('chips')}, params)
    }

    protected _values(): Pet[] | undefined {
        return this.paged?.values
    }
}
