import React, {Fragment} from "react";
import {observer} from "mobx-react-lite";
import {HasChildren, HasTitle} from "../utils/props";
import {asArray, isEmptyString} from "../utils";
import {TextOrReactRender} from "./TextOrReactRender";
import {typographyStyles} from "../styles/AppScheme";
import {Box} from "@material-ui/core";

type DatumViewProps = HasTitle & HasChildren & { link?: any };

export const DatumView = observer((props: DatumViewProps) => {
    const classes = typographyStyles();

    if (props.children === undefined
        || (Array.isArray(props.children) && props.children.length === 0)
        || (isEmptyString(props.children))) {
        return (<Fragment/>)
    }

    return (
        <div style={{position: "relative"}}>
            <TextOrReactRender gutterBottom variant="overline" className={classes.title} value={props.title}/>

            {asArray(props.children).map((child, idx) => (
                <Fragment>
                    {props.link && idx === 0 ? (
                        <Box display={'flex'} >
                            <TextOrReactRender gutterBottom variant="body1" className={classes.item} value={child}/>
                            {props.link}
                        </Box>
                    ) : (
                        <TextOrReactRender gutterBottom variant="body1" className={classes.item} value={child}/>
                    )}
                </Fragment>
            ))}
        </div>
    )
})
