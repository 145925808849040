import React, {Suspense} from 'react';
import './App.css';

import {Route, Router, Switch} from "react-router-dom";
import {useAuth0, withAuthenticationRequired} from '@auth0/auth0-react';
import history from "./utils/history";
import Loading from "./components/Loading";
import {PermanentDrawerLeft} from "./components/Layout";
import {useStores} from "./contexts";
import {observer} from "mobx-react-lite";
import {alwaysTrue} from "./utils";
import {
    AmlaView,
    ChipSearchView,
    ChipView,
    CremationCreateView,
    CremationView,
    DashboardView,
    Home,
    NotFound,
    NotImplemented,
    OrderCreateView,
    OrderSearchView,
    OrderView,
    OrgCreateView,
    OrgSearchView,
    OrgView,
    PersonMergeView,
    PersonSearchView,
    PersonView,
    PetCreateView,
    PetRecoveryView,
    PetSearchView,
    PetSosCreateView,
    PetSosGalleryView,
    PetSosView,
    PetTransferView,
    PetView,
    PrnStatsView,
    ProfileView,
    ShipmentCreateView,
    ShipmentView,
    TaskProcessView
} from "./utils/lazyRoutes";
import {Typography} from "@material-ui/core";
import {CremationsReportView, CustomReportView, OrdersReportView} from "./components/reports";
// @ts-ignore
import PageVisibility from 'react-page-visibility';
import ReactGA from 'react-ga'
import {Helmet} from "react-helmet";
import {cremationsStore} from "./stores/reports/cremations";
import {theConstants} from "./constants";
import {RootStore, theRootStore} from "./stores/RootStore";
import {ordersStore} from "./stores/reports/orders";
import {LoadError} from "./dialogs/LoadErrorDialog";
import {DEFAULT_DISMISS_TIMEOUT} from "./stores/RemoteStore";

type Loader = (location: Location, state: any) => boolean

function extractIdAndTrigger(rx: RegExp, val: string, trigger: (id: string) => any) {
    const id = rx.exec(val)?.[1];
    if (!id) {
        return false
    }

    try {
        trigger(id)
    } catch (e) {
        theRootStore.onMessage("ERROR", e)
    }

    return true
}

function extractAndTrigger(rx: RegExp, val: string, trigger: (arr: string[]) => any) {
    try {
        let parts: string[] | null = rx.exec(val)
        if (!parts)
            return false

        trigger(parts.slice(1))
    } catch (e) {
        theRootStore.onMessage("ERROR", e)
    }

    return true
}

const PageLoading = () => {
    return (
        <Typography variant="overline" display="block" gutterBottom>
            Loading page...
        </Typography>
    )
}

// @ts-ignore
const PrivateRoute = ({component, ...args}) => (
    <Route component={withAuthenticationRequired(component)} {...args} />
)

const ShowLoadingOrApp = observer(() => {
    const {isLoading, user} = useAuth0()
    const stores = useStores()

    if (isLoading || (user && stores.loading === "LOADING")) {
        return <Loading/>
    } else if (stores.loading === "ERROR") {
        return <LoadError/>
    }

    return (
        <Router history={history}>
            <Helmet>
                <title>Identipet [Management Application]</title>
            </Helmet>

            <PermanentDrawerLeft>
                <Suspense fallback={<PageLoading/>}>
                    <Switch>
                        <Route path="/" exact component={Home}/>
                        <Route path="/network/stats" exact component={PrnStatsView}/>
                        <PrivateRoute path="/amla" component={AmlaView}/>
                        <PrivateRoute path="/chips/:id" component={ChipView}/>
                        <PrivateRoute path="/chips" component={ChipSearchView}/>
                        <PrivateRoute path="/cremations/create" component={CremationCreateView}/>
                        <PrivateRoute path="/cremations/:id" component={CremationView}/>
                        <PrivateRoute path="/dashboard" component={DashboardView}/>
                        <PrivateRoute path="/orders/:id/shipments/create" component={ShipmentCreateView}/>
                        <PrivateRoute path="/orders/:id" component={OrderView}/>
                        <PrivateRoute path="/orders" component={OrderSearchView}/>
                        <PrivateRoute path="/orgs/create" component={OrgCreateView}/>
                        <PrivateRoute path="/orgs/:id/orders/create" component={OrderCreateView}/>
                        <PrivateRoute path="/orgs/:id" component={OrgView}/>
                        <PrivateRoute path="/orgs" component={OrgSearchView}/>
                        <PrivateRoute path="/payments/complete" component={DashboardView}/>
                        <PrivateRoute path="/people/create" component={NotImplemented}/>
                        <PrivateRoute path="/people/merge" component={PersonMergeView}/>
                        <PrivateRoute path="/people/:id/pets/create" component={PetCreateView}/>
                        <PrivateRoute path="/people/:id" component={PersonView}/>
                        <PrivateRoute path="/people" component={PersonSearchView}/>
                        <PrivateRoute path="/pets/create" component={PetCreateView}/>
                        <PrivateRoute path="/pets/:id/transfer" component={PetTransferView}/>
                        <PrivateRoute path="/pets/:id/sos" component={PetSosCreateView}/>
                        <PrivateRoute path="/pets/:id" component={PetView}/>
                        <PrivateRoute path="/pets" component={PetSearchView}/>
                        <PrivateRoute path="/profile" component={ProfileView}/>
                        <PrivateRoute path="/recoveries/:id" component={PetRecoveryView}/>
                        <PrivateRoute path="/reports/i/custom" component={CustomReportView}/>
                        <PrivateRoute path="/reports/i/customer_chips" component={NotImplemented}/>
                        <PrivateRoute path="/reports/i/owner_pets" component={NotImplemented}/>
                        <PrivateRoute path="/reports/l/pending_cremations" component={CremationsReportView}/>
                        <PrivateRoute path="/reports/l/recent_cremations" component={CremationsReportView}/>
                        <PrivateRoute path="/reports/l/open_orders" component={OrdersReportView}/>
                        <PrivateRoute path="/reports/l/closed_orders" component={OrdersReportView}/>
                        <PrivateRoute path="/sos/gallery" component={PetSosGalleryView}/>
                        <PrivateRoute path="/sos/gallery/all" component={PetSosGalleryView}/>
                        <PrivateRoute path="/sos/:id" component={PetSosView}/>
                        <PrivateRoute path="/shipments/:id" component={ShipmentView}/>
                        <PrivateRoute path="/tasks/:id" component={NotImplemented}/>
                        <PrivateRoute path="/tasks" component={TaskProcessView}/>
                        <Route component={NotFound}/>
                    </Switch>
                </Suspense>
            </PermanentDrawerLeft>
        </Router>
    )
})

function handlePaymentCompletion(stores: RootStore, arr: string[]) {
    switch (arr[1]) {
        case 'approved':
            stores.onMessage("INFO",
                `Your payment via ${arr[0]} was approved. Your subscription should now be enabled.`, DEFAULT_DISMISS_TIMEOUT)
            break
        case 'declined':
            stores.onMessage("ERROR", `Your payment via ${arr[0]} was declined.`)
            break
        case 'cancelled':
            stores.onMessage("ERROR", `Your payment via ${arr[0]} was cancelled.`)
            break
        case 'other':
        default:
            stores.onMessage("ERROR", `There was an unknown problem with your payment via ${arr[0]}. (${arr[1]})`)
            break
    }

    stores.dashboard.load('')
    // setTimeout(() => stores.history.push('/dashboard'), 2000)
}

const App = () => {
    const stores = useStores();
    const {isLoading} = useAuth0();

    // TODO the stores should be responsible for their own.... I think....
    const loaders: Loader[] = [
        (location: Location, state: any) => {
            return extractIdAndTrigger(/chips\/(\w+)/, location.pathname, stores.chip.edit.load)
        },
        (location: Location, state: any) => {
            return /cremations\/create(\/)?/.test(location.pathname)
        },
        (location: Location, state: any) => {
            return extractIdAndTrigger(/cremations\/(\w+)/, location.pathname, stores.cremation.edit.load)
        },
        (location: Location, state: any) => {
            return location.pathname === '/dashboard' ? alwaysTrue(() => stores.dashboard.load('')) : false
        },
        (location: Location, state: any) => {
            return extractIdAndTrigger(/orders\/(\d+)\/shipments\/create/, location.pathname, stores.shipment.create.load)
        },
        (location: Location, state: any) => {
            return extractIdAndTrigger(/orders\/(\d+)/, location.pathname, stores.order.edit.load)
        },
        (location: Location, state: any) => {
            return extractIdAndTrigger(/orgs\/([\w-]+)\/orders\/create/, location.pathname, stores.order.create.load)
        },
        (location: Location, state: any) => {
            return /orgs\/create(\/)?/.test(location.pathname)
        },
        (location: Location, state: any) => {
            return extractIdAndTrigger(/orgs\/([\w-]+)/, location.pathname, stores.org.edit.load)
        },
        (location: Location, state: any) => {
            return extractAndTrigger(/payments\/complete\/(\w+)\/(\w+)/, location.pathname, arr => handlePaymentCompletion(stores, arr))
        },
        (location: Location, state: any) => {
            return /people\/(create|merge)(\/)?/.test(location.pathname)
        },
        (location: Location, state: any) => {
            return extractIdAndTrigger(/people\/([\w-]+)\/pets\/create/, location.pathname, stores.pet.create.load)
        },
        (location: Location, state: any) => {
            return extractIdAndTrigger(/people\/([\w-]+)/, location.pathname, stores.person.edit.load)
        },
        (location: Location, state: any) => {
            return /pets\/create(\/)?/.test(location.pathname)
        },
        (location: Location, state: any) => {
            return extractIdAndTrigger(/pets\/([\w-]+)\/transfer/, location.pathname, stores.pet.transfer.load)
        },
        (location: Location, state: any) => {
            return extractIdAndTrigger(/pets\/([\w-]+)\/sos/, location.pathname, stores.pet.sos.create.load)
        },
        (location: Location, state: any) => {
            return extractIdAndTrigger(/pets\/([\w-]+)/, location.pathname, stores.pet.edit.load)
        },
        (location: Location, state: any) => {
            return extractIdAndTrigger(/recoveries\/([A-Za-z0-9-_=]+)/, location.pathname, stores.pet.recovery.edit.load)
        },
        (location: Location, state: any) => {
            return location.pathname === '/reports/l/pending_cremations' ? alwaysTrue(() => cremationsStore.pending()) : false
        },
        (location: Location, state: any) => {
            return location.pathname === '/reports/l/recent_cremations' ? alwaysTrue(() => cremationsStore.recents()) : false
        },
        (location: Location, state: any) => {
            return location.pathname === '/reports/l/open_orders' ? alwaysTrue(() => ordersStore.open()) : false
        },
        (location: Location, state: any) => {
            return location.pathname === '/reports/l/closed_orders' ? alwaysTrue(() => ordersStore.closed()) : false
        },
        (location: Location, state: any) => {
            return location.pathname === '/sos/gallery/all' ? alwaysTrue(() => stores.pet.sos.search.searchAll()) : false
        },
        (location: Location, state: any) => {
            return location.pathname === '/sos/gallery' ? alwaysTrue(() => stores.pet.sos.search.searchNearSelf()) : false
        },
        (location: Location, state: any) => {
            return extractIdAndTrigger(/sos\/([A-Za-z0-9-_=]+)/, location.pathname, stores.pet.sos.edit.load)
        },
        (location: Location, state: any) => {
            return extractIdAndTrigger(/shipments\/(\w+)/, location.pathname, stores.shipment.edit.load)
        },
        (location: Location, state: any) => {
            return location.pathname === '/tasks' ? alwaysTrue(stores.task.refresh) : false
        },
        (location: Location, state: any) => {
            return extractIdAndTrigger(/tasks\/(\w+)/, location.pathname, () => {
            })
        },
    ];

    history.listen((location, action) => {
        stores.recents.hide()
        load(location, location.state)

        if (theConstants.gaId().length > 0) {
            try {
                const uri = location.pathname + location.search
                ReactGA.set({page: uri})
                ReactGA.pageview(uri)
            } catch (e) {
                console.log('GA send failed', e)
            }
        }
    });
    load(history.location, history.location.state);

    // Allow the root store to manipulate history
    stores.history = history

    function load(location: any, state: any) {
        if (isLoading)
            return

        for (let i = 0; i < loaders.length; i++) {
            if (loaders[i](location, state)) {
                break;
            }
        }
    }

    return (
        <PageVisibility onChange={(val: boolean) => stores.setVisibility(val)}>
            <ShowLoadingOrApp/>
        </PageVisibility>
    )
}

export default App
