import {observer} from "mobx-react-lite";
import {Box, TextField, Tooltip, Typography} from "@material-ui/core";
import React, {Fragment} from "react";
import {Address as ApiAddress} from "idpet-api";
import {DatumView} from "../DatumView";
import {columnStyles} from "../../styles/EntityLayoutStyles";
import {_title, EditableProps, styles} from "../editable";
import {reaction} from "mobx";
import {typographyStyles} from "../../styles/AppScheme";
import LocationOnIcon from '@material-ui/icons/LocationOn';

const keys: (keyof ApiAddress)[] = ["line1", "line2", "line3", "line4", "code"]

const FieldEdit = observer((props: EditableProps<ApiAddress> & { idx: number, aKey: keyof ApiAddress }) => {
    const classes = styles();
    const checkValue = (event: any, key: keyof ApiAddress) => {
        console.log(">> ", props.context.isValid(key))
    }

    const idx = props.idx
    const key = props.aKey
    return (
        <TextField label={idx > 0 || !props.title ? _title(key) : `Line 1 - ${props.title}`}
                   variant="filled"
                   required={idx === 0 && props.required}
                   value={props.context.getValue(key)}
                   onChange={event => props.context.setValue(key, event.target.value)}
                   className={classes.item}
                   autoFocus={idx === 0 && props.autoFocus}
                   onBlur={event => checkValue(event, key)}
        />
    )
})

const CompactEdit = (props: EditableProps<ApiAddress>) => {
    const classes = columnStyles();

    return (
        <Fragment>
            <FieldEdit {...props} idx={0} aKey={"line1"}/>
            <FieldEdit {...props} idx={1} aKey={"line2"}/>
            <Box className={classes.container}>
                <Box className={classes.col2x}>
                    <FieldEdit {...props} idx={2} aKey={"line3"}/>
                </Box>
                <Box className={classes.column}>
                    <FieldEdit {...props} idx={3} aKey={"code"}/>
                </Box>
            </Box>
        </Fragment>
    )
}

const FullSizeEdit = (props: EditableProps<ApiAddress>) => {
    return (
        <Fragment>
            {keys.map((key, idx) => (
                <FieldEdit {...props} idx={idx} aKey={key}/>
            ))}
        </Fragment>
    )
}

export const Address = observer((props: EditableProps<ApiAddress>) => {
    const classes = typographyStyles()
    let ctx = props.context;
    const line4 = ctx.getValue("line4")
    const compact = (props.compact !== undefined && props.compact) ||
        (line4 === undefined || line4.trim().length === 0)

    reaction(() => ctx.editing,
        editing => {
            // This control doesn't geocode, so set to be null
            if (editing) {
                ctx.setValue('placeId', null)
                ctx.setValue('lat', null)
                ctx.setValue('lng', null)
            }
        })

    const title = (
        <div style={{position: "relative"}}>
            <Typography gutterBottom variant={"overline"} className={classes.title} display={"inline"}>
                {props.title}
            </Typography>

            {ctx.getValue("placeId") && (
                <Tooltip title="Geocoded address">
                    <LocationOnIcon style={{position: "absolute"}} color={"primary"}/>
                </Tooltip>
            )}
        </div>
    )

    return (
        <div>
            {ctx.editing
                ? compact ? (<CompactEdit {...props}/>) : (<FullSizeEdit {...props}/>)
                : (
                    <Fragment>
                        <DatumView title={title} children={keys.map(key => ctx.getValue(key) || '')}/>
                    </Fragment>
                )}
        </div>
    )
})
