import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import React from "react";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {Box, Typography} from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import {observer} from "mobx-react-lite";
import {SelectionModel} from "../utils/selection";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        table: {
            minWidth: 650,
        },
        hover: {
            "&:hover": {
                cursor: 'pointer',
            },
        },
    }),
);

export interface ColumnDefinition<T> {
    name: string
    getValue: ((arg0: T) => string | React.ReactElement)
}

export abstract class ColumnBuilder<T> {
    available: ColumnDefinition<T>[] = []

    add = (name: string, getValue: ((arg0: T) => string | React.ReactElement)) => {
        this.available.push({name, getValue})
    }

    used = () => {
        return [...this.available]
    }
}

interface TableProps {
    columns: ColumnBuilder<any>
    keyGen: (val: any) => string | number | undefined
    onRowClick?: (item: any, event?: any) => void
    values: any[] | undefined
    selection?: SelectionModel<any>
}

const TableRowContent = observer((props: TableProps) => {
    const classes = useStyles();
    const values = props.values;
    if (!values || values.length === 0) {
        return (
            <tbody>
            <tr>
                <td colSpan={props.columns.used().length}>
                    <Box m={1}>
                        <Typography variant="subtitle1" gutterBottom>
                            No records returned.
                        </Typography>
                    </Box>
                </td>
            </tr>
            </tbody>
        )
    }

    const isSelected = (item: any): boolean => {
        return props.selection?.contains(item) || false
    }

    const onClick = (event: any, item: any) => {
        props.onRowClick && props.onRowClick(item, event)
        props.selection && props.selection.toggle(item)
    }

    return (
        <TableBody>
            {values.map((item) => {
                return (
                    <TableRow key={props.keyGen(item)}
                              onClick={event => onClick(event, item)}
                              className={props.onRowClick && classes.hover}
                              selected={isSelected(item)}
                              hover>
                        {props.columns.used().map((defn) => (
                            <TableCell key={defn.name}>{defn.getValue(item)}</TableCell>
                        ))}
                    </TableRow>
                )
            })}

        </TableBody>
    )
})

export const TableView = observer((props: TableProps) => {
    const classes = useStyles();

    return (
        <Table className={classes.table} aria-label="simple table">
            <TableHead>
                <TableRow>
                    {props.columns.used().map((defn) => (
                        <TableCell key={defn.name}>{defn.name}</TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableRowContent {...props}/>
        </Table>
    )
})
