import {observer} from "mobx-react-lite";
import {useStores} from "../contexts";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import React from "react";
import {EditablePerson} from "../components/compound-editable";

const ProfilePersonCreateDialog = observer(() => {
    const {profile} = useStores().person

    const reload = () => {
        document.location.reload()
    }
    return (
        <Dialog onClose={profile.cancel}
                open={profile.active}
                fullWidth={true}
                maxWidth={'lg'}
        >
            <DialogTitle>Person Details</DialogTitle>
            <DialogContent>
                {profile.active && (
                    <EditablePerson context={profile.ctxPerson()} editOnly/>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={profile.cancel} variant={"text"}>Cancel</Button>
                <Button onClick={() => profile.save(reload)} variant={"contained"} color={"primary"}
                        disabled={!profile._item || !profile.isEntityValid || profile.status === "BUSY"}>Save</Button>
            </DialogActions>
        </Dialog>
    )
})

export default ProfilePersonCreateDialog
